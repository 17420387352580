import Image from 'next/image';
import styled, { AnyStyledComponent, css } from 'styled-components';
import { COLORS, SPACES } from '@/theme';
import { FAMILIES, SIZES, WEIGHTS } from '@/theme/fonts.const';

export const BackToPostWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: ${SPACES.xs};
`;

export const BacktToPostText = styled.div`
  font-family: ${FAMILIES.robotoFlex};
  font-style: normal;
  font-weight: ${WEIGHTS.normal};
  font-size: ${SIZES.xs};
  line-height: ${SIZES.xs};
  margin-left: 3px;
  cursor: pointer;
  color: ${COLORS.backToPostButtonColor};
`;

export const RowImages = styled.div`
  margin-left: ${SPACES.xs};
  display: flex;
  max-width: 180px;
`;

export const ImageItem = styled.img`
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-bottom: 5px;
`;

export const NoImageItem = styled(Image as AnyStyledComponent)`
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-bottom: 5px;
`;

export const ExpandButton = styled.button<{ isOwnReply: boolean }>`
  padding-left: 10px;
  font-size: ${SIZES.m};
  line-height: ${SIZES.m};
  font-family: ${FAMILIES.robotoFlex};
  color: ${COLORS.black};
  border: none;
  background-color: ${COLORS.opacityZero};
  cursor: pointer;
  width: fit-content;
  border-left: solid
    ${({ isOwnReply }) => (isOwnReply ? `1px ${COLORS.borderColorForOwnReplyTile}` : `2px ${COLORS.borderColorForReplyTile}`)};
  display: flex;
  column-gap: 10px;
  height: 40px;
  align-items: center;
`;

export const ExpandButtonTextInConversationTread = styled.div`
  color: ${COLORS.black};
  font-family: ${FAMILIES.ibmPlexSansHebrew};
  font-size: ${SIZES.s};
  font-style: normal;
  font-weight: ${WEIGHTS.medium};
  line-height: ${SIZES.s};
  letter-spacing: 0.6px;
`;

export const PostWrapper = styled.div<{ isPublish?: boolean; isInModal?: boolean; isIdeaModal?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: ${({ isInModal }) => (isInModal ? '40px' : '0')};

  padding: 0 33px 0 27px;
  ${({ isInModal, isIdeaModal }) =>
    (isInModal || isIdeaModal) &&
    css`
      padding: 0;
    `};
  ${({ isPublish }) =>
    isPublish &&
    css`
      padding: 0 33px 0 26px;
    `};
`;

export const TwoTreadsWrapper = styled.div<{ isNotDisplay?: boolean; isIdeaModal?: boolean }>`
  display: ${({ isNotDisplay }) => (isNotDisplay ? 'none' : 'flex')};
  flex-direction: column;
  overflow: ${({ isIdeaModal }) => (isIdeaModal ? 'visible' : 'visible')};
  width: 100%;
`;

export const PostDivider = styled.div<{
  isDefaultWidth?: boolean;
  isInBookmarkSection?: boolean;
  isAnon?: boolean;
  isProfile?: boolean;
  mt?: number;
  isInModal?: boolean;
}>`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 0px;
  ${({ isInBookmarkSection }) => isInBookmarkSection && 'width: 100%;margin-left: 0px;'}
  ${({ isDefaultWidth }) => (isDefaultWidth ? 'width: 500px; margin: 14px auto 0 auto;' : 'margin-top: 14px;')}
  border: ${COLORS.NEW_BORDER};
  /* ${({ isAnon }) => isAnon && 'background-color: #353535;'} */
  ${({ isProfile }) => isProfile && 'margin-bottom: 5px'}
  ${({ mt }) => mt && `margin-top: ${mt}px;`}
  ${({ isInModal }) => isInModal && 'visibility: hidden;'}
`;
