import { axiosInstance } from './axios';
import { EnhancedWithAuthHttpService } from './http-auth.service';
import { HttpService } from './http.service';
import { IHttpConfig } from '../types';

class PageService extends EnhancedWithAuthHttpService {
  constructor() {
    super(new HttpService(axiosInstance));
  }

  async checkAccess(req: unknown) {
    return this.get<boolean>('page/check-access', req as IHttpConfig);
  }

  async checkAccessWithoutReq() {
    return this.get<boolean>('page/check-access');
  }
}

export const pageService = new PageService();
