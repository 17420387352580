import {
  IFilters,
  IFiltersResponse,
  IMemo,
  IMemoDraftsList,
  IMemoList,
  IMemoPayload,
  IMemoSection,
  IMemosOnProfile,
  IOptions,
  IOptionsDrafts,
  IProcessStatus,
  IPublish,
  IRateMemo,
  ISectors,
  RatingMemo
} from '../types/memo.type';
import { IPost } from '../types/post.type';
import { setPostStatistics } from '../utils/post-statistics';
import { axiosInstance } from './axios';
import { EnhancedWithAuthHttpService } from './http-auth.service';
import { HttpService } from './http.service';

class MemoService extends EnhancedWithAuthHttpService {
  constructor() {
    super(new HttpService(axiosInstance));
  }

  async getSectors() {
    return this.get<ISectors[]>('sector/memo');
  }

  async getMemoList(options: IOptions) {
    const { setTread, queryClient, setPosts } = options.postParams;
    const optionsUrl = new URLSearchParams();
    const includeDirection: string[] = [];
    const excludeDirection: string[] = [];
    const includeSectorId: string[] = [];
    const excludeSectorId: string[] = [];
    const includeRegionId: string[] = [];
    const excludeRegionId: string[] = [];
    const includeCategory: string[] = [];
    const excludeCategory: string[] = [];
    const includeMarketCap: string[] = [];
    const excludeMarketCap: string[] = [];
    const includeAdtvCap: string[] = [];
    const excludeAdtvCap: string[] = [];

    optionsUrl.append('page', options.page ? options.page.toString() : '1');
    optionsUrl.append('limit', options.limit ? options.limit.toString() : '20');

    if (options.direction && options.direction.length > 0) {
      options.direction.filter((s) => s.include).forEach((s) => includeDirection.push(s.id));
      options.direction.filter((s) => !s.include).forEach((s) => excludeDirection.push(s.id));
    }

    if (options.sectorId && options.sectorId.length > 0) {
      options.sectorId.filter((s) => s.include).forEach((s) => includeSectorId.push(s.id));
      options.sectorId.filter((s) => !s.include).forEach((s) => excludeSectorId.push(s.id));
    }

    if (options.regionId && options.regionId.length > 0) {
      options.regionId.filter((s) => s.include).forEach((s) => includeRegionId.push(s.id));
      options.regionId.filter((s) => !s.include).forEach((s) => excludeRegionId.push(s.id));
    }

    if (options?.category && options?.category.length > 0) {
      options.category.filter((s) => s.include).forEach((s) => includeCategory.push(s.id));
      options.category.filter((s) => !s.include).forEach((s) => excludeCategory.push(s.id));
    }

    if (options.marketCap && options.marketCap.length > 0) {
      options.marketCap.filter((s) => s.include).forEach((s) => includeMarketCap.push(s.id));
      options.marketCap.filter((s) => !s.include).forEach((s) => excludeMarketCap.push(s.id));
    }

    if (options.adtvCap && options.adtvCap.length > 0) {
      options.adtvCap.filter((s) => s.include).forEach((s) => includeAdtvCap.push(s.id));
      options.adtvCap.filter((s) => !s.include).forEach((s) => excludeAdtvCap.push(s.id));
    }

    includeMarketCap.forEach((id) => optionsUrl.append('includeMarketCap[]', id));
    excludeMarketCap.forEach((id) => optionsUrl.append('excludeMarketCap[]', id));

    includeAdtvCap.forEach((id) => optionsUrl.append('includeAdtvCap[]', id));
    excludeAdtvCap.forEach((id) => optionsUrl.append('excludeAdtvCap[]', id));

    includeCategory.forEach((id) => optionsUrl.append('includeCategory[]', id));
    excludeCategory.forEach((id) => optionsUrl.append('excludeCategory[]', id));

    includeRegionId.forEach((id) => optionsUrl.append('includeRegionId[]', id));
    excludeRegionId.forEach((id) => optionsUrl.append('excludeRegionId[]', id));

    includeDirection.forEach((id) => optionsUrl.append('includeDirection[]', id));
    excludeDirection.forEach((id) => optionsUrl.append('excludeDirection[]', id));

    includeSectorId.forEach((id) => optionsUrl.append('includeSectorId[]', id));
    excludeSectorId.forEach((id) => optionsUrl.append('excludeSectorId[]', id));

    const postResponse = await this.get<IMemoList>(`memo/paged?${optionsUrl}`, { signal: options?.signal });

    if (postResponse) {
      postResponse.posts.forEach((post) => {
        setTread(post.tread, post.id);
        post.tread.replies.forEach((reply) => {
          setPostStatistics(reply, queryClient);
        });
        setPostStatistics(post, queryClient);
        if (post.post) {
          setPostStatistics(post.post, queryClient);
        }
        if (post.originalPost) {
          setPostStatistics(post.originalPost, queryClient);
        }
      });
      setPosts(postResponse.posts, options.page ?? 1);
    }
    return postResponse;
  }

  async getFilters(options: IFilters) {
    const optionsUrl = new URLSearchParams();
    const includeDirection: string[] = [];
    const excludeDirection: string[] = [];
    const includeSectorId: string[] = [];
    const excludeSectorId: string[] = [];
    const includeRegionId: string[] = [];
    const excludeRegionId: string[] = [];
    const includeCategory: string[] = [];
    const excludeCategory: string[] = [];
    const includeMarketCap: string[] = [];
    const excludeMarketCap: string[] = [];
    const includeAdtvCap: string[] = [];
    const excludeAdtvCap: string[] = [];

    if (options.direction && options.direction.length > 0) {
      options.direction.filter((s) => s.include).forEach((s) => includeDirection.push(s.id));
      options.direction.filter((s) => !s.include).forEach((s) => excludeDirection.push(s.id));
    }

    if (options.sectorId && options.sectorId.length > 0) {
      options.sectorId.filter((s) => s.include).forEach((s) => includeSectorId.push(s.id));
      options.sectorId.filter((s) => !s.include).forEach((s) => excludeSectorId.push(s.id));
    }

    if (options.regionId && options.regionId.length > 0) {
      options.regionId.filter((s) => s.include).forEach((s) => includeRegionId.push(s.id));
      options.regionId.filter((s) => !s.include).forEach((s) => excludeRegionId.push(s.id));
    }

    if (options?.category && options?.category.length > 0) {
      options.category.filter((s) => s.include).forEach((s) => includeCategory.push(s.id));
      options.category.filter((s) => !s.include).forEach((s) => excludeCategory.push(s.id));
    }

    if (options.marketCap && options.marketCap.length > 0) {
      options.marketCap.filter((s) => s.include).forEach((s) => includeMarketCap.push(s.id));
      options.marketCap.filter((s) => !s.include).forEach((s) => excludeMarketCap.push(s.id));
    }

    if (options.adtvCap && options.adtvCap.length > 0) {
      options.adtvCap.filter((s) => s.include).forEach((s) => includeAdtvCap.push(s.id));
      options.adtvCap.filter((s) => !s.include).forEach((s) => excludeAdtvCap.push(s.id));
    }

    includeMarketCap.forEach((id) => optionsUrl.append('includeMarketCap[]', id));
    excludeMarketCap.forEach((id) => optionsUrl.append('excludeMarketCap[]', id));

    includeAdtvCap.forEach((id) => optionsUrl.append('includeAdtvCap[]', id));
    excludeAdtvCap.forEach((id) => optionsUrl.append('excludeAdtvCap[]', id));

    includeCategory.forEach((id) => optionsUrl.append('includeCategory[]', id));
    excludeCategory.forEach((id) => optionsUrl.append('excludeCategory[]', id));

    includeRegionId.forEach((id) => optionsUrl.append('includeRegionId[]', id));
    excludeRegionId.forEach((id) => optionsUrl.append('excludeRegionId[]', id));

    includeDirection.forEach((id) => optionsUrl.append('includeDirection[]', id));
    excludeDirection.forEach((id) => optionsUrl.append('excludeDirection[]', id));

    includeSectorId.forEach((id) => optionsUrl.append('includeSectorId[]', id));
    excludeSectorId.forEach((id) => optionsUrl.append('excludeSectorId[]', id));

    return this.get<IFiltersResponse>(`memo/filters?${optionsUrl}`);
  }

  async getUserDrafts(options: IOptionsDrafts) {
    const optionsUrl = new URLSearchParams();
    optionsUrl.append('page', options.page ? options.page.toString() : '1');
    optionsUrl.append('limit', options.limit ? options.limit.toString() : '20');
    return this.get<IMemoDraftsList>(`memo/drafts?${optionsUrl}`);
  }

  async getMemoLocalStorage() {
    const id = localStorage.getItem('memoId');
    if (!id) return {} as IMemo;
    return this.get<IMemo>(`memo/${id}`);
  }

  async getMemosOnProfile(profileId: string) {
    return this.get<IMemosOnProfile>(`memo/memos-on-profile/${profileId}`);
  }

  async createMemo(memo: IMemoPayload) {
    const { writedAt } = memo;
    const updatedWritedAt = writedAt ? new Date(writedAt) : undefined;
    updatedWritedAt?.setUTCHours(12, 0, 0, 0);
    return this.post<IMemo, IMemoPayload>('memo', {
      ...memo,
      writedAt: updatedWritedAt
    });
  }

  async updateMemo(memo: IMemo, memoId: string) {
    const { memoSections, writedAt, ...rest } = memo;
    const updatedWritedAt = writedAt ? new Date(writedAt) : undefined;
    updatedWritedAt?.setUTCHours(12, 0, 0, 0);
    const memoSectionsWithoutImg = memoSections?.map((section) => {
      const { imageUrls, ...payload } = section;
      return {
        ...payload
      };

      // return section;
    });
    const memoRes = this.put<IMemo, IMemo>(`memo/${memoId}`, {
      ...rest,
      writedAt: updatedWritedAt,
      researchProgress: rest.researchProgress?.length ? rest.researchProgress : undefined,
      id: memoId,
      memoSections: memoSectionsWithoutImg
    }).then(async (value) => {
      if (memoSections) {
        const memoSectionsWithImg = await Promise.all(
          memoSections.map(async (section) => {
            const memoSection = await this.put<IMemoSection, IMemoSection>(`memo/section/${memoId}`, section);
            return memoSection;

            // return section;
          })
        );
        return { ...value, memoSections: memoSectionsWithImg as IMemoSection[] } as IMemo;
      }
    });
    return memoRes;
  }

  async getMemosCurrentUser(id: string) {
    return this.get<IMemo[]>(`memo/byCategory/${id}`);
  }

  async getMemosOtherUser(memoId: string, direction: string, category: string) {
    return this.get<IPost[]>(`memo/byCategoryAllPeople?memoId=${memoId}&direction=${direction}&category=${category}`);
  }

  async getMemosById(id: string) {
    return this.get<IMemo>(`memo/${id}`);
  }

  async publishedMemo(id: string, memo: IMemo) {
    return this.put<IMemo, IMemo>(`memo/publishMemo/${id}`, memo);
  }

  async processSecondStepMemoProcessing(id: string, document: File) {
    const formData = new FormData();
    formData.append('file', document);
    return this.post<void, FormData>(`memo/upload-file-for-second-search/${id}`, formData);
  }

  async rateMemo(memoId: string, rate: number) {
    return this.put<IRateMemo, { rate: number }>(`memo/rate/${memoId}`, { rate });
  }

  async getMyRate(memoId: string) {
    return this.get<RatingMemo>(`memo/my-rate/${memoId}`);
  }

  async getMemosPostById(id: string | undefined) {
    if (!id) {
      return;
    }
    return this.get<IPublish>(`memo/array-ids/${id}`);
  }

  async processMemoLink(link: string) {
    return this.get<string>('memo/link-for-search', { params: { link } });
  }

  async processMemoDocument(document: File, fileName?: string) {
    const formData = new FormData();
    formData.append('file', document);
    if (fileName) {
      formData.append('fileName', fileName);
    }

    return fetch(`${process.env.NEXT_PUBLIC_BACKEND_URL}/memo/upload-file-for-search`, {
      method: 'POST',
      body: formData
    });
  }

  async checkProcessStatus(processId: string) {
    return this.get<IProcessStatus>(`memo/check-process-status/${processId}`);
  }

  async deleteMemo(id: string) {
    return this.delete(`memo/${id}`);
  }
}

export const memoService = new MemoService();
