import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { QUERY_KEYS } from '../consts/app-keys.const';
import { channelService } from '../services/channel.service';
import { ICurrentStatus } from '../types/profile.type';
import { useChannelDataPosts, useTreadByPostId } from './use-post';
import { POST_LIMIT } from '../consts';
import { queryClient } from '@/pages/_app';
import { IPost } from '../types/post.type';

export const useGetChannels = () => useQuery([QUERY_KEYS.ALL_CHANNELS], () => channelService.getAllChannels());

export const useGetChannelData = (userProfileId?: string, id?: string) => {
  const { setAllPosts, setNewPosts } = useChannelDataPosts(userProfileId, id);
  const { setTread } = useTreadByPostId();

  const setPosts = (postsData: IPost[], pageNumber: number) => {
    if (pageNumber === 1) {
      setAllPosts(postsData);
      return;
    }
    if (userProfileId) {
      setNewPosts(postsData, userProfileId);
    }
  };
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, refetch, isRefetching } = useInfiniteQuery(
    [QUERY_KEYS.CHANNEL_DATA, id, userProfileId],
    ({ pageParam = 0, signal }) =>
      channelService.getChannelData({ setTread, queryClient, setPosts }, id, pageParam + 1, POST_LIMIT, signal),
    {
      cacheTime: 0,
      staleTime: 0,
      enabled: Boolean(id),
      getNextPageParam(lastPage, allPages) {
        if (allPages.length * POST_LIMIT >= (lastPage?.count ?? 0)) return;
        return allPages.length;
      }
    }
  );

  return {
    isActive: data?.pages[0]?.isActive,
    name: data?.pages[0]?.name,
    hasNextPageList: hasNextPage,
    isFetchingNextPageList: isFetchingNextPage,
    isListLoading: isLoading,
    isRefetchingList: isRefetching,
    fetchNextPageList: fetchNextPage,
    refetchList: async () => {
      await queryClient.setQueryData([QUERY_KEYS.POST_FUND_LETTERS_DATA_FOLLOWING, POST_LIMIT, userProfileId], () => ({
        pageParams: []
      }));
      await refetch();
    }
  };
};

export const useUpdateUserChannels = (setCurrentStatus: React.Dispatch<React.SetStateAction<Partial<ICurrentStatus> | undefined>>) =>
  useMutation((id: string) => channelService.updateUserChannels(id), {
    onSuccess: (data) => {
      if (data) {
        setCurrentStatus((prev: any) => ({
          ...prev,
          channelsIds: data
        }));
      }
    }
  });

export const useUpdateUserFirstVisitedChannels = () =>
  useMutation((id: string) => channelService.updateUserFirstVisitedChannels(id), {
    onSuccess: (data) => {}
  });
