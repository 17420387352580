import React, { useEffect, useState } from 'react';

import { userService } from '@services';
import { useMutation, useQueryClient } from 'react-query';
import { Loader } from '@components/loader';
import toast from 'react-hot-toast';
import { useRouter } from 'next/router';
import * as Styled from './home.styled';
import { QUERY_KEYS, ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { SPACES } from '@/theme';
import { useAuth, useDeleteProfile, useLogount, useUpdateSetupStep } from '@/common/hooks';

export const HomeComponent = () => {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(true);
  const { push } = useRouter();
  const { user, isUserLoading } = useAuth();
  const { mutateAsync: updateSetupStep } = useUpdateSetupStep();
  const {
    data: invitationLink,
    isLoading: isInvitationLinkLoading,
    mutateAsync: getInvitationLink
  } = useMutation({
    mutationFn: (url: string) => userService.getInvitationLink(url)
  });
  const { mutateAsync: logout } = useLogount();

  const { mutateAsync: deleteProfile } = useDeleteProfile();

  const onGenerateClick = async () => {
    await getInvitationLink(window.location.origin + ROUTER_KEYS.AUTH);
  };

  const onGeneratedLinkClick = () => {
    if (invitationLink) navigator.clipboard.writeText(invitationLink);
    toast('Invitation link copied to the clipboard');
  };

  const onLogoutClick = async () => {
    logout();
  };

  const onDeleteClick = async () => {
    if (user?.id) {
      await deleteProfile(user.id);
      queryClient.removeQueries([QUERY_KEYS.PROFILE]);
      await push(ROUTER_KEYS.AUTH);
    }
  };

  const onOnboardingClick = () => {
    if (user && user?.linkedinUrl) {
      push(ROUTER_KEYS.PULL);
    } else {
      updateSetupStep(2);
    }
  };

  useEffect(() => {
    if (!user) return;
    if (user.setupStep === 5) {
      push(ROUTER_KEYS.PODCAST_INSIGHTS);
    }
    if (user.setupStep === null) {
      push(ROUTER_KEYS.PULL);
    }
    if (user.setupStep !== 5) {
      push(ROUTER_KEYS.ONBOARDING_WELCOME);
    }
  }, [isUserLoading, user]);

  return (
    <Styled.HomeWrapper>
      {isUserLoading || isLoading ? (
        <Loader />
      ) : (
        <>
          <Styled.ActionWrapper>
            <Styled.GenerateLinkButton onClick={onDeleteClick}>Delete Profile</Styled.GenerateLinkButton>
            <Styled.GenerateLinkButton onClick={onOnboardingClick}>Onboarding</Styled.GenerateLinkButton>
            <Styled.GenerateLinkButton onClick={onLogoutClick}>Logout</Styled.GenerateLinkButton>
          </Styled.ActionWrapper>
          <Styled.GenerateLinkButton onClick={onGenerateClick}>
            {isInvitationLinkLoading ? <Loader width={SPACES.xxxxl} height={SPACES.xxxxl} /> : 'Invite a Friend'}
          </Styled.GenerateLinkButton>

          <Styled.GeneratedLink title="Click to copy" onClick={onGeneratedLinkClick}>
            {invitationLink}
          </Styled.GeneratedLink>
        </>
      )}
    </Styled.HomeWrapper>
  );
};
