import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import * as Styled from './podcast-sections-thread.styled';
import { PostComponent } from '@/common/components/post';
import { useDeletePost } from '@/common/hooks';
import { IPostWithPostOrOriginalPost, ISubPost, PostType } from '@/common/types/post.type';

interface IProps {
  openReply: (postItem?: IPostWithPostOrOriginalPost, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isClickOnPost: boolean;
  isTreanding?: boolean;
  isMemoPost?: boolean;
  postId: string;
  profileId?: string;
  isIdeaModal?: boolean;
  userProfileId?: string | undefined;
  isInModal?: boolean;
  replies?: IPostWithPostOrOriginalPost[];
  quotePostHandler: (postId: string) => void;
  closeModal?: () => void;
  memosSection?: any;
  dividerWidth?: number;
  isDefaultStats?: boolean;
  disableQuoteAndRepost?: boolean;
  isPodcastModal?: boolean;
  parentPost?: ISubPost;
}

export const PodcastSectionsThread = ({
  openReply,
  isClickOnPost,
  isTreanding,
  profileId,
  postId,
  userProfileId,
  isInModal,
  isPodcastModal,
  replies,
  isIdeaModal,
  isDefaultStats,
  disableQuoteAndRepost,
  parentPost,
  quotePostHandler,
  closeModal
}: IProps) => {
  const { mutateAsync: deletePost } = useDeletePost({ postId, profileId });

  const stopPropagation = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e?.preventDefault();
    e?.stopPropagation();
  };

  return (
    <AnimatePresence>
      {isClickOnPost && replies?.length && (
        <motion.div
          transition={{ ease: 'backInOut', delay: isIdeaModal ? 0 : 0.2, duration: 0.5 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={undefined}
        >
          <Styled.ConversationWrapper>
            {replies.map((it, index) => {
              if (it.isNew) return <div key={`post-${it.id}`} />;
              const isLast = index === replies.length - 1;
              return (
                <div key={`wrapper-post${isTreanding && '-treanding'}-divider-${it.id}`} style={{ position: 'relative' }}>
                  <PostComponent
                    isPodcastModal={isPodcastModal}
                    disableQuoteAndRepost={disableQuoteAndRepost}
                    parentPost={parentPost}
                    userProfileId={userProfileId}
                    isDefaultStats={isDefaultStats}
                    postStatus={{
                      isInOwnTread: false,
                      isOwnReply: true,
                      isLast: it.replyCount === 0,
                      isInModal,
                      isTreadOpen: isClickOnPost,
                      isConversation: true
                    }}
                    isIdeaModal={isIdeaModal}
                    closeModal={closeModal}
                    post={it}
                    onClickByPost={stopPropagation}
                    repliedPost={it.post}
                    key={`conversation${isTreanding && '-treanding'}-post-${it.id}`}
                    deletePost={deletePost}
                    openReply={(e) => openReply(it, e)}
                    quotePostHandler={quotePostHandler}
                    postType={PostType.Reply}
                  />
                  {!isLast && <Styled.ReplyDivider />}
                </div>
              );
            })}
          </Styled.ConversationWrapper>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
