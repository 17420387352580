import styled from 'styled-components';
import Image from 'next/image';
import { ModalComponent } from '../modal';
import { FONTS } from '@/theme';

export const CreateModalWrapper = styled(ModalComponent)`
  min-width: 600px;
  max-width: 600px;
  min-height: calc(100vh - 30px);
  padding: 30px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  display: flex;
  flex-direction: column;
  color: #000;
  border-radius: 20px !important;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: -5px 5px 10px 0px rgba(202, 202, 202, 0.15);
  z-index: 15 !important;
`;

export const BlurBlock = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 60px;
  height: 100vh;
  z-index: 50;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.5);
`;

export const BlurBlockPost = styled.div`
  position: absolute;
  top: 0px;
  left: 60px;
  width: 60px;
  height: 100vh;
  z-index: 50;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.5);
`;

export const HeaderModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 39px; */
  margin-bottom: 20px;
`;

export const HeaderModalTabs = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
`;

export const SelectModalContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 3px;
`;

export const CloseButton = styled.button`
  background-color: #fff;
  border: none;
  cursor: pointer;
`;

export const CloseButtonImage = styled(Image)`
  width: 20px;
  height: 20px;
  margin-top: 3px;
  margin-right: -1px;
`;

export const SelectItem = styled.button<{ isSelected?: boolean; other?: boolean }>`
  border: none;
  cursor: pointer;
  display: flex;
  padding: 5px 15px;
  align-items: center;
  gap: 5px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 12px;
  border: ${({ isSelected }) => (isSelected ? '1px solid transparent' : '1px solid #F8F8F8')};
  background: ${({ isSelected }) => (isSelected ? '#EFF1FF' : '#F6F8FF')};
  color: ${({ isSelected }) => (isSelected ? '#0018F1' : '#6D7CFF')};
  position: relative;
  height: 30px;

  ${({ other }) =>
    other &&
    `
  color: #777;
  background: #F8F8F8;
  `}
`;

export const SelectItemIcon = styled(Image)`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 9px;
  left: 0;
`;

export const AddPostText = styled.button`
  border: none;
  cursor: pointer;
  display: flex;
  padding: 5px 15px;
  align-items: center;
  gap: 5px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.medium};
  line-height: 20px;
  border-radius: 12px;
  border: 1px solid #f8f8f8;
  background: #eff1ff;
  color: #0018f1;
  position: relative;
  height: 30px;
`;
