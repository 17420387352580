import { ReactNode, SetStateAction } from 'react';
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
  InfiniteData
} from 'react-query';
import { IRegion, ISelectedCompany } from './coverage.type';
// eslint-disable-next-line import/no-cycle
import { IPost, ISetTreadAndPostsProps } from './post.type';

export interface ISectors {
  createdAd: string;
  id: string;
  jobVerticals: string[];
  name: string;
  updatedAt: string;
}

export interface ISubSectors {
  createdAd: string;
  id: string;
  name: string;
  updatedAt: string;
  sectorId: string;
}

export interface IMemoPropsComponent {
  state: string;
  updateState: (componentName: string, value: string) => void;
  setSectorId?: (id: string) => void;
  active?: string;
  sectorId?: string;
  setMemoState?: React.Dispatch<React.SetStateAction<IMemoState>>;
}

export enum CaseType {
  BASE_CASE = 'BASE_CASE',
  BEAR_CASE = 'BEAR_CASE',
  THESIS = 'THESIS'
}

export interface IFirstGroup {
  state: string;
  updateState: (componentName: string, value: string) => void;
  setSectorId?: (id: string) => void;
  active?: string;
  sectorId?: string;
  setMemoState?: React.Dispatch<React.SetStateAction<IMemoState>>;
}

export interface ISubSector extends IFirstGroup {}

export interface IMemoSecurityProps extends IMemoPropsComponent {
  securityName: string | undefined;
  setSecurityName: React.Dispatch<React.SetStateAction<string>>;
  setIsOpenListSecurity?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IMemoSecurityList {
  companies: ISelectedCompany[] | undefined;
  handleClick: (name: string, id: string) => void;
  state?: number;
  handleChange?: (value: string) => void | undefined;
  searchText?: string;
}

export interface ICompanies {
  name: string;
  id: string;
}
export interface IMemoReturns {
  baseMethod: string;
  baseReturn: number;
  bullMethod: string;
  bullReturn: number;
  bearMethod: string;
  bearReturn: number;
}

export interface IMemoValuation {
  revenue: number;
  ebitda: number;
  eps: number;
  fcfeps: number;
}

export interface IValuationSectionProps {
  fieldName: string;
  field: number;
  onInputChange: (fieldName: string, value: string) => void;
}

export interface IValuationProps {
  memoValuation: IMemoValuation;
  onInputChange: (fieldName: string, value: string) => void;
  currentMemo: IMemo;
  isCurrent: boolean;
  isAllSectionOpen: boolean;
}

export interface IReturnExplanationInputProps {
  methodField: string;
  methodFieldName: string;
  onInputChange?: (fieldName: string) => (value: number) => void;
  onTextInputChange?: (fieldName: string) => (value: string) => void;
  isDetails?: boolean | undefined;
  isLoading?: boolean;
}

export interface IReturnNumberProps {
  returnField: number;
  returnFieldName: string;
  onInputChange: (value: string) => void;
  isDetails?: boolean | undefined;
  isProcessing?: boolean;
}

export interface IMemoOptionalDetailsProps {
  isCurrent: boolean;
  handleOptionalDetailsChange: (data: any) => void;
}

export interface IReturnsProps {
  memoReturns: IMemoReturns;
  setMemoReturns: (memoReturns: IMemoReturns) => void;
  isCurrent: boolean;
  isDetails?: boolean;
  method: string;
  returns: number;
  returnFieldName: string;
  methodFieldName: string;
  isAllSectionOpen: boolean;
}

export enum TimeHorizon {
  MONTHS_0_6 = 'MONTHS_0_6',
  MONTHS_6_12 = 'MONTHS_6_12',
  MONTHS_12_24 = 'MONTHS_12_24',
  MONTHS_24_PLUS = 'MONTHS_24_PLUS'
}

export enum Research {
  LOWER_50 = 'LOWER_50',
  BETWEEN_50_75 = 'BETWEEN_50_75',
  OVER_75 = 'OVER_75'
}

export enum Direction {
  LONG = 'LONG',
  SHORT = 'SHORT'
}
export interface IMemoThesis {
  text: string;
  imageUrls: string[];
  sectionName: string;
  title: string;
}

export interface IThesisSelectButtons {
  [key: string]: string[];
}

export interface IThesisSubsectionProps {
  isCurrent: boolean;
  title: string;
  selectedButtons: IThesisSelectButtons;
  setSelectedButtons: (field: React.SetStateAction<IThesisSelectButtons>) => void;
  memoThesis: IMemoThesis[];
  setMemoThesis: React.Dispatch<React.SetStateAction<IMemoThesis[]>>;
  color: string;
}

export enum TitleEnum {
  SUMMARY = 'Summary',
  EXIT_VALUATION = 'Exit Value',
  RISKS = 'Risks',
  BEAR_CASE = 'Bear Case',
  MOAT = 'Moat',
  STAKEHOLDERS = 'Stakeholders',
  BUSINESS_MODEL = 'Business Model'

  // FINANCIAL_METRICS = 'Financial Metrics',
  // BUSINESS_QUALITY = 'Business Quality',
  // ABOUT_THE_COMPANY = 'About the Company'
}
export const FromTitleToText: {
  [it in TitleEnum]: { view: string; question: string; description: string };
} = {
  [TitleEnum.SUMMARY]: {
    view: 'Summary',
    question: 'Opportunity: summarize your thesis',
    description: 'e.g. earnings out-/under-performance, valuation, stakeholders, moat, risks, M&A, bear case'
  },
  [TitleEnum.EXIT_VALUATION]: {
    view: 'Exit Value',
    question: 'Exit Value: what are your expectations for financials and valuation?',
    description: 'e.g. change in revenue, margins, per share metrics; you vs consensus; valuation multiple'
  },
  [TitleEnum.RISKS]: {
    view: 'Risks',
    question: 'Risks: which are notable?',
    description: 'e.g. competition, disruption, execution, regulation, AI, suppliers, customers, leverage, etc'
  },
  [TitleEnum.BEAR_CASE]: {
    view: 'Bear Case',
    question: 'Bear Case: what are the circumstances and your price expectation?',
    description: 'e.g. pricing, volume, margins, valuation multiple'
  },
  [TitleEnum.MOAT]: {
    view: 'Moat',
    question: 'Moat: what evidence suggests this is a good or bad business?',
    description: 'e.g. competitive advantages, pricing power, margins, return on capital'
  },
  [TitleEnum.BUSINESS_MODEL]: {
    view: 'Business Model',
    question: 'Business Model: explain the company’s operations',
    description: 'e.g. history, revenue drivers, unit economics'
  },
  [TitleEnum.STAKEHOLDERS]: {
    view: 'Stakeholders',
    question: 'Stakeholders: are the company’s stakeholders performing and aligned?',
    description: 'e.g. capital allocation, leadership and their compensation structure, key shareholders'
  }

  // [TitleEnum.SUMMARY]: {
  //   view: 'Opportunity: summarize your thesis',
  //   question: 'Why invest now?',
  //   description: 'e.g. earnings out-/under-performance, valuation, management, M&A, strategy, factors'
  // },
  // [TitleEnum.FINANCIAL_METRICS]: {
  //   view: 'Financial Expectations',
  //   question: 'Your expectations over the investment horizon?',
  //   description: 'e.g. change in revenue, change in margins, change in per share metrics, your estimates vs consensus'
  // },
  // [TitleEnum.BUSINESS_QUALITY]: {
  //   view: 'Business Quality',
  //   question: 'What evidence suggests this is a good or bad quality business?',
  //   description: 'e.g. moat, pricing power, margins, return on capital'
  // },
  // [TitleEnum.ABOUT_THE_COMPANY]: {
  //   view: 'About the Company',
  //   question: 'Provide a description of the company.',
  //   description: 'e.g. revenue drivers, financial statements, historical performance, unit economics'
  // }
};

export interface IThesisSubsectionItemProps {
  isCurrent: boolean;
  item: TitleEnum;
  isLoading?: boolean;
  memoThesis?: IMemoThesis;
  setMemoThesis: React.Dispatch<React.SetStateAction<IMemoThesis[]>>;
  isLast: boolean;
  widthDivider?: boolean;
  setSelectedTitles?: (updater: (prevSelected: TitleEnum[]) => TitleEnum[]) => void;
  priceTarget?: number;
  setPriceTarget?: (price: number) => void;
}

export interface IThesisButtonsRowProps {
  color: string;
  fieldName: string;
  handleClick: (buttonText: string, fieldName: string, field: string[]) => () => void;
  field: string[];
  selectedField: string[];
  bc: string;
  newMaxWidth?: number;
}

export interface IMemoButtonProps {
  onClick: () => void;
  name: string;
  children: ReactNode;
  color: string;
  selectedField: string[];
  bc: string;
}

export interface ImgUploadProps {
  callback: (param: any) => void;
  multiple?: boolean;
  disabled: boolean;
  isCurrent: boolean;
}

export enum SelectionType {
  DOCUMENT_SECTION,
  URL_SELECTION,
  MANUAl
}

export enum PositionSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE'
}

export enum ConvictionLevel {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH'
}

export enum Disclaimer {
  LONG = 'LONG',
  SHORT = 'SHORT',
  NONE = 'NONE'
}

export const DisclaimerOutput = {
  [Disclaimer.LONG]: 'Long position at publication',
  [Disclaimer.SHORT]: 'Short position at publication',
  [Disclaimer.NONE]: 'No position at publication'
};

export interface IMemoPayload {
  direction: Direction | null;
  security: string;
  priceTarget: number;
  bearPriceTarget?: number;
  writedAt?: Date;
  closePrice?: number;
  category?: string;
  sectorId?: string;
  regionId?: string;
  subSectorId?: string;
  isSaveExit?: boolean;
  documentUrl?: string;
  documentName?: string;
  disclaimer?: Disclaimer | null;
  author?: string;
  link?: string;
}

export interface IMemoOnProfile {
  id: string;
  security: string;
  postId: string;
  replyCount: number;
  isReplied: boolean;
}

export interface IMemosOnProfile {
  shortMemos: IMemoOnProfile[];
  longMemos: IMemoOnProfile[];
}

export interface RatingMemo {
  id: string;
  profileId: string;
  rate: number;
}

export interface IRateMemo extends RatingMemo {
  memoId: string;
}

export interface IMarketData {
  sharePrice?: number;
  marketCap?: number;
  yearLow?: number;
  yearHigh?: number;
  adtvApprox?: number;
  currency?: string;
  shortInterest?: number;
}

export interface IMemo extends IMarketData {
  id: string;
  isFirstStepClicked: boolean;
  isProcessed: boolean;
  priceTarget?: number;
  bearPriceTarget?: number;
  createdAt: string | Date;
  writedAt?: string | Date;
  category?: string;
  isAnon?: boolean;
  security: string;
  direction: Direction;
  timeHorizon: string;
  disclaimer?: Disclaimer | null;
  researchProgress?: string;
  revenue?: number;
  ebitda?: number;
  eps?: number;
  fcfeps?: number;
  sectorId?: string;
  regionId?: string;
  subSectorId?: string;
  documentUrl?: string;
  documentName?: string;
  rating?: number;
  backDateToggle?: boolean;
  author?: string;
  region?: {
    name: string;
  };
  subSector?: {
    name: string;
  };
  profileId: string;
  profile?: {
    imageUrl: string;
    fullName: string;
    id: string;
  };
  postId?: string;
  link?: string;
  comparables?: string[];
  themes?: string[];
  summaryText?: string;
  positionSize?: string;
  convictionLevel?: string;
  memoSections?: IMemoSection[];
  memoSection?: IMemoPublishSection[];
  securityName?: string;
  sectorName?: string;
  regionName?: string;
  subSectorName?: string;
  totalLikesCount?: number;
  totalRepliesCount?: number;
  isMemoProcessing?: boolean;
}

export interface IMemoSectionPost {
  id: string;
  memoId: string;
  method: null | string;
  return: null | number;
  section: string;
  sectionName: string;
  text: string;
  title: string;
  type: string;
  priceTarget?: number;
  risk?: string;
  direction?: Direction;
  closePrice?: number;
}
export interface IMemoSection {
  id?: string;
  type?: string;
  truncatedText?: string;
  text?: string;
  imageUrls?: string[];
  sectionName?: string;
  companyName?: string;
  title: string;
  method?: string;
  priceTarget?: number;
  closePrice?: number;
  return?: number;
  section?: string;
  direction?: Direction;
  memo?: IMemo;
  memoId?: string;
  risk?: number;
}

export interface IMemoState {
  category: string;
  direction: string;
  sectorId: string;
  regionId: string;
  subSectorId: string;
  timeHorizon: string;
  priceTarget: number;
  security: string;
  researchProgress: string;
  isFirstStepClicked: boolean;
  isProcessed: boolean;
  createdAt?: Date;
  backDateToggle?: boolean;
  writedAt?: Date;
}

export interface IOptionalState {
  positionSize: string;
  convictionLevel: string;
  disclaimer: string;
  comparables: string[];
  link: string;
  documentUrl: string;
  documentName: string;
}

export interface ISummaryState {
  summaryText: string;
  themes: string[];
}

export interface IProcessLoadingStatus {
  isBaseProcessed: boolean;
  isSummaryProcessed: boolean;
  isFinancialMetricsProcessed: boolean;
  isExitValuationProcessed: boolean;
  isRisksProcessed: boolean;
  isBusinessQualityProcessed: boolean;
  isStakeholdersProcessed: boolean;
  isAboutTheCompanyProcessed: boolean;
  isBearCaseProcessed: boolean;
  isBullCaseProcessed: boolean;
}

export interface IOptions {
  page: number;
  limit: number;
  postParams: ISetTreadAndPostsProps;
  category?: IFilterItem[];
  direction?: IFilterItem[];
  regionId?: IFilterItem[];
  sectorId?: IFilterItem[];
  marketCap?: IFilterItem[];
  adtvCap?: IFilterItem[];
  signal?: AbortSignal;
}

export interface IOptionsDrafts {
  page: number;
  limit: number;
  signal?: AbortSignal;
}

export interface IMemoList {
  count: number;
  posts: IPost[];
}

export interface IMemoDraft {
  security: string;
  id: string;
  diraction: string;
  subSector: string;
}

export interface IMemoDraftsList {
  memos: IMemoDraft[];
  maxPage: number;
}

export interface IListProps {
  memos: IMemo[] | undefined;
  isFetchingNextPage: boolean;
  isLoading: boolean;
  hasNextPage: boolean | undefined;
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<void | IMemoList, unknown>>;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<InfiniteData<void | IMemoList>, unknown>>;
}

export interface IDraftsProps {
  memos: IMemoDraft[];
  isFetchingNextPage: boolean;
  isOpenOptions?: boolean;
  isAnon: boolean;
  isLoading: boolean;
  hasNextPage: boolean | undefined;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<InfiniteData<void | IMemoDraftsList>, unknown>>;
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<void | IMemoDraftsList, unknown>>;
}

export interface IMemoPublishSection {
  memoSection: any;
  id: string;
  imageUrls: string[];
  memoId: string;
  method: string;
  return: number;
  sectionName: string;
  text: null;
  title: null;
  type?: string;
  risk?: number;
  profile?: Profile;
}
interface QueryAnswers {
  [key: string]: number;
}

interface Answers {
  queryAnswers: QueryAnswers;
}

interface Profile {
  answers: Answers;
  companies: string[];
  createdAt: string;
  bio: null;
  formerCompanies: string[];
  formerRegions: string[];
  formerSectors: string[];
  formerSubSectors: string[];
  fullName: string;
  id: string;
  imageUrl: string;
  imageVisibility: string;
  isBioFilled: boolean;
  isPulled: boolean;
}

export interface IPublishMemo {
  id?: string;
  createdAt?: string;
  category: string;
  security: string;
  direction: string;
  timeHorizon: string;
  researchProgress: string;
  revenue?: number;
  ebitda?: number;
  eps?: number;
  fcfeps?: number;
  sectorId: string;
  regionId: string;
  subSectorId: string;
  profileId: string;
  postId?: string;
  link?: string;
  comparables?: string[];
  themes?: string[];
  summaryText?: string;
  positionSize?: string;
  convictionLevel?: string;
  memoSections?: IMemoSection[];
  memoSection?: IMemoPublishSection[];
  sectorName: string;
  regionName: string;
  subSectorName: string;
  profile?: Profile;
  region: {
    createdAt: string;
    groupNumber: number;
    id: string;
    name: string;
    updatedAt: string;
  };
  sector: ISectors;
  subSector: {
    createdAt: string;
    id: string;
    name: 'Apparel';
    sectorId: string;
    updatedAt: string;
  };
  type?: string;
}

export interface IMemoSectionInPost {
  direction: string;
  id: string;
  memoId: string;
  section: string;
  sectionName: string;
  text: string;
  title: string;
  type: string;
  imageUrls: string[];
}

export interface IPublishPost {
  bookmarkCount: number;
  clapCount: number;
  comments: Comment[];
  createdAt: string;
  deletedAt: string | null;
  id: string;
  ideaId: string | null;
  imageUrl: string | null;
  isBookmarked: boolean;
  isClaped: boolean;
  isImageHidden: boolean;
  isLiked: boolean;
  isOwnPost: boolean;
  isPraised: boolean;
  isReplied: number;
  isRepost: null | string;
  isReposted: boolean;
  likeCount: number;
  memoId: string | null;
  memoSectionId: string | null;
  originalPostId: string | null;
  ownReplyCount: number;
  postId: string;
  praiseCount: number;
  profile: Profile;
  memoSection: IMemoSectionInPost;
  profileId: string;
  questionId: string | null;
  replyCount: number;
  repostCount: number;
  role: string;
  sectors: string[];
  text: string;
  updatedAt: string;
}

export interface IPublish {
  memosSection: IPost[];
  summary: IPost[];
}

interface Profile {
  fullName: string;
  id: string;
  imageUrl: string;
  imageVisibility: string;
}

export enum FilterKeys {
  CATEGORY = 'category',
  DIRECTION = 'direction',
  SECTOR_ID = 'sectorId',
  REGION_ID = 'regionId',
  MARKET_CAP = 'marketCap',
  ADTV_CAP = 'adtvCap'
}

export interface IFilters {
  [FilterKeys.CATEGORY]?: IFilterItem[];
  [FilterKeys.DIRECTION]?: IFilterItem[];
  [FilterKeys.SECTOR_ID]?: IFilterItem[];
  [FilterKeys.REGION_ID]?: IFilterItem[];
  [FilterKeys.MARKET_CAP]?: IFilterItem[];
  [FilterKeys.ADTV_CAP]?: IFilterItem[];
}

export interface IFiltersResponse {
  category: string[];
  direction: string[];
  sectorId: string[];
  regionId: string[];
  marketCap: string[];
  adtvCap: string[];
}

export interface IMemoListFilter {
  title: string;
  isAnon?: boolean;
  quantityFilters: string[];
  updateFilters: <T extends keyof IFilters>(filterName: T, filterValue: IFilterItem) => void;
  currentFilter?: IFilterItem[];
}

export interface IFilterItem {
  id: string;
  include?: boolean;
  label?: string;
}

export interface IProcessStatus {
  isProcessComplete: boolean;
  processError?: string;
  processedMemoId?: string;
}

export interface IMemoListFirstSection {
  state: string;
  handleClick: (name: string) => void;
  regions?: void | IRegion[] | undefined;
}
