import styled from 'styled-components';
import { FAMILIES } from '@/theme/fonts.const';

export const PodcastSectionWrapper = styled.div`
  margin-bottom: -2px;
`;

export const PodcastModalTitle = styled.p`
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  margin-bottom: 2px;
`;

export const PodcastModalText = styled.p`
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
