import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import * as Styled from './tag-list.styled';
import { Chip } from '@/common/components/chip';
import { ButtonLink, Link, OthersText } from '../../../post-insight/post-insight.styled';
import { ISector } from '@/common/types/coverage.type';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { AlgoliaIndexes } from '@/common/components/search-page/search-page.const';
import NewsletterLink from '@/styles/icons/newsletterLink.png';
import ArrowDown from '@/styles/icons/insight-download.png';
import Dot from '@/styles/icons/dot.svg';

interface IProps {
  listTitle?: string;
  feedId?: string;
  tagsList: ISector[];
  isOpen?: boolean;
  isSecurityListEmpty?: boolean;
  isNewsletter?: boolean;
  isShowSource?: boolean;
  toggleShowAll?: (event: React.MouseEvent) => void;
  handleLinkClick?: () => void;
}

export const TagListComponent = ({
  listTitle,
  tagsList,
  feedId,
  isOpen,
  isSecurityListEmpty,
  isShowSource,
  isNewsletter,
  handleLinkClick,
  toggleShowAll
}: IProps): ReactElement => {
  const listBlockRef = useRef<HTMLDivElement>(null);
  const [visibleTags, setVisibleTags] = useState<ISector[]>(tagsList);
  const { push } = useRouter();

  useEffect(() => {
    if (listBlockRef.current) {
      const containerWidth = listBlockRef.current.offsetWidth;
      let totalWidth = 0;
      const newVisibleTags: ISector[] = [];

      const chips = listBlockRef.current.children;
      for (let i = 0; i < chips.length; i += 1) {
        const chip = chips[i] as HTMLElement;
        const chipWidth = chip.offsetWidth + 3;

        if (totalWidth + chipWidth > 196) {
          break;
        }

        totalWidth += chipWidth;
        if (tagsList[i]) {
          newVisibleTags.push(tagsList[i]);
        }
      }
      setVisibleTags(newVisibleTags);
    }
  }, [tagsList, isOpen]);

  const showOthersText = visibleTags.length < tagsList.length;
  const othersText = isOpen ? '— show fewer' : `+ ${tagsList.length - visibleTags.length} others`;

  const onClickCompany = (event: React.MouseEvent, company: ISector): void => {
    event.stopPropagation();
    push({
      pathname: ROUTER_KEYS.SEARCH_PAGE,
      query: {
        type: AlgoliaIndexes.SECURITY,
        value: company.name,
        clickedId: company.id,
        clickedValue: company.name,
        isClickOnMention: true
      }
    });
  };

  const source = isShowSource && (
    <ButtonLink isSecurityListEmpty={isSecurityListEmpty} onClick={handleLinkClick}>
      {isNewsletter ? (
        <Image src={ArrowDown} width={12} height={12} alt="arrow down" />
      ) : (
        <Image src={ArrowDown} width={12} height={12} alt="arrow down" />
      )}
    </ButtonLink>
  );

  return (
    <Styled.Container>
      {listTitle && <Styled.Title>{listTitle}</Styled.Title>}
      {isOpen ? (
        <>
          {source}
          {tagsList.map((company, index) => (
            <Chip
              onClick={(event) => onClickCompany(event, company)}
              label={company?.id}
              color="primary"
              key={`${feedId}-${company?.id}-${index}`}
            />
          ))}
          {showOthersText && (
            <OthersText onClick={toggleShowAll} style={{ cursor: 'pointer' }}>
              {othersText}
            </OthersText>
          )}
        </>
      ) : (
        <Styled.ListBlock ref={listBlockRef} style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
          {source}
          {visibleTags.map((company, index) => (
            <Chip
              onClick={(event) => onClickCompany(event, company)}
              label={company?.id}
              color="primary"
              key={`${feedId}-${company?.id}-${index}`}
            />
          ))}
          {showOthersText && (
            <OthersText
              onClick={toggleShowAll}
              style={{
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                marginLeft: '3px',
                flexShrink: 0
              }}
            >
              {othersText}
            </OthersText>
          )}
        </Styled.ListBlock>
      )}
    </Styled.Container>
  );
};
