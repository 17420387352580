import React, { ReactElement } from 'react';
import AttentionIconImg from '@/styles/icons/attention-circle.webp';
import { FlexComponent } from '../flex';
import { AttentionIcon, AttentionIconBox, Description, DescriptionWrapper } from './disclaimer.styled';

interface IProps {
  mt?: number;
  isChannel?: boolean;
}

export const DisclaimerMessage = ({ mt, isChannel }: IProps): ReactElement => (
  <DescriptionWrapper mt={mt} isChannel={isChannel}>
    <FlexComponent gap="10px" alignItems="center">
      <AttentionIconBox>
        <AttentionIcon src={AttentionIconImg} alt="Attention" />
      </AttentionIconBox>
      <Description>
        Everything should be viewed as personal opinions only which do not reflect investments
        <br /> or views of the member’s employer(s); nothing should be viewed as investment advice
      </Description>
    </FlexComponent>
  </DescriptionWrapper>
);
