import React, { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import AddIcon from '@styles/icons/add-insight-icon.webp';
import { Stack } from '@mui/material';
import * as Styled from './debate.styled';
import { MODAL_SLIDE_TIMEOUT } from '@/common/consts';
import { useCreateQuestion } from '@/common/hooks';
import { Question } from '@/common/types/question.type';
import { ModalHeaderText, ModalTitle, Section, TitleContainer } from '../coverage/coverage.styled';
import { IconContainer, SectionTitle } from '../poll/poll.styled';
import { FlexComponent } from '@/common/components/flex';
import { StepperComponent } from '../poll/components/stepper';
import { PostFormActions } from '../insight/components/post-form-actions';

interface IQuestionsModalComponent {
  open: boolean;
  closeHandler: () => void;
}

const steps = [
  {
    value: '',
    lineHeight: '75px',
    disabledLineHeight: '75px'
  },
  {
    value: '',
    lineHeight: '65px',
    disabledLineHeight: '65px'
  },
  {
    value: '',
    lineHeight: '75px',
    disabledLineHeight: '75px'
  },
  {
    value: ''
  }
];
export const DebateComponent = ({ open, closeHandler }: IQuestionsModalComponent) => {
  const [text, setText] = useState('');
  const [year, setYear] = useState('');
  const [answer, setAnswer] = useState<number | null>(null);
  const ref = useRef<HTMLInputElement | null>(null);

  const { mutateAsync: createQuestion, isLoading: createIsLoading, isSuccess: createIsSuccess, reset } = useCreateQuestion();

  const resetFields = () => {
    setText('');
    setYear('');
    setAnswer(null);
    reset();
  };

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        resetFields();
      }, MODAL_SLIDE_TIMEOUT);
    }
  }, [open]);

  useEffect(() => {
    if (createIsSuccess) {
      closeHandler();
    }
  }, [createIsSuccess]);

  const confirmClick = () => {
    if (text && year && answer) {
      createQuestion({ text: `${text} ${year}`.trim(), answer, type: Question.QUERY, isVisible: true });
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref.current]);

  const maxLength = 70;

  return (
    <div>
      <Styled.QuestionContainer style={{ marginTop: '20px' }}>
        <TitleContainer>
          <IconContainer>
            <Image src={AddIcon} width={12} height={12} alt="add-coverage" />
          </IconContainer>
          <Stack gap="10px">
            <ModalTitle>Create debate</ModalTitle>
            <ModalHeaderText>Survey others on key debates</ModalHeaderText>
          </Stack>
        </TitleContainer>
        <FlexComponent gap="15px">
          <StepperComponent grayDotStep={2} allDotsActive isDotted step={1} steps={steps} />
          <Stack gap="30px">
            <Section>
              <FlexComponent gap="5px" alignItems="center">
                <SectionTitle>Debate</SectionTitle>
                <Styled.StepText>70 character limit</Styled.StepText>
              </FlexComponent>
              <Styled.InputText
                autoFocus
                placeholder="Type here .."
                value={text}
                onChange={(e) => setText(e.target.value)}
                maxLength={maxLength}
              />
            </Section>
            <Section>
              <SectionTitle>Select timeframe</SectionTitle>
              <Styled.SelectYearList>
                {Array.from({ length: 4 }, (_, i) => `${new Date().getFullYear() + i}${i === 3 ? '+' : ''}`).map((item) => (
                  <Styled.SelectYearItem
                    key={item}
                    selected={item === year}
                    onClick={() => setYear(item)}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter' && !(!text && !year)) {
                        event.preventDefault();
                        setYear(item);
                      }
                    }}
                    tabIndex={0}
                    disabled={!text && !year}
                  >
                    {item}
                  </Styled.SelectYearItem>
                ))}
              </Styled.SelectYearList>
            </Section>
            <Section>
              <Styled.StepTitleAppear>How your debate will appear</Styled.StepTitleAppear>
              <Styled.PreviewText>{`${text || '[debate]'} ${year || '[timeframe]'}`}</Styled.PreviewText>
            </Section>
            <Section gap={10}>
              <SectionTitle>Select your view</SectionTitle>
              <Styled.QuestionVote
                oneVoteWidth={77}
                oneVoteHeight={36}
                isPost
                allowChange
                answer={answer}
                answerCallback={(newAnswer) => setAnswer(newAnswer)}
              />
            </Section>
          </Stack>
        </FlexComponent>
      </Styled.QuestionContainer>

      <Styled.ActionButtonsContainer>
        <PostFormActions
          mt={1}
          title="create debate"
          isDisabled={createIsLoading || !text || !year || !answer || text.length > maxLength || createIsSuccess}
          isActive={(text && year && answer && text.length < maxLength) || createIsSuccess}
          activeCallback={confirmClick}
        />
      </Styled.ActionButtonsContainer>
    </div>
  );
};
