import styled from 'styled-components';
import { COLORS, FONTS } from '@/theme';

export const Wrapper = styled.div`
  display: flex;
  width: 540px;
  margin-left: 27px;
  padding: 15px 0px 15px 20px;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  border: 1px dashed ${COLORS.profileLockSectionBorder};
  background: ${COLORS.profileLockSectionBackground};
  color: ${COLORS.profileLockSectionText};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  font-weight: ${FONTS.WEIGHTS.normal};
  line-height: ${FONTS.SIZES.m};
`;
