import styled, { css } from 'styled-components';
import { APP_WIDTH } from '@/common/consts';
import { COLORS } from '@/theme';

interface Props {
  routePage?: string;
  contentLeftMargin?: string;
  sidebarWidth?: number;
}

const ColumnDividerStyled = css`
  position: fixed;
  top: 0;
  width: 0;
  height: 100%;
  border: 0.5px solid ${COLORS.NEW_BORDER_COLOR};
  z-index: 57;
`;

export const LeftColumnDivider = styled.div<{ sidebarWidth: number }>`
  ${ColumnDividerStyled}
  left: ${({ sidebarWidth }) => `${sidebarWidth}px`};
`;

export const PageWrapper = styled.div<{ disableScroll?: boolean; isAnon?: boolean; sidebarWidth?: number }>`
  position: absolute;
  top: 0;
  left: 0;
  color: ${COLORS.black};
  min-height: 100vh;
  min-height: -webkit-fill-available;
  width: 100vw;
  overflow: ${({ disableScroll }) => (disableScroll ? 'hidden' : 'auto')};

  /* background: ${({ isAnon }) => (isAnon ? '#313131' : '#f5f5f5')}; */
`;

const VerticalBarStyle = css`
  width: calc((100% - 604.5px) / 2);
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 10;
`;

export const LeftVerticalBar = styled.div<{ contentMarginLeft?: number; isHome?: boolean }>`
  ${VerticalBarStyle}
  /* width: ${({ contentMarginLeft }) => `calc(((100vw - ${APP_WIDTH}px) / 2) + 196px)`}; */
  min-width: 185px;
`;

export const RightVerticalBar = styled.div`
  ${VerticalBarStyle}
  width: calc(((100vw - ${APP_WIDTH}px) / 2) - 20px);
  overflow: hidden;
  right: 0;
`;

export const BlurBlockRightBar = styled.div`
  position: absolute;
  top: 0;
  left: 16px;
  width: 80px;
  height: 100vh;
  z-index: 13;
  backdrop-filter: blur(2px);
`;

export const BlurBlockRightMain = styled.div`
  position: absolute;
  top: 0;
  right: 0px;
  width: 611px;
  height: 100vh;
  z-index: 13;
  backdrop-filter: blur(2px);
`;

export const TopVerticalBarBlur = styled.div<{ isleft: boolean }>`
  width: 100%;
  height: 95px;
  ${({ isleft }) => `${isleft ? 'border-right' : 'border-left'}: 1px solid rgba(241, 241, 241, 0.75);`};
  opacity: 0.8;
  background: linear-gradient(180deg, rgba(245, 245, 245, 0.5) 60.42%, rgba(245, 245, 245, 0) 100%);
  backdrop-filter: blur(4px);
  position: absolute;
  top: 0;
  z-index: 10;
`;

export const BottomVerticalBarBlur = styled.div<{ isleft: boolean }>`
  width: 100%;
  height: 95px;
  ${({ isleft }) => `${isleft ? 'border-right' : 'border-left'}: 1px solid rgba(241, 241, 241, 0.75);`};
  opacity: 0.8;
  background: linear-gradient(180deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.5) 52.6%);
  backdrop-filter: blur(4px);
  position: absolute;
  bottom: 0;
  z-index: 10;
`;

export const ContentWrapper = styled.div<Props>`
  position: relative;
  max-width: calc(${APP_WIDTH}px + 20px);
  min-width: calc(${APP_WIDTH}px + 32px);
  max-height: 100vh;
  margin: 0 auto;
  margin-left: ${({ routePage, contentLeftMargin, sidebarWidth }) =>
    `calc(${sidebarWidth}px + ${routePage ? '144px' : contentLeftMargin || '125px'} - 15px)`};
  padding-bottom: 0;
  padding-right: 0;
  //overflow: hidden;
  @media (max-width: ${APP_WIDTH}px) {
    margin-left: 0;
  }
`;

export const ChildrenWrapper = styled.div<{ disableScroll?: boolean; isSearch: boolean; isIdeaHome?: boolean }>`
  ${({ isSearch }) => !isSearch && 'overflow-x: auto;'}
  ${({ isSearch }) => isSearch && 'padding-left: 2px;'}
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
  height: 100%;
  width: 100%;

  ${({ isIdeaHome }) => isIdeaHome && 'width: fit-content;'}
`;

interface ILoaderWrapperProps {
  isPageLoading?: boolean;
  isNotificationPage?: boolean;
  heightForMinus?: number;
  padding?: number;
  staticWidth?: number;
  heihgt?: number;
  width?: number;
}

export const LoaderWrapper = styled.div<ILoaderWrapperProps>`
  width: ${({ isPageLoading, width, staticWidth }) => {
    if (staticWidth) {
      return `${staticWidth}px`;
    }
    if (width) {
      return `${width}%`;
    }
    return isPageLoading ? '1200px' : '100%';
  }};
  height: ${({ isPageLoading, heightForMinus }) => {
    if (isPageLoading) {
      return '100vh';
    }
    return heightForMinus ? `calc(100vh - ${heightForMinus}px)` : '100%';
  }};
  ${({ heihgt }) => heihgt && `height:${heihgt}px;`}
  ${({ padding }) => `padding:${padding}px 0`};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  ${({ isNotificationPage }) => isNotificationPage && 'width:100%'}
`;
