import React, { useState, ReactElement } from 'react';
import { Stack } from '@mui/material';
import { useRouter } from 'next/router';
import Image from 'next/image';
import * as Styled from './post-insight.styled';
import { EInsightType, IInsightFeed, sourceType } from '@/common/types/insight.types';
import { FlexComponent } from '@/common/components/flex';
import { TagListComponent } from '../post-basic-info/insight-basic-info/tag-list/tag-list.component';
import ArrowTopRight from '@/styles/icons/arrow-top-right.webp';
import ArrowTopLeft from '@/styles/icons/arrow-top-left.png';
import PodcastGenerate from '@/styles/icons/podcast-generate.png';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { highlightText } from '@/common/utils/highlight-text';
import { ClickBlock } from '../display-memo-section';
import { PodcastModalComponent } from './podcast-modal/podcast-modal.component';

interface IProps extends IInsightFeed {
  isNotification?: boolean;
  description?: string;
  searchText?: string;
  originalPostId?: string;
  isQuote?: boolean;
  onClickByPost?: () => void;
}

export const PostInsightComponent = ({
  onClickByPost,
  isQuote,
  type,
  isNewPodcastType,
  searchText,
  title,
  description,
  securities,
  isRssInsight,
  link,
  isFile,
  isNotification,
  source,
  originalPostId
}: IProps): ReactElement => {
  const { pathname, push } = useRouter();
  const [isOpenPodcastModal, setIsOpenPodcastModal] = useState<boolean>(false);
  const [isThreadOpen, setIsOpenThread] = useState<boolean>(false);
  const isSearchPage = pathname.includes(ROUTER_KEYS.SEARCH_PAGE) && isRssInsight;
  const [showAll, setShowAll] = useState<boolean>(false);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const isNewsletter = source?.sourceType === sourceType.NEWSLETTER;
  const isPodcast = source?.sourceType === sourceType.PODCAST;
  const AiGenerated = type === EInsightType.AI_GENERATED_PODCAST;

  const togglePodcastButton = () => {
    if (onClickByPost) {
      onClickByPost();
      // setIsOpenThread((prev) => !prev);
      setIsAnimating(false);
    }
  };

  const openPodcast = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    if (isThreadOpen) {
      togglePodcastButton();
    } else {
      setIsAnimating(true);
      setTimeout(() => {
        togglePodcastButton();
      }, 3000);
    }
  };

  const handleLinkClick = (): void => {
    if (isFile) {
      const fileUrl = `${ROUTER_KEYS.PDF_VIEW}?file=${link}`;
      window.open(fileUrl, '_blank');
    } else {
      window.open(link, '_blank', 'noopener,noreferrer');
    }
  };

  const onSearchPageClick = () => {
    if (isFile) {
      push({
        pathname: ROUTER_KEYS.PDF_VIEW,
        query: {
          file: link
        }
      });
    } else {
      window.open(link, '_blank', 'noopener,noreferrer');
    }
  };

  const postTitle = isFile ? title?.replace(/^[\d.]+-/, '') : title;
  const isSecurityListEmpty = securities?.length === 0;
  const securitiesList = (
    <TagListComponent
      isShowSource={!isNewPodcastType && !isPodcast}
      isNewsletter={isNewsletter}
      handleLinkClick={handleLinkClick}
      isSecurityListEmpty={isSecurityListEmpty}
      isOpen={showAll}
      toggleShowAll={(event) => {
        event.stopPropagation();
        setShowAll((prev) => !prev);
      }}
      tagsList={securities}
    />
  );

  if (isNotification) {
    return (
      <Stack gap="8px">
        <FlexComponent alignItems="baseline" gap="6px">
          <Styled.NotificationFundTitle>Fund Letters</Styled.NotificationFundTitle>
          <Styled.Username>shared by you</Styled.Username>
        </FlexComponent>
        <Styled.NotificationTitle>{postTitle}</Styled.NotificationTitle>
        <Styled.NotificationSecuritiesBox>{securitiesList}</Styled.NotificationSecuritiesBox>
      </Stack>
    );
  }

  const decodeHtmlEntities = (text: string) => {
    if (!document) return;
    const element = document.createElement('textarea');
    element.innerHTML = text;
    return element.value;
  };

  const text = decodeHtmlEntities(isRssInsight ? description ?? '' : `${description}`) as string;
  const formattedTitle = isRssInsight && !isNewPodcastType ? `> ${title}` : title;

  if (AiGenerated) {
    const onClickOpenModal = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (isOpenPodcastModal) {
        setIsOpenPodcastModal((prev) => !prev);
      } else {
        setIsAnimating(true);
        setTimeout(() => {
          setIsOpenPodcastModal((prev) => !prev);
          setIsAnimating(false);
        }, 3000);
      }
    };

    return (
      <Styled.Header onClick={isSearchPage ? onSearchPageClick : undefined}>
        {isSearchPage && <ClickBlock onClick={isSearchPage ? onSearchPageClick : undefined} />}
        <FlexComponent flexDirection="column">
          <Styled.NewPodcastTypeTitle>
            {highlightText({ text: title, highlight: searchText as string, exactMatch: false })}
          </Styled.NewPodcastTypeTitle>
          {text && (
            <Styled.NewPodcastTypeText>
              {highlightText({ text, highlight: searchText as string, exactMatch: false })}{' '}
            </Styled.NewPodcastTypeText>
          )}
        </FlexComponent>
        {AiGenerated && !isQuote && (
          <Styled.NewPodcastButton isAnimating={isAnimating} isThreadOpen={isThreadOpen} onClick={onClickOpenModal}>
            {!isThreadOpen && <Image src={PodcastGenerate} width={14} height={14} alt="arrow top" />}
            {isThreadOpen ? 'hide summary of this episode' : 'generate detailed episode summary split by topics'}
            {isThreadOpen && <Image src={ArrowTopLeft} width={8} height={8} alt="Newsletter Link" style={{ marginLeft: '2px' }} />}
          </Styled.NewPodcastButton>
        )}
        <PodcastModalComponent originalPostId={originalPostId!} isOpen={isOpenPodcastModal} onClose={onClickOpenModal} />
      </Styled.Header>
    );
  }

  return (
    <Styled.Header onClick={isSearchPage ? onSearchPageClick : undefined}>
      {isSearchPage && <ClickBlock onClick={isSearchPage ? onSearchPageClick : undefined} />}
      <FlexComponent flexDirection="column">
        <Styled.Description>
          {highlightText({ text: formattedTitle, highlight: searchText as string, exactMatch: false })}
        </Styled.Description>
        {text && (
          <Styled.Description>
            {highlightText({ text, highlight: searchText as string, exactMatch: false })}{' '}
            {!isNewPodcastType && !isPodcast && <Styled.ReadMoreSection>read more</Styled.ReadMoreSection>}{' '}
          </Styled.Description>
        )}
      </FlexComponent>
      {!isNewPodcastType && !isPodcast && (
        <FlexComponent height={isSecurityListEmpty ? '26px' : 'auto'} gap="0px" justifyContent="">
          {securitiesList}
        </FlexComponent>
      )}
      {isPodcast && !isNewPodcastType && (
        <FlexComponent height={isSecurityListEmpty ? '26px' : 'auto'} gap="0px" justifyContent="">
          {securitiesList}
          <Styled.LinkPodcast isSecurityListEmpty={isSecurityListEmpty} onClick={handleLinkClick}>
            <Image src={ArrowTopRight} width={8} height={8} alt="Newsletter Link" style={{ marginRight: '5px' }} />
            read
          </Styled.LinkPodcast>
        </FlexComponent>
      )}
      {isNewPodcastType && !isQuote && (
        <Styled.PodcastButton isAnimating={isAnimating} isThreadOpen={isThreadOpen} onClick={openPodcast}>
          {!isThreadOpen && <Image src={PodcastGenerate} width={14} height={14} alt="arrow top" />}
          {isThreadOpen ? 'hide summary of this episode' : 'generate summary of this episode'}
          {isThreadOpen && <Image src={ArrowTopLeft} width={8} height={8} alt="Newsletter Link" style={{ marginLeft: '2px' }} />}
        </Styled.PodcastButton>
      )}
    </Styled.Header>
  );
};
