import styled from 'styled-components';
import { Modal } from '@mui/material';
import { APP_MIN_MARGIN_LEFT_MODAL, APP_MIN_MARGIN_RIGHT, APP_WIDTH_MODAL, MODAL_SLIDE_TIMEOUT } from '@/common/consts';

export const ModalWrapper = styled(Modal)<{
  scrollX: number;
  isLeft?: boolean;
  isCenter?: boolean;
  isCreateModal?: boolean;
  isTopLeft?: boolean;
  isSubmitIdea?: boolean;
}>`
  display: flex;
  justify-content: ${({ isLeft, isCenter, isTopLeft }) => (isCenter ? 'center' : isLeft || isTopLeft ? 'flex-start' : 'flex-end')};
  align-items: start;
  ${({ isLeft, isCenter, isTopLeft }) =>
    isCenter
      ? ''
      : isLeft
      ? `padding-left: calc(((100vw - ${APP_WIDTH_MODAL}px) / 2) + ${APP_MIN_MARGIN_LEFT_MODAL}px);`
      : isTopLeft
      ? `padding-left: calc(((100vw - ${APP_WIDTH_MODAL}px) / 2) + ${230}px);`
      : `padding-right: calc(((100vw - ${APP_WIDTH_MODAL}px) / 2) + ${APP_MIN_MARGIN_RIGHT}px);`}
  ${({ isCreateModal, isSubmitIdea }) => {
    if (isCreateModal && isSubmitIdea) return 'padding-right: calc((100vw - 580px) / 2);';
    if (isCreateModal) return 'padding-right: calc((100vw - 1210px) / 2);';
  }}
  @media (max-width: ${APP_WIDTH_MODAL}px) {
    ${({ isLeft, scrollX, isCenter, isTopLeft }) =>
      isCenter
        ? ''
        : isLeft
        ? `padding-left: calc(100vw - ${APP_WIDTH_MODAL - APP_MIN_MARGIN_LEFT_MODAL}px + ${scrollX}px);`
        : isTopLeft
        ? ''
        : `padding-right: calc(100vw - ${APP_WIDTH_MODAL - APP_MIN_MARGIN_RIGHT}px + ${scrollX}px);`}
    ${({ isCreateModal, isSubmitIdea }) => {
      if (isCreateModal && isSubmitIdea) return 'padding-right: calc((100vw - 580px) / 2);';
      if (isCreateModal) return 'padding-right: calc((100vw - 1210px) / 2);';
    }}
  }
`;

export const ChildrenWrapper = styled.div<{
  hideChildBackground?: boolean;
  hideChildeShadow?: boolean;
  hideBorder?: boolean;
  isScrollDisabled?: boolean;
  isCenter?: boolean;
  isLeft?: boolean;
  childTopPostion?: number;
  isUserModeSwitchModal?: boolean;
  isTopLeft?: boolean;
}>`
  position: relative;
  top: ${({ childTopPostion }) => (typeof childTopPostion !== 'undefined' ? `${childTopPostion}px` : '100px')};
  margin-bottom: 30px;
  max-height: ${({ isUserModeSwitchModal, childTopPostion }) =>
    isUserModeSwitchModal
      ? '100vh'
      : typeof childTopPostion !== 'undefined'
      ? `calc(100vh - ${childTopPostion}px - ${childTopPostion}px)`
      : 'calc(100vh - 100px)'};
  border-radius: ${({ isTopLeft }) => (isTopLeft ? 37 : 5)}px;
  ${({ hideChildBackground }) => !hideChildBackground && 'background: #FFF;'}
  ${({ hideChildeShadow }) => !hideChildeShadow && 'box-shadow: 0px 2px 30px 0px #e1e1e1;'}
  backdrop-filter: ${({ isUserModeSwitchModal }) => (isUserModeSwitchModal ? 'blur(2.5px)' : 'blur(1.5px)')};
  ${({ isUserModeSwitchModal }) => isUserModeSwitchModal && 'background: rgba(232, 232, 232, 0.80);'}
  /* ${({ isScrollDisabled }) => !isScrollDisabled && 'overflow-y: scroll;'} */
  ${({ hideBorder }) => !hideBorder && 'border: 1px solid #EAEAEA;'}

  -ms-overflow-style: none;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &:focus-visible {
    outline: none;
  }
`;
