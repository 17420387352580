import { useEffect } from 'react';

interface Props {
  isDisabled: boolean;
  onSubmit: () => void;
}

export const useKeyboardSubmit = ({ isDisabled, onSubmit }: Props) => {
  useEffect(() => {
    const handleCtrlEnter = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'Enter' && !isDisabled) {
        onSubmit();
      }
    };

    window.addEventListener('keydown', handleCtrlEnter);
    return () => {
      window.removeEventListener('keydown', handleCtrlEnter);
    };
  }, [isDisabled, onSubmit]);
};
