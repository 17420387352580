import { IChannel, IChannelData } from '../types/channel.type';
import { ISetTreadAndPostsProps } from '../types/post.type';
import { setPostStatistics } from '../utils/post-statistics';
import { axiosInstance } from './axios';
import { EnhancedWithAuthHttpService } from './http-auth.service';
import { HttpService } from './http.service';

class ChannelService extends EnhancedWithAuthHttpService {
  constructor() {
    super(new HttpService(axiosInstance));
  }

  async getAllChannels() {
    return this.get<IChannel[]>('channel');
  }

  async getChannelData(
    { setTread, queryClient, setPosts }: ISetTreadAndPostsProps,
    id?: string,
    page?: number,
    take?: number,
    signal?: AbortSignal
  ) {
    const query = new URLSearchParams();

    query.append('page', page ? page.toString() : '0');
    query.append('limit', take ? take.toString() : '20');
    const postResponse = await this.get<IChannelData>(`channel/data/${id}?${query}`, { signal });

    if (postResponse) {
      postResponse.posts.forEach((post: any) => {
        setTread(post.tread, post.id);
        post.tread.replies.forEach((reply: any) => {
          setPostStatistics(reply, queryClient);
        });
        setPostStatistics(post, queryClient);
        if (post.post) {
          setPostStatistics(post.post, queryClient);
        }
        if (post.originalPost) {
          setPostStatistics(post.originalPost, queryClient);
        }
      });
      setPosts(postResponse.posts, page ?? 1);
    }
    return postResponse;
  }

  async updateUserChannels(id: string) {
    return this.patch<unknown, { id: string }>('channel/update-user-channels-onboarding', { id });
  }

  async updateUserFirstVisitedChannels(id: string) {
    return this.patch<unknown, { id: string }>('channel/update-user-first-visited-channels', { id });
  }
}

export const channelService = new ChannelService();
