import styled, { css } from 'styled-components';
import Image from 'next/image';
import { motion } from 'framer-motion';
import { ModalComponent } from '@/common/components/modal';
import { COLORS, FONTS } from '@/theme';
import { FAMILIES, WEIGHTS } from '@/theme/fonts.const';
import { FlexComponent } from '@/common/components/flex';

export const IdeaModalWrapper = styled(ModalComponent)`
  position: relative;
  min-width: 602px;
  max-width: 602px;
  overflow: hidden;
  min-height: calc(100vh - 30px);
  padding: 20px 30px;
  padding-bottom: 0;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  display: flex;

  flex-direction: column;
  color: #000;
  border-radius: 10px !important;
  box-shadow: -5px 5px 10px 0px rgba(202, 202, 202, 0.15);
  z-index: 15 !important;
  overflow-y: auto;

  border: 1px solid #eaeaea;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: -5px 5px 10px 0px rgba(202, 202, 202, 0.15);
  backdrop-filter: blur(5px);
`;

export const DirectionText = styled('p')<{ isShort?: boolean; isColored?: boolean }>`
  color: ${({ isColored, isShort }) => (isColored ? (isShort ? COLORS.purple : COLORS.esmerald) : COLORS.black)};
  font-family: ${FAMILIES.robotoFlex};
  height: 15px;
  font-size: 15px;
  font-style: normal;
  font-weight: ${WEIGHTS.light};
  line-height: 15px;
`;

export const CompanyText = styled('p')<{ isShort?: boolean; isColored?: boolean }>`
  color: ${({ isColored, isShort }) => (isColored ? (isShort ? COLORS.purple : COLORS.esmerald) : COLORS.black)};
  font-family: ${FAMILIES.robotoFlex};
  font-size: 15px;
  font-style: normal;
  font-weight: ${WEIGHTS.medium};
  line-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 375px;
`;

export const RiskXText = styled('p')<{ isShort?: boolean; isColored?: boolean }>`
  color: ${({ isColored, isShort }) => (isColored ? (isShort ? COLORS.purple : COLORS.esmerald) : COLORS.black)};
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
`;

export const RiskText = styled('p')<{ isShort?: boolean; isColored?: boolean }>`
  color: ${({ isColored, isShort }) => (isColored ? (isShort ? COLORS.purple : COLORS.esmerald) : COLORS.black)};
  font-family: ${FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 1.2px;
`;

export const PriceTargetText = styled('p')`
  color: #2d2d2d;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
`;

export const PriceTargetPercentsText = styled('p')`
  color: #5e5e5e;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
`;

export const SubSectorText = styled('p')`
  color: #676767;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
`;

export const DotText = styled('p')`
  font-family: ${FAMILIES.robotoFlex};
  color: #ccc;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
`;

export const ThesisTitle = styled('p')`
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const RatingTitle = styled(ThesisTitle)`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
`;

export const WrittenByText = styled('p')`
  color: #5e5e5e;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: -0.3px;
`;

export const ThesisText = styled('p')`
  margin-top: 4px;
  margin-bottom: -2px;
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  /* max-height: 602px; */
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  white-space: pre-wrap;
`;

export const Row = styled(FlexComponent)`
  gap: 10px;
  height: 20px;
  align-items: baseline;
`;

export const OptionalTitle = styled('p')`
  font-family: ${FAMILIES.robotoFlex};
  color: #434343;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 13px;
  min-width: 92px;
`;

export const OptionalText = styled('p')`
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  & > span {
    color: #000;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
  }
`;

export const PublishText = styled('p')`
  padding-top: 5px;
  font-family: ${FAMILIES.robotoFlex};
  color: #656565;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
`;

export const EmptyTextValue = styled('p')`
  color: #e1e1e1;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
`;

export const BottomContainer = styled('div')<{ withoutPagination?: boolean }>`
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ withoutPagination }) => (withoutPagination ? '90px' : '90px')};
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: auto;
  align-items: center;
  gap: 14px;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: inset 0px 0px 10px 10px rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(2.5px);
`;

export const ActionsBox = styled('div')<{ marginTop?: string; leftMargin?: boolean; marginBottom?: string }>`
  position: relative;
  margin-top: ${({ marginTop }) => marginTop || '4px'};

  ${({ leftMargin }) =>
    leftMargin &&
    css`
      margin-left: -67px;
    `}

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom};
    `}
`;

export const FlexComponentBox = styled(FlexComponent)`
  height: 17px !important;
`;

export const OptionsButton = styled('button')<{ minWidth?: string; marginLeft?: string }>`
  display: flex;
  padding: 10px 15px;
  align-items: center;
  gap: 6px;
  font-family: ${FAMILIES.robotoFlex};
  color: #0018f1;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  height: 34px;
  border-radius: 7px;
  border: none;
  background: #f3f4ff;
  min-width: ${({ minWidth }) => minWidth || '165px'};
  max-width: 365px;
  white-space: nowrap;

  margin-top: 16px;
  cursor: pointer;
`;

export const AddReplyButtonButton = styled(motion.button)<{ minWidth?: string; marginLeft?: string }>`
  display: flex;

  align-items: center;
  gap: 6px;
  font-family: ${FAMILIES.robotoFlex};
  color: #0018f1;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  height: 24px;
  border-radius: 20px;
  border: none;
  background: transparent;
  min-width: ${({ minWidth }) => minWidth || '165px'};
  max-width: 365px;
  margin-left: ${({ marginLeft }) => marginLeft || 0};
  white-space: nowrap;

  cursor: pointer;
`;

export const Divider = styled('div')<{ minWidth?: string; marginLeft?: string; mt?: number }>`
  min-height: 1px;
  width: 540px;
  background: #f3f3f3;
  margin-top: 25px;

  ${({ mt }) =>
    mt &&
    css`
      margin-top: ${mt}px;
    `}
`;

export const ReplyTitle = styled(motion.p)`
  color: #2e2e2e;
  margin-top: 20px;
  margin-bottom: 17px;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;

export const ControlButton = styled('button')<{ justifyContent?: string }>`
  display: flex;
  min-width: 265px;
  display: flex;
  padding: 15px;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  gap: 8px;
  border: none;
  border-radius: 10px;
  background: #f3f4ff;
  color: #0018f1;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;

  cursor: pointer;

  :disabled {
    background: rgba(243, 244, 255, 0.5);
    color: rgba(0, 24, 241, 0.2);
    cursor: default;
  }
`;

export const CloseButton = styled.button`
  background-color: #fff;
  border: none;
  cursor: pointer;
`;

export const CloseButtonImage = styled(Image)`
  width: 20px;
  height: 20px;
`;

export const ArrowIcon = styled(Image)<{ isDisabled?: boolean }>`
  width: 20px;
  height: 20px;

  ${({ isDisabled }) =>
    isDisabled &&
    `
   opacity: 0.2;
  `};
`;

export const ArrowIconSmall = styled(Image)`
  width: 8px;
  height: 8px;
`;

export const StarIcon = styled(Image)`
  position: absolute;
  left: -19px;
  bottom: 3.5px;
  width: 14px;
  height: 14px;
`;
