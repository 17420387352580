import React, { ReactElement, useEffect, useRef, useState } from 'react';
import ShortIdeaArrow from '@styles/icons/short-idea-arrow.webp';
import LongIdeaArrow from '@styles/icons/long-idea-arrow.webp';
import { FlexComponent } from '@/common/components/flex';
import * as Styled from './idea-page-basic-info.styled';
import { DateTimeOutput } from '../../date';
import { ISubPost } from '@/common/types/post.type';

interface IProp {
  isTrending?: boolean;
  fullName: string;
  deletedAt?: Date;
  isAnon?: boolean;
  isHidden?: boolean;
  isQuote?: boolean;
  isLatestCoverage?: boolean;
  date?: Date;
  isFaded?: boolean;
  isOriginalPostFaded?: boolean;
  isInCoverageLatest?: boolean;
  className?: string;
  post?: ISubPost;
  typePost?: string;
  onClickBy: () => void;
}

export const IdeaPageBasicInfoComponent = ({
  post,
  isHidden,
  isLatestCoverage,
  isOriginalPostFaded,
  isFaded,
  deletedAt,
  isTrending,
  isQuote,
  fullName,
  typePost,
  isAnon,
  isInCoverageLatest,
  date,
  onClickBy,
  className
}: IProp): ReactElement => {
  const elementRef = useRef<HTMLDivElement>(null);
  const memo = post?.memoSection?.memo;
  const [usernameWidth, setUsernameWidth] = useState<number>(0);
  const postTitle = `Author: ${post?.memoSection?.memo?.author || post?.profile?.fullName || 'Anonymous'}`;
  const isShort = memo?.direction === 'SHORT';

  useEffect(() => {
    if (elementRef.current) {
      setUsernameWidth(elementRef.current.getBoundingClientRect().width);
    }
  }, []);

  return (
    <div>
      <Styled.Wrapper isHidden={isHidden} isLatestCoverage={isLatestCoverage} className={className}>
        {!isQuote && (
          <FlexComponent alignItems="baseline">
            <Styled.IdeaArrowWrapper isShort={isShort}>
              <Styled.IdeaArrowIcon src={isShort ? ShortIdeaArrow : LongIdeaArrow} alt="idea arrow" />
            </Styled.IdeaArrowWrapper>
          </FlexComponent>
        )}
        <Styled.InfoWrapper typePost={typePost} isQuote={isQuote} isInCoverageLatest={isInCoverageLatest}>
          <Styled.FullNameWrapper isTrending={!!isTrending} isAnon={!!isAnon} isQuote={isQuote} isFaded={isFaded || isOriginalPostFaded}>
            {isQuote && !deletedAt && 'quoting'}
          </Styled.FullNameWrapper>
          {!isQuote && (
            <FlexComponent gap="8px" alignItems="baseline">
              {isHidden ? (
                <Styled.Title usernameWidth={usernameWidth}>{fullName}</Styled.Title>
              ) : (
                <Styled.Title usernameWidth={usernameWidth}>{postTitle}</Styled.Title>
              )}
            </FlexComponent>
          )}
          {isQuote && !!date && <DateTimeOutput isQuote={isQuote} date={date} />}
        </Styled.InfoWrapper>
      </Styled.Wrapper>
    </div>
  );
};
