import {
  IGetNextOrPrevRepliesRequest,
  IGetPostsResponse,
  IGetRepliesRequest,
  INextRepliesInTreadResponse,
  IPost,
  IPostCreatePayload,
  IPostDebateResultDetails,
  IPostStatResponseItem,
  IPostStatResponseItemOld,
  IPrevRepliesInTreadResponse,
  IQuoteAndRepostByGroups,
  IRepliePayload,
  IRepostPayload,
  IRepostResponce,
  ISetPostTreadProps,
  ISetTreadAndPostsProps,
  IStatistic
} from '../types/post.type';
import { axiosInstance } from './axios';
import { EnhancedWithAuthHttpService } from './http-auth.service';
import { HttpService } from './http.service';
import { setPostStatistics } from '../utils/post-statistics';
import { IReviewCreatePayload } from '../types/review.type';
import { IFilters, IFiltersResponse } from '../types/newsfeed.type';
import { getNewsfeedFilters } from '../utils/get-newsfeed-filters';

class PostService extends EnhancedWithAuthHttpService {
  constructor() {
    super(new HttpService(axiosInstance));
  }

  async getPosts(id: string, page: number, take: number, { setTread, queryClient, setPosts }: ISetTreadAndPostsProps) {
    const postResponse = await this.get<IGetPostsResponse>(`post/posts/${id}`, { params: { page, take } });
    if (postResponse?.posts?.length) {
      await Promise.all(
        postResponse.posts.map(async (post) => {
          await setTread(post.tread, post.id);
          Promise.all(
            post.tread.replies.map(async (reply) => {
              await setPostStatistics(reply, queryClient);
            })
          );
          await setPostStatistics(post, queryClient);
          if (post.post) {
            await setPostStatistics(post.post, queryClient);
          }
          if (post.originalPost) {
            await setPostStatistics(post.originalPost, queryClient);
          }
        })
      );
      await setPosts(postResponse.posts, page);
    }
    return postResponse;
  }

  async getPost(id: string, searchedPostId?: string) {
    if (searchedPostId) {
      return this.get<IPost>(`post/${id}?searchedPostId=${searchedPostId}`);
    }
    return this.get<IPost>(`post/${id}`);
  }

  async getInsightPodcastPostModal(id: string, searchedPostId?: string) {
    if (searchedPostId) {
      return this.get<IPost>(`post/insight-post/${id}?searchedPostId=${searchedPostId}`);
    }
    return this.get<IPost>(`post/insight-post/${id}`);
  }

  async getPostStatistic(id: string) {
    return this.get<IStatistic>(`post/statistic/${id}`);
  }

  async getPostStatisticReply(id: string) {
    return this.get<IStatistic>(`post/statistic-reply/${id}`);
  }

  async getDebateDetails(questionId: string) {
    return this.get<IPostDebateResultDetails>(`post/debate-details/${questionId}`);
  }

  async getPollDetails(pollId: string) {
    return this.get<IPostDebateResultDetails>(`post/poll-details/${pollId}`);
  }

  async getAllPosts({ setTread, queryClient, setPosts }: ISetTreadAndPostsProps, currentFilter?: IFilters, page?: number, take?: number) {
    const query = new URLSearchParams();

    const { include, exclude, includeTime, excludeTime } = getNewsfeedFilters(currentFilter);
    query.append('page', page ? page.toString() : '0');
    query.append('limit', take ? take.toString() : '20');
    const timeZone = new Date().getTimezoneOffset() / 60;
    query.append('timeZone', timeZone.toString());

    include.forEach((id) => query.append('include[]', id));
    exclude.forEach((id) => query.append('exclude[]', id));

    includeTime.forEach((id) => query.append('includeTime[]', id));
    excludeTime.forEach((id) => query.append('excludeTime[]', id));
    const postResponse = await this.get<IGetPostsResponse>(`post/home-all?${query}`);
    if (postResponse?.posts?.length) {
      postResponse.posts.forEach((post) => {
        setTread(post.tread, post.id);
        post.tread.replies.forEach((reply) => {
          setPostStatistics(reply, queryClient);
        });
        setPostStatistics(post, queryClient);
        if (post.post) {
          setPostStatistics(post.post, queryClient);
        }
        if (post.originalPost) {
          setPostStatistics(post.originalPost, queryClient);
        }
      });
      setPosts(postResponse.posts, page ?? 1);
    }
    return postResponse;
  }

  async getPodcastInsightsPosts(
    { setTread, queryClient, setPosts }: ISetTreadAndPostsProps,
    page?: number,
    take?: number,
    signal?: AbortSignal
  ) {
    const query = new URLSearchParams();

    query.append('page', page ? page.toString() : '0');
    query.append('limit', take ? take.toString() : '20');

    const postResponse = await this.get<IGetPostsResponse>(`post/podcast-insights?${query}`, { signal });
    if (postResponse) {
      postResponse.posts.forEach((post) => {
        setTread(post.tread, post.id);
        post.tread.replies.forEach((reply) => {
          setPostStatistics(reply, queryClient);
        });
        setPostStatistics(post, queryClient);
        if (post.post) {
          setPostStatistics(post.post, queryClient);
        }
        if (post.originalPost) {
          setPostStatistics(post.originalPost, queryClient);
        }
      });
      setPosts(postResponse.posts, page ?? 1);
    }
    return postResponse;
  }

  async getFollowingPosts(
    { setTread, queryClient, setPosts }: ISetTreadAndPostsProps,
    currentFilter?: IFilters,
    page?: number,
    take?: number,
    signal?: AbortSignal
  ) {
    const query = new URLSearchParams();

    const includeSectorId: string[] = [];
    const excludeSectorId: string[] = [];
    const includeTopics: string[] = [];
    const excludeTopics: string[] = [];
    const includeSource: string[] = [];
    const excludeSource: string[] = [];

    if (currentFilter) {
      if (currentFilter.sectors && currentFilter.sectors.length > 0) {
        currentFilter.sectors.filter((s) => s.include).forEach((s) => includeSectorId.push(s.id));
        currentFilter.sectors.filter((s) => !s.include).forEach((s) => excludeSectorId.push(s.id));
      }

      if (currentFilter.source && currentFilter.source.length > 0) {
        currentFilter.source.filter((s) => s.include).forEach((s) => includeSource.push(s.id));
        currentFilter.source.filter((s) => !s.include).forEach((s) => excludeSource.push(s.id));
      }

      if (currentFilter?.topics && currentFilter?.topics.length > 0) {
        currentFilter.topics.filter((s) => s.include).forEach((s) => includeTopics.push(s.id));
        currentFilter.topics.filter((s) => !s.include).forEach((s) => excludeTopics.push(s.id));
      }
    }

    query.append('page', page ? page.toString() : '0');
    query.append('limit', take ? take.toString() : '20');

    includeSectorId.forEach((id) => query.append('includeSectorId[]', id));
    excludeSectorId.forEach((id) => query.append('excludeSectorId[]', id));

    includeTopics.forEach((id) => query.append('includeTopics[]', id));
    excludeTopics.forEach((id) => query.append('excludeTopics[]', id));

    includeSource.forEach((id) => query.append('includeSource[]', id));
    excludeSource.forEach((id) => query.append('excludeSource[]', id));

    const postResponse = await this.get<IGetPostsResponse>(`post?${query}`, { signal });
    if (postResponse) {
      postResponse.posts.forEach((post) => {
        setTread(post.tread, post.id);
        post.tread.replies.forEach((reply) => {
          setPostStatistics(reply, queryClient);
        });
        setPostStatistics(post, queryClient);
        if (post.post) {
          setPostStatistics(post.post, queryClient);
        }
        if (post.originalPost) {
          setPostStatistics(post.originalPost, queryClient);
        }
      });
      setPosts(postResponse.posts, page ?? 1);
    }
    return postResponse;
  }

  async getHomeFilters(currentFilter: IFilters) {
    const query = new URLSearchParams();

    const includeSectorId: string[] = [];
    const excludeSectorId: string[] = [];
    const includeTopics: string[] = [];
    const excludeTopics: string[] = [];
    const includeSource: string[] = [];
    const excludeSource: string[] = [];

    if (currentFilter) {
      if (currentFilter.sectors && currentFilter.sectors.length > 0) {
        currentFilter.sectors.filter((s) => s.include).forEach((s) => includeSectorId.push(s.id));
        currentFilter.sectors.filter((s) => !s.include).forEach((s) => excludeSectorId.push(s.id));
      }

      if (currentFilter.source && currentFilter.source.length > 0) {
        currentFilter.source.filter((s) => s.include).forEach((s) => includeSource.push(s.id));
        currentFilter.source.filter((s) => !s.include).forEach((s) => excludeSource.push(s.id));
      }

      if (currentFilter?.topics && currentFilter?.topics.length > 0) {
        currentFilter.topics.filter((s) => s.include).forEach((s) => includeTopics.push(s.id));
        currentFilter.topics.filter((s) => !s.include).forEach((s) => excludeTopics.push(s.id));
      }
    }

    includeSectorId.forEach((id) => query.append('includeSectorId[]', id));
    excludeSectorId.forEach((id) => query.append('excludeSectorId[]', id));

    includeTopics.forEach((id) => query.append('includeTopics[]', id));
    excludeTopics.forEach((id) => query.append('excludeTopics[]', id));

    includeSource.forEach((id) => query.append('includeSource[]', id));
    excludeSource.forEach((id) => query.append('excludeSource[]', id));

    return this.get<IFiltersResponse>(`post/filters?${query}`);
  }

  async getFundLettersPosts(
    { setTread, queryClient, setPosts }: ISetTreadAndPostsProps,
    page?: number,
    take?: number,
    signal?: AbortSignal
  ) {
    const query = new URLSearchParams();

    query.append('page', page ? page.toString() : '0');
    query.append('limit', take ? take.toString() : '20');

    const postResponse = await this.get<IGetPostsResponse>(`post/fund-letters?${query}`, { signal });
    if (postResponse) {
      postResponse.posts.forEach((post) => {
        setTread(post.tread, post.id);
        post.tread.replies.forEach((reply) => {
          setPostStatistics(reply, queryClient);
        });
        setPostStatistics(post, queryClient);
        if (post.post) {
          setPostStatistics(post.post, queryClient);
        }
        if (post.originalPost) {
          setPostStatistics(post.originalPost, queryClient);
        }
      });
      setPosts(postResponse.posts, page ?? 1);
    }
    return postResponse;
  }

  async getQuotePostsByPostId(postId: string, page?: number, take?: number) {
    return this.get<IGetPostsResponse>(`post/guote-posts/${postId}`, { params: { page, take } });
  }

  async getReplies({ originalPostId, take }: IGetRepliesRequest) {
    return this.get<INextRepliesInTreadResponse>(`post/replies/${originalPostId}?take=${take}`);
  }

  async getNextReplies({ originalPostId, searchedPostId, take }: IGetNextOrPrevRepliesRequest) {
    return this.get<INextRepliesInTreadResponse>(
      `post/next-replies-in-tread/${originalPostId}?take=${take}&searchedPostId=${searchedPostId}`
    );
  }

  async getPrevReplies({ originalPostId, searchedPostId, take }: IGetNextOrPrevRepliesRequest) {
    return this.get<IPrevRepliesInTreadResponse>(
      `post/prev-replies-in-tread/${originalPostId}?take=${take}&searchedPostId=${searchedPostId}`
    );
  }

  async getTrendingMusings({ setTread, queryClient }: ISetPostTreadProps) {
    const trendingPosts = await this.get<IPost[]>('post/trending');
    if (trendingPosts?.length) {
      trendingPosts?.forEach((item) => {
        setTread(item.tread, item.id);
        item.tread.replies.forEach((it) => {
          setPostStatistics(it, queryClient);
        });
        setPostStatistics(item, queryClient);
      });
    }
    return trendingPosts;
  }

  async createPost(post: IPostCreatePayload) {
    return this.post<IPost, IPostCreatePayload>('post', post);
  }

  async createReview(post: IReviewCreatePayload) {
    return this.post<IPost, IReviewCreatePayload>('post/review', post);
  }

  async createReplie(replie: IRepliePayload) {
    return this.post<{ reply: IPost; post: IPost }, IRepliePayload>('post/replie', replie);
  }

  async createRepost(repost: IRepostPayload) {
    return this.post<IRepostResponce, IRepostPayload>('post/repost', repost);
  }

  async getProfileByGroups(id: string) {
    return this.get<IQuoteAndRepostByGroups>(`post/groupedQuotePostAndRePost/${id}`);
  }

  async getPostReposts(postId: string, take: number, page: number) {
    return this.get<IPostStatResponseItem>(`post/post-reposts/${postId}?take=${take}&&page=${page}`);
  }

  async getPostQuotePosts(postId: string) {
    return this.get<IPostStatResponseItemOld[]>(`post/post-quote-posts/${postId}`);
  }

  async deletePost(postId: string) {
    return this.delete<IPost>(`post/${postId}`);
  }

  async getAdoratedPosts(page: number, take: number) {
    return this.get<IGetPostsResponse>(`post/adorations?page=${page}&take=${take}`);
  }
}

export const postService = new PostService();
