import React, { ChangeEvent, useEffect, useState } from 'react';
import AddReplyIconBlack from '@styles/icons/add-reply-black.svg';
import AddReplyIconGrey from '@styles/icons/add-reply-grey.svg';
import AddReplyIconBlue from '@styles/icons/arrow-bottom.png';

import { v4 } from 'uuid';
// eslint-disable-next-line max-len
import AddMaterialsIcon from '@styles/icons/add-insight-icon.webp';
import Image from 'next/image';
import { Stack } from '@mui/material';
import { IdeaDTO } from '@/common/types';
import { ISelectedCompany } from '@/common/types/coverage.type';
import NormalPostIcon from '@/styles/icons/normal-post.png';
import PrivatePostIcon from '@/styles/icons/private-post.png';
import { IdeaHorizon } from '@/common/types/idea.type';
import { Question } from '@/common/types/question.type';
import { SKIP_ANSWER } from '@/common/consts/idea.const';
import { IdeaVoteComponent } from '@/common/components/idea-vote';
import { useCheckedBasicPostInfo, useCreateIdea, useDeleteIdea, useUpdateIdea } from '@/common/hooks';
import { MODAL_SLIDE_TIMEOUT } from '@/common/consts';
import * as Styled from './coverage.styled';
import { InputWithImageComponent } from '../input-with-image/input-with-image.component';
import { OnDropErrorEnum } from '@/common/types/profile.type';
import { pasteBuffer } from '@/common/utils/copy-past-in-input';
import { handleDrop } from '@/common/utils/on-drop-in-input';
import { getBase64 } from '@/common/components/onboard/helpers';
import { ModalErrorOnDragAndDropComponent } from '@/common/components/modal-error-on-drag-and-drop';
import { INewPostReply } from '@/common/components/new-musing-modal/new-musing-modal.types';
import { NewPostReplyComponent } from '@/common/components/new-musing-modal/new-post-reply.component';
import { IconContainer, SectionTitle } from '../poll/poll.styled';
import { SecuritySelectComponent } from '../poll/components/security-select/security-select.component';
import { FlexComponent } from '@/common/components/flex';
import { StepperComponent } from '../poll/components/stepper';
import { OptionalTitleComponent } from '../optional-title';
import { PostFormActions } from '../insight/components/post-form-actions';

interface IIdeasModalComponentProps {
  open: boolean;
  idea: IdeaDTO | null;
  closeHandler: () => void;
  closeReplyModal?: () => void;
  setIsScrollDisabled: (it: boolean) => void;
}

const steps = [
  {
    value: '',
    lineHeight: '75px',
    disabledLineHeight: '75px'
  },
  {
    value: '',
    lineHeight: '70px',
    isDisabledDotLightGray: true,
    disabledLineHeight: '70px'
  },
  {
    isDisabledDotLightGray: true,
    value: ''
  }
];

export const CoverageComponent = ({ open, idea, closeHandler, closeReplyModal, setIsScrollDisabled }: IIdeasModalComponentProps) => {
  const [selectedCompany, setSelectedCompany] = useState<ISelectedCompany | null>(null);
  const [horizon, setHorizon] = useState<IdeaHorizon | null>(null);
  const [answer, setAnswer] = useState<number | null>(null);
  const [reasons, setReasons] = useState<string[]>([]);
  const [showEdit, setShowEdit] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [replies, setReplies] = useState<INewPostReply[]>([]);
  const [onDropError, setOnDropError] = useState<null | OnDropErrorEnum>(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const [isMoreThanMaxLength, setIsMoreThanMaxLength] = useState(false);
  const [text, setText] = useState('');
  const [image, setImage] = useState<string | ArrayBuffer | null>(null);

  const [viewSelected, setViewSelected] = useState(!!idea?.priority || idea?.priority === 0);

  const { mutateAsync: createIdea, isLoading: createIsLoading, isSuccess: createIsSuccess, reset: createReset } = useCreateIdea();
  const { mutateAsync: updateIdea, isLoading: updateIsLoading, isSuccess: updateIsSuccess, reset: updateReset } = useUpdateIdea();
  const { isSuccess: deleteIsSuccess, reset: deleteReset } = useDeleteIdea();
  const { info } = useCheckedBasicPostInfo();

  const resetFields = () => {
    setSelectedCompany(idea ? { id: idea.questionId, name: idea.text } : null);
    setHorizon(idea?.horizon || null);
    setAnswer(idea?.priority || idea?.priority === SKIP_ANSWER ? idea.priority : null);
    setReasons(idea?.reasons || []);
    createReset();
    updateReset();
    deleteReset();
    if (deleteClicked) {
      setDeleteClicked(false);
    }
  };

  useEffect(() => {
    resetFields();
  }, [idea]);

  useEffect(() => {
    setTimeout(() => {
      resetFields();
    }, MODAL_SLIDE_TIMEOUT);
  }, [open]);

  useEffect(() => {
    if (createIsSuccess || updateIsSuccess || deleteIsSuccess) {
      closeHandler();
      if (closeReplyModal) closeReplyModal();
    }
  }, [createIsSuccess, updateIsSuccess, deleteIsSuccess]);

  useEffect(() => {
    if (
      idea &&
      (horizon !== idea.horizon ||
        answer !== idea.priority ||
        reasons.length !== idea.reasons.length ||
        !reasons.every((val) => idea.reasons.includes(val)))
    ) {
      setShowEdit(true);
    } else if (showEdit) {
      setShowEdit(false);
    }
  }, [horizon, answer, reasons]);

  useEffect(() => {
    if (!viewSelected && (!!answer || answer === 0)) {
      setViewSelected(true);
    }
  }, [answer]);

  const confirmClick = async () => {
    try {
      if (idea) {
        await updateIdea({
          ideaId: idea.id,
          horizonSelect: horizon || IdeaHorizon.SKIP,
          priority: answer,
          reasons
        });
      } else if (selectedCompany) {
        await createIdea({
          type: Question.COMPANY,
          horizonSelect: horizon || IdeaHorizon.SKIP,
          priority: answer,
          questionId: selectedCompany.id,
          isPrivate,
          reasons,
          text,
          image,
          replies
        });
      }
      closeHandler();
    } catch {
      /* empty */
    }
  };

  const onPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    pasteBuffer(e, setImage);
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    handleDrop(e, setImage, setOnDropError);
  };

  const uploadPhoto = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const img = await getBase64(e.target.files[0]);
      setImage(img);
      e.target.value = '';
    }
  };

  const addReplyClick = () => {
    setReplies([...replies, { id: v4(), text: '', image: null }]);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter' && !event.currentTarget.disabled) {
      event.preventDefault();
      addReplyClick();
    }
  };

  const hasContent = (text && text.trim().length > 0) || image;

  const isReplyDisabled = replies.some((reply) => !((reply.text && reply.text.trim().length > 0) || reply.image));

  const isPostDisabled = !selectedCompany || answer === null;
  const isDisabled = !selectedCompany || isReplyDisabled;

  return (
    <div>
      <ModalErrorOnDragAndDropComponent type={onDropError} open={!!onDropError} closeHandler={() => setOnDropError(null)} />
      <Styled.CoverageContainer style={{ marginTop: '20px' }}>
        <Styled.TitleContainer>
          <IconContainer>
            <Image src={AddMaterialsIcon} width={12} height={12} alt="add-coverage" />
          </IconContainer>
          <Stack gap="10px">
            <Styled.ModalTitle>Add company coverage</Styled.ModalTitle>
            <Styled.ModalHeaderText>
              Add a listed or private company, which enables us to curate relevant content
              <br /> to you, and informs others who cover the same or comparable companies
            </Styled.ModalHeaderText>
          </Stack>
        </Styled.TitleContainer>
        <FlexComponent gap="15px">
          <StepperComponent isDotted step={1} steps={steps} />
          <Stack gap="30px">
            <Styled.Section>
              <SectionTitle>Select the company</SectionTitle>
              <SecuritySelectComponent
                placeholder="Enter company ticker / name"
                uniqCompanies
                company={selectedCompany}
                setCompany={setSelectedCompany}
              />
            </Styled.Section>
            <Styled.Section gap={10}>
              <OptionalTitleComponent title="Select your view" />
              <IdeaVoteComponent
                isCoverageModal
                borderRadius={5}
                answer={answer}
                allowChange
                answerCallback={(ideaAnswer: number) => {
                  if (answer === ideaAnswer) {
                    setAnswer(null);
                  } else {
                    setAnswer(ideaAnswer);
                  }
                }}
              />
            </Styled.Section>
            <Styled.Section gap={10}>
              <OptionalTitleComponent title="Add your thoughts" />
              <FlexComponent width="500px" position="relative">
                <InputWithImageComponent
                  text={text}
                  image={image}
                  setText={setText}
                  onPaste={onPaste}
                  onDrop={onDrop}
                  uploadPhoto={uploadPhoto}
                  removePhoto={() => setImage(null)}
                  setIsScrollDisabled={setIsScrollDisabled}
                  isEdit={!idea}
                  haveReply={!!replies.length}
                />
                <Styled.AddReplyButton
                  disabled={!hasContent || isMoreThanMaxLength || replies?.length >= 1}
                  onClick={addReplyClick}
                  onKeyDown={onKeyDown}
                  isFirst={!replies.length}
                >
                  add reply
                  <Styled.AddReplyButtonImage
                    src={!hasContent || isMoreThanMaxLength || replies?.length >= 1 ? AddReplyIconGrey : AddReplyIconBlue}
                    alt="send"
                  />
                </Styled.AddReplyButton>
              </FlexComponent>
            </Styled.Section>
            {!!info && !!replies?.length && !idea && (
              <Styled.RepliesContainer>
                {replies.map((reply, index) => (
                  <NewPostReplyComponent
                    isFirst={index === 0}
                    setIsMoreThanMaxLength={setIsMoreThanMaxLength}
                    reply={reply}
                    isCoverage
                    isCoverageList
                    setReplies={setReplies}
                    fullName={info.fullName}
                    profileId={info.id}
                    imageUrl={info.imageUrl}
                    role={info.role}
                    vertical={info.vertical}
                    key={reply.id}
                    onKeyDown={() => {}}
                    setIsScrollDisabled={setIsScrollDisabled}
                    isLast={index === replies.length - 1}
                  />
                ))}
                <Styled.AddReplyButton
                  left={76}
                  bottom={20}
                  disabled={!hasContent || isMoreThanMaxLength || isReplyDisabled}
                  onClick={addReplyClick}
                  onKeyDown={onKeyDown}
                  isFirst={!replies.length}
                >
                  add reply
                  <Styled.AddReplyButtonImage
                    src={!hasContent || isMoreThanMaxLength || isReplyDisabled ? AddReplyIconGrey : AddReplyIconBlue}
                    alt="send"
                  />
                </Styled.AddReplyButton>
              </Styled.RepliesContainer>
            )}
            <PostFormActions
              mt={replies?.length >= 1 ? -19 : -19}
              title="add to your coverage"
              isDisabled={createIsLoading || updateIsLoading || createIsSuccess || updateIsSuccess}
              isActive={!isDisabled}
              activeCallback={confirmClick}
            />
          </Stack>
        </FlexComponent>
      </Styled.CoverageContainer>
      <Styled.ActionsContainer>
        {!!selectedCompany && !!horizon && viewSelected && (
          <>
            <Styled.AddReplyButton
              disabled={isReplyDisabled || !hasContent || isMoreThanMaxLength || isPostDisabled}
              onClick={addReplyClick}
              isFirst={!replies.length}
            >
              <Styled.AddReplyButtonImage
                src={isReplyDisabled || !hasContent || isMoreThanMaxLength || isPostDisabled ? AddReplyIconGrey : AddReplyIconBlack}
                alt="send"
              />
              {replies.length ? 'add reply' : 'add reply to create thread'}
            </Styled.AddReplyButton>
            <Styled.PrivatePostButton onClick={() => setIsPrivate((prev) => !prev)} isPrivate={isPrivate}>
              <Styled.PrivatePostButtonImage priority src={isPrivate ? PrivatePostIcon : NormalPostIcon} alt="private-status" />
              {isPrivate ? 'only you' : 'normal'}
            </Styled.PrivatePostButton>
          </>
        )}
      </Styled.ActionsContainer>
    </div>
  );
};
