// eslint-disable-next-line import/no-cycle
import { QueryClient } from 'react-query';
import { INewPostReply } from '../components/new-musing-modal/new-musing-modal.types';
// eslint-disable-next-line import/no-cycle
import { IdeaHorizon } from './idea.type';
import { IProfileByGroups } from './like.type';
// eslint-disable-next-line import/no-cycle
import { Direction } from './memo.type';
import { IProfile, IProfileBasicInfo } from './profile.type';
// eslint-disable-next-line import/no-cycle
import { IQuestion, SentimentType } from './question.type';
// eslint-disable-next-line import/no-cycle
import { IReview } from './review.type';
import { IPoll } from './poll.types';
import { IInsightFeed } from './insight.types';

export interface IQuestionPosts extends IQuestion {
  ownerFullName?: string;
  ownerProfileId?: string;
  user: {
    answer: number;
    profile: IProfile;
    username?: string;
  };
  ownAnswer?: number;
  isHidden?: boolean;
  isResult?: boolean;
  isNotEnoughAnswers?: boolean;
  endAt?: string;
  isCanChange: boolean;
  followerAnswerCount?: number;
  answeredUserFullName?: string;
}
// IPoll & ISentimentSwings
export interface IPollPosts extends IPoll {
  ownerFullName?: string;
  ownerProfileId?: string;
  user: {
    answer: number;
    profile: IProfile;
    username?: string;
  };
  actualAnswer: number | null;
  ownAnswer?: number;
  isHidden?: boolean;
  isResult?: boolean;
  isNotEnoughAnswers?: boolean;
  endAt: Date;
  isCanChange: boolean;
  followerAnswerCount?: number;
  answeredUserFullName?: string;
  startDate: string;
  endDate: string;
  answers: number[];
  labels: string[];
  sentimentType: SentimentType;
}

export interface IPostDebateResultDetails {
  strongDisagree: IGetFiveProfilesFromTreadResponse[];
  moreStrongDisagree: number;
  highlyUnlikely: IGetFiveProfilesFromTreadResponse[];
  moreHighlyUnlikely: number;
  quiteUnlikely: IGetFiveProfilesFromTreadResponse[];
  moreQuiteUnlikely: number;
  quiteLikely: IGetFiveProfilesFromTreadResponse[];
  moreQuiteLikely: number;
  highlyLikely: IGetFiveProfilesFromTreadResponse[];
  moreHighlyLikely: number;
  strongLikely: IGetFiveProfilesFromTreadResponse[];
  moreStrongLikely: number;
}

export interface IUpdateReaction {
  statistic: IStatistic;
  post: IPost;
}

export interface ILinkPreview {
  link: string;
  domain: string;
  title: string;
  imageUrl?: string;
  description?: string;
}

export interface IIdeaPost {
  horizon: IdeaHorizon;
  priority: number;
  text: string;
  reasons?: string[];
}

export interface IStatistic {
  id: string;
  likeCount: number;
  isLiked: boolean;
  bookmarkCount: number;
  isBookmarked: boolean;
  replyCount: number;
  repostCount: number;
  isReposted: boolean;
  quotePostCount: number;
  isQuotePosted: boolean;
  ownReplyCount: number;
  praiseCount: number;
  isPraised: boolean;
  clapCount: number;
  isClaped: boolean;
  isOwnPost: boolean;
  isReplied: boolean;
  isImageHidden?: boolean;
  isHidden?: boolean;
  isSkipped?: boolean;
  isPrivate?: boolean;
}

export interface IPostDebateResult {
  moreNegative: number;
  aligned: number;
  morePositive: number;
}

export interface ISubPost extends IStatistic {
  isContinueOfInsight?: boolean;
  text: string;
  matchCount?: number;
  imageUrl?: string;
  isRepost?: boolean;
  profileId: string;
  profile: IProfileBasicInfo;
  sectors?: string[];
  vertical?: string;
  role?: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  additionalInfo?: {
    title?: string;
  };
  originalPostId?: string;
  postId?: string;
  isOriginalPostFaded?: boolean;
  questionResult?: IPostDebateResult;
  pollResult?: IPostDebateResult;
  memo: IMemo;
  review: IReview;
  memoSection: IMemoSection;
  memoSectionId: string;
  newsFeedId: string | null;
  newsFeed?: INewsfeed;
  question?: IQuestionPosts;
  poll?: IPollPosts;
  idea?: IIdeaPost;
  insight?: IInsightFeed;
  isRepliedPost?: boolean;
  linkPreviews: ILinkPreview[];
}

export interface IMemoSection {
  sectionName: string;
  section: string;
  title: string;
  id: string;
  direction: Direction;
  memoId: string;
  companyName?: string;
  text?: string;
  type?: string;
  imageUrls?: string[];
  risk?: number;
  memo?: IMemo;
  createdAt: Date;
  return: number | 0;
  priceTarget: number | 0;
  closePrice: number | 0;
}

export interface IMemo {
  author?: string;
  section: string;
  title: string;
  id: string;
  currency?: string;
  direction: Direction;
  createdAt: Date;
  backDateToggle: boolean;
  writedAt: Date;
  subSector?: {
    name?: string;
  };
  region?: {
    name?: string;
  };
  isAnon?: boolean;
}

export interface IGetFiveProfilesFromTreadResponse {
  id: string;
  isImageHidden: boolean;
  imageUrl?: string;
}

export interface ITreadStatus {
  hasNext: boolean;
  countNextReplies: number;
  hasPrev: boolean;
  countPrevFollowingUserImages: number;
}
export interface IRepliesInTreadResponse extends ITreadStatus {
  replies: IPostWithPostOrOriginalPost[];
  prevFollowingUserImages: IGetFiveProfilesFromTreadResponse[];
}

export interface IPrevRepliesInTreadResponse {
  replies: IPostWithPostOrOriginalPost[];
  hasPrev: boolean;
  countPrevFollowingUserImages: number;
  prevFollowingUserImages: IGetFiveProfilesFromTreadResponse[];
}

export interface INextRepliesInTreadResponse {
  replies: IPostWithPostOrOriginalPost[];
  hasNext: boolean;
  countNextReplies: number;
}

export interface IGetRepliesRequest {
  originalPostId: string;
  take: number;
}

export interface IGetNextOrPrevRepliesRequest extends IGetRepliesRequest {
  searchedPostId: string;
}

export interface IPostWithPostOrOriginalPost extends ISubPost {
  post?: ISubPost;
  originalPost?: ISubPost;
  isNew?: boolean;
  isSearched?: boolean;
  isRepliedPostView?: boolean;
}

export interface IPost extends IPostWithPostOrOriginalPost {
  tread: IRepliesInTreadResponse;
}

export interface IRepostResponce {
  repost: IPost;
  post: IPost;
}

export interface IGetPostsResponse {
  count: number;
  posts: IPost[];
  sectors: string[];
  topics: string[];
  source: string[];
  isFeedHidden?: boolean;
}

export interface IGetNewsfeedResponse {
  count: number;
  posts: IPost[];
}

export interface IPostPayload {
  text: string;
  image: string | ArrayBuffer | null;
}

export interface IPostCreatePayload extends IPostPayload {
  replies?: IPostPayload[];
  isPrivate: boolean;
  channelId?: string | null;
}

export interface IRepliePayload extends IPostPayload {
  postId?: string;
  originalPostId: string;
}

export interface IRepostPayload extends IPostPayload {
  postId: string;
  isRepost: boolean;
  replies?: INewPostReply[];
  isPrivate: boolean;
}

export interface IQuoteAndRepostByGroups {
  repost: IProfileByGroups;
  quotePost: IProfileByGroups;
}

export interface IPostStatResponseItemOld {
  id: string;
  fullName: string;
  connectionRequestSent: boolean;
  followingRequestSent: boolean;
  role: string;
  userId: string;
  vertical: string;
  imageUrl: string;
  isPrivate: boolean;
  follower: boolean;
  following: boolean;
  connected: boolean;
  isCurrentUser: boolean;
}

export interface IPostStatResponseItem {
  count: number;
  data: {
    id: string;
    fullName: string;
    connectionRequestSent: boolean;
    followingRequestSent: boolean;
    connectionRequestIgnored: boolean;
    followingRequestIgnored: boolean;
    role: string;
    userId: string;
    vertical: string;
    imageUrl: string;
    isPrivate: boolean;
    follower: boolean;
    following: boolean;
    connected: boolean;
    isCurrentUser: boolean;
    isImageHidden: boolean;
    isAnon: boolean;
  }[];
  metaData: {
    lastCursor: string;
    nextPage: boolean;
  };
}

export enum MentionType {
  USER = 'USER',
  SECURITY = 'SECURITY',
  SECTOR = 'SECTOR',
  SUB_SECTOR = 'SUB_SECTOR',
  EMPLOYER = 'EMPLOYER',
  PRIVATE = 'PRIVATE'
}

export const ConvertFromMentionTypeToAlgoliaType = {
  [MentionType.SECURITY]: 'security',
  [MentionType.SECTOR]: 'sectors',
  [MentionType.SUB_SECTOR]: 'sub-sectors',
  [MentionType.EMPLOYER]: 'employers',
  [MentionType.USER]: 'user',
  [MentionType.PRIVATE]: 'privates'
};

export interface IMentionOption {
  type: MentionType;
  value: string;
  label: string;
  image?: string;
  isImageHidden?: string;
}

export interface ISetPostTreadProps {
  setTread: (data: IRepliesInTreadResponse, newPostId?: string) => void;
  queryClient: QueryClient;
}

export interface ISetTreadAndPostsProps extends ISetPostTreadProps {
  setPosts: (postsData: IPost[], pageNumber: number) => void;
}

export interface INewsSource {
  id: string;
  fullName: string;
  imgUrl: null | string;
  active: boolean;
  sourceUrl: string;
  subscriptionId: number;
  createdAt: string;
}

export interface INewsfeed {
  id: string;
  description: string | null;
  title: string;
  imgUrl: null | string;
  link: string;
  newsSourceId: string;
  createdAt: string;
  newsSource: INewsSource;
  tags: ITag[];
}

export interface ITag {
  id: string;
  fullName: string;
  shortName: string;
  type: string;
}

export enum HomePostTabs {
  ALL = 'All',
  FOLLOWING = 'Following',
  NEWS = 'News'
}

export enum ProfilePostTabs {
  POST = 'Posts + Replies',
  BOOKMARK = 'Bookmarks',
  LIKES = 'Likes'
}

export enum PostType {
  Regular = 'regular',
  Quote = 'quote',
  Reply = 'reply'
}
