export enum CreateModalType {
  // COVERAGE = 'Coverage',
  POST = 'Post',
  POLL = "POLL",
  DEBATE = "DEBATE",
  COVERAGE = "COVERAGE",
  INSIGHT = "INSIGHT",
  REVIEW = "REVIEW"
  // DEBATE = 'Debate',
  // REVIEW = 'Review',
  // POLL = 'Poll',
  // INSIGHT = 'Insight'
}
