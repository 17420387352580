import styled, { AnyStyledComponent, css } from 'styled-components';
import { COLORS } from '@/theme';
import { PageWrapperComponent } from '../page-wrapper';
import { RightColumnStyle } from '../page-wrapper/components/right-column/right-column.styled';
import { LeftColumnComponent } from '../page-wrapper/components/left-column';

export const ProfilePageWrapper = styled(PageWrapperComponent as AnyStyledComponent)``;

export const ColumnsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  //gap: 50px;
`;

export const LeftColumn = styled(LeftColumnComponent)``;

const ColumnDividerStyled = css`
  position: fixed;
  top: 0;
  width: 0;
  height: 100%;
  border: 0.5px solid ${COLORS.NEW_BORDER_COLOR};
  z-index: 57;
`;

export const LeftColumnDivider = styled.div<{ sidebarWidth: number }>`
  ${ColumnDividerStyled}
  left: ${({ sidebarWidth }) => `${sidebarWidth}px`};
`;

export const RightColumnDivider = styled.div<{ sidebarWidth: number }>`
  ${ColumnDividerStyled}
  left: ${({ sidebarWidth }) => `${sidebarWidth + 606}px`};
`;

export const RightColumnWrapper = styled.div`
 
`;

export const RightColumn = styled.div`
  ${RightColumnStyle}

  margin-left: 30px;
  width: 400px;
  background-color: transparent;
  padding: 13.2px 0;
  display: flex;
  flex-direction: column;
  border: none;
`;

export const ProfileDivider = styled.div`
  background-color: ${COLORS.dividerColor};
  margin: 2.5rem 7.5rem 2.5rem 7rem;
  height: 3px;
`;
