/* eslint-disable @typescript-eslint/no-shadow */
import React, { SyntheticEvent, useEffect, useState } from 'react';
import Image from 'next/image';
import { ChannelType, SendbirdChatWith } from '@sendbird/chat';
import { GroupChannel, GroupChannelModule } from '@sendbird/chat/groupChannel';
import PrivateUserLogo from '@styles/icons/lock.png';
import Bookmark from '@styles/icons/message-bookmark.png';
import BookmarkLIU from '@styles/icons/message-bookmark-active.png';
import LikeLIU from '@styles/icons/like-LIU.png';
import ClapZero from '@styles/icons/clap-zero.png';
import ClapLIU from '@styles/icons/clap-LIU.png';
import LikeZero from '@styles/icons/like-zero.png';
import PostOption from '@styles/icons/post-option.png';
import InboxIcon from '@styles/icons/bookmark-inbox.png';
import { MessageRetrievalParams, UserMessage } from '@sendbird/chat/message';
import { FileMessage, GroupChannelListParams } from '@sendbird/chat/lib/__definition';
import { useRouter } from 'next/router';
import { IMessagePostResponse, TMessageResponse } from '@/common/types/bookmark.types';
import { bookmarkService } from '@/common/services';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { PublishModalComponent } from '@/common/components/publish-memo/components/image-modal/images-modal.component';
import { DateTimeOutput } from '@/common/components/post/components/date';
import * as Styled from './bookmarks-list.styled';
import ActiveBookMess from "@styles/icons/activeBokmarkIconInbox.png";
import BookMess from "@styles/icons/bookmarkIconInbox.png";
import ActiveLikeMess from "@styles/icons/activeLikeIconInbox.png";
import LikeMess from "@styles/icons/likeIconInbox.png";

interface Props {
  bookmark: TMessageResponse;
  sb: SendbirdChatWith<GroupChannelModule[]>;
  userProfileId: string;
  deleteBookmark: (data: TMessageResponse | IMessagePostResponse, newProfileId?: string) => void;
}

export const BookmarkMessageItem: React.FC<Props> = ({ bookmark, sb, userProfileId, deleteBookmark }) => {
  const { push } = useRouter();
  const [message, setMessage] = useState<UserMessage | FileMessage | null>(null);
  const [channel, setChannel] = useState<GroupChannel | null>(null);
  const displayUser = bookmark.displayUserInfo;
  const [modalImages, setModalImages] = useState<string[]>([]);
  const [selectedModalImage, setCurrentImage] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [isBookmarkLoading, setIsBookmarkLoading] = useState(false);
  const [isReactionDisabled, setIsReactionDisabled] = useState(false);

  const getMessage = async () => {
    const params: MessageRetrievalParams = {
      messageId: bookmark.messageId,
      channelType: ChannelType.GROUP,
      channelUrl: bookmark.channelUrl,
      includeReactions: true
    };
    const message = await sb.message.getMessage(params);
    setMessage(message as UserMessage | null);
  };

  const getChannel = async () => {
    const params: GroupChannelListParams = {
      includeEmpty: true,
      channelUrlsFilter: [bookmark.channelUrl]
    };
    const query = sb.groupChannel.createMyGroupChannelListQuery(params);

    const channels: GroupChannel[] = await query.next();
    setChannel(channels[0] as GroupChannel | null);
  };

  const messageDate = message ? new Date(message.createdAt) : null;
  const allReactions = message?.reactions.map((react) => react.key);
  const isClap = allReactions?.includes('clap');
  const isLike = allReactions?.includes('like');
  const isBookmark = message?.reactions.filter((react) => react.key === 'bookmark')[0]?.userIds.includes(userProfileId) ?? false;

  // const updateMessageReactions = async (message: UserMessage | FileMessage) => {
  //   const userMessageUpdateParams = {};
  //   await channel?.updateUserMessage(message.messageId, userMessageUpdateParams);
  // };

  const addMessageReaction = async (message: UserMessage | FileMessage, emojiKey: string) => {
    if (!channel) return;
    const reactionEvent = await channel.addReaction(message, emojiKey);
    message.applyReactionEvent(reactionEvent!);

    // await updateMessageReactions(message);
  };

  const removeMessageReaction = async (message: UserMessage | FileMessage, emojiKey: string) => {
    if (!channel) return;
    const reactionEvent = await channel.deleteReaction(message, emojiKey);
    message.applyReactionEvent(reactionEvent!);

    // await updateMessageReactions(message);
  };

  const handleReactionClick = async (message: UserMessage | FileMessage, emojiKey: string) => {
    if (isReactionDisabled) return;
    if (userProfileId === message.sender?.userId) return;
    setIsReactionDisabled(true);
    const reactedUsers = message.reactions
      .map((react) => {
        if (react.key === emojiKey) return react?.userIds;
        return [];
      })
      .flat();
    const isRemoveReaction = reactedUsers.includes(userProfileId);
    if (isRemoveReaction) {
      await removeMessageReaction(message, emojiKey);
    } else {
      await addMessageReaction(message, emojiKey);
    }
    setTimeout(() => {
      getMessage().then(() => setIsReactionDisabled(false));
    }, 200);
  };

  const handleBookmarkClick = async (message: UserMessage | FileMessage) => {
    if (isBookmarkLoading) return;
    const reactedUsers = message.reactions
      .map((react) => {
        if (react.key === 'bookmark') return react?.userIds;
        return [];
      })
      .flat();
    const isRemoveReaction = reactedUsers.includes(userProfileId);
    if (isRemoveReaction) {
      const dto = {
        channelUrl: message.channelUrl,
        messageId: message.messageId
      };
      setIsBookmarkLoading(true);
      await removeMessageReaction(message, 'bookmark');
      await bookmarkService.removeBookmark(dto);
      deleteBookmark(bookmark, userProfileId);
      setIsBookmarkLoading(false);
    }
  };

  const handleUserClick = (e: SyntheticEvent, userId: string) => {
    e.stopPropagation();
    push(`${ROUTER_KEYS.PROFILE}?id=${userId}`);
  };

  const onClickByImage = (e: React.MouseEvent<HTMLImageElement, MouseEvent> | undefined) => {
    if (!bookmark.file.url) return;
    e?.stopPropagation();
    const imageUrl = `${bookmark.file.url}?auth=${sb.ekey}`;
    setModalImages(imageUrl ? [imageUrl] : []);
    setCurrentImage(0);
    setOpen(true);
  };

  useEffect(() => {
    if (sb) {
      getMessage();
      getChannel();
    }
  }, []);

  return (
    <Styled.MessageContainer>
      <PublishModalComponent
        open={open}
        transitionExited={() => setModalImages([])}
        closeHandler={() => setOpen(false)}
        imgUrls={modalImages}
        current={selectedModalImage}
        setCurrentImage={setCurrentImage}
      />
      <Styled.MessageTopContainer>
        <Styled.TileHeader onClick={(e) => handleUserClick(e, bookmark.sender.userId)}>
          {displayUser?.isImageHidden ? (
            <Styled.HiddenImage>
              <Styled.LockImage src={PrivateUserLogo} alt="private" />
            </Styled.HiddenImage>
          ) : (
            <Styled.AvatarWrapper>
              {displayUser?.imageUrl && <img src={displayUser.imageUrl} height={30} width={30} alt={displayUser.fullName ?? 'user-icon'} />}
            </Styled.AvatarWrapper>
          )}
          <Styled.UserDetails>
            <Styled.UserFullName>{displayUser?.fullName ?? 'Deleted Account'}</Styled.UserFullName>
            {(displayUser?.vertical || displayUser?.role) && (
              <Styled.UserRoleInfo>
                {displayUser.vertical} {displayUser.role}
              </Styled.UserRoleInfo>
            )}
          </Styled.UserDetails>
          <Styled.InboxImage src={InboxIcon} alt="inbox-icon" />
        </Styled.TileHeader>
        <Styled.MessageBody>
          {bookmark.file.url && (
            <Styled.MessageImage src={`${bookmark.file.url}?auth=${sb.ekey}`} alt="user-image" onClick={onClickByImage} />
          )}
          {bookmark.message && <Styled.MessageText isCheckLinks>{bookmark.message}</Styled.MessageText>}
        </Styled.MessageBody>
      </Styled.MessageTopContainer>
      <Styled.MessageReactions>
        {bookmark.sender.userId !== userProfileId && (
          <Styled.ReactionsContainer>
            {!isLike && (
              <Styled.ReactionContainer>
                <Styled.ClapIcon src={isClap ? ClapLIU : ClapZero} alt="clap" onClick={() => handleReactionClick(message!, 'clap')} />
              </Styled.ReactionContainer>
            )}
            {!isClap && (
              <Styled.ReactionContainer>
                <Styled.LikeIcon src={isLike ? ActiveLikeMess : LikeMess} alt="like" onClick={() => handleReactionClick(message!, 'like')} />
              </Styled.ReactionContainer>
            )}
            <Styled.ReactionContainer>
              <Styled.BookmarkIcon src={isBookmark ? ActiveBookMess : BookMess} alt="bookmark" onClick={() => handleBookmarkClick(message!)} />
              <Styled.BookmarkText>User will not know you bookmarked</Styled.BookmarkText>
            </Styled.ReactionContainer>
          </Styled.ReactionsContainer>
        )}
        <Styled.OwnReactionsContainer>
          {bookmark.sender.userId === userProfileId && (
            <Styled.ReactionContainer extraMargin={20}>
              <Styled.BookmarkIcon src={isBookmark ? ActiveBookMess : BookMess} alt="bookmark" onClick={() => handleBookmarkClick(message!)} />
            </Styled.ReactionContainer>
          )}
          {bookmark.interlocutor && bookmark.sender.userId === userProfileId && (
            <>
              {isClap && (
                <Styled.ReactionContainer>
                  {/* <Styled.ClapIcon src={ClapLIU} alt="clap" />
                  <Styled.ReactionText>{bookmark.interlocutor.fullName.split(' ')[0]} applauded</Styled.ReactionText> */}
                </Styled.ReactionContainer>
              )}
              {isLike && (
                <Styled.ReactionContainer>
                  <Styled.LikeIcon src={ActiveBookMess} alt="like" />
                  <Styled.ReactionText>{bookmark.interlocutor.fullName.split(' ')[0]} liked</Styled.ReactionText>
                </Styled.ReactionContainer>
              )}
            </>
          )}
        </Styled.OwnReactionsContainer>
        <Styled.Controls>
          {messageDate && <DateTimeOutput date={messageDate} />}
          <Image src={PostOption} height={4} width={16} alt="more" />
        </Styled.Controls>
      </Styled.MessageReactions>
    </Styled.MessageContainer>
  );
};
