export const AlgoliaIndexes = {
  POSTS: 'posts',
  IDEAS: 'ideas',
  NEWSLETTERS: 'newsletters',
  PODCASTS: 'podcasts',
  FUND_MATERIALS: 'fund-materials',
  NEWS: 'news',
  USERS: 'users',
  SECTORS: 'sectors',
  SUB_SECTORS: 'sub-sectors',
  REGIONS: 'regions',
  SECURITY: 'security',
  KEY_DEBATES: 'key-debates',
  EMPLOYERS: 'employers',
  POLLS: 'polls',
  PRIVATES: 'privates'
};

export const keyValuePairs = [
  { key: AlgoliaIndexes.POSTS, value: 'Posts, Replies, Quote-Posts', lessThanTwoValue: 'Posts, Replies, Quote-Posts', anchor: 'posts' },
  { key: AlgoliaIndexes.NEWS, value: 'News', lessThanTwoValue: 'News', anchor: 'newsletters' },
  { key: AlgoliaIndexes.IDEAS, value: 'Ideas', lessThanTwoValue: 'Idea', anchor: 'ideas' },
  // { key: AlgoliaIndexes.NEWSLETTERS, value: 'Newsletters', lessThanTwoValue: 'Newsletters', anchor: 'newsletters' },
  { key: AlgoliaIndexes.PODCASTS, value: 'Podcasts', lessThanTwoValue: 'Podcasts', anchor: 'podcasts' },
  // { key: AlgoliaIndexes.FUND_MATERIALS, value: 'Fund materials', lessThanTwoValue: 'Fund materials', anchor: 'fund-materials' },
  // { key: AlgoliaIndexes.POLLS, value: 'Polls', lessThanTwoValue: 'Poll', anchor: 'polls' },
  { key: AlgoliaIndexes.KEY_DEBATES, value: 'Debates', lessThanTwoValue: 'Debates', anchor: 'debates' },
  { key: AlgoliaIndexes.USERS, value: 'Members', lessThanTwoValue: 'Member', anchor: 'members' },
  { key: AlgoliaIndexes.SECURITY, value: 'Securities', lessThanTwoValue: 'Securities', anchor: 'securities' },
  { key: AlgoliaIndexes.PRIVATES, value: 'Privates', lessThanTwoValue: 'Privates', anchor: 'privates' },
  { key: AlgoliaIndexes.EMPLOYERS, value: 'Employers', lessThanTwoValue: 'Employer', anchor: 'employers' },
  { key: AlgoliaIndexes.SECTORS, value: 'Sectors', lessThanTwoValue: 'Sector', anchor: 'sectors' },
  { key: AlgoliaIndexes.SUB_SECTORS, value: 'Sub-sectors', lessThanTwoValue: 'Sub-sector', anchor: 'sub-sectors' },
  { key: AlgoliaIndexes.REGIONS, value: 'Regions', lessThanTwoValue: 'Region', anchor: 'regions' }
];
