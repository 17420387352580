/* eslint-disable import/no-cycle */
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { QUERY_KEYS, ROUTER_KEYS } from '../consts/app-keys.const';
import { postService } from '../services/post.service';
import {
  INextRepliesInTreadResponse,
  IPost,
  IPostCreatePayload,
  IPostStatResponseItem,
  IPrevRepliesInTreadResponse,
  IRepliePayload,
  IRepliesInTreadResponse,
  IRepostPayload,
  IStatistic,
  ISubPost
} from '../types/post.type';
import { setPostStatistics, updateAdorationPost } from '../utils/post-statistics';
import { POST_LIMIT } from '../consts';
// eslint-disable-next-line import/no-cycle
import { useUser } from './use-user';
import { updatePostQuestion } from '../utils/mutate-posts';
import { IFilters } from '../types/newsfeed.type';
import { useAllUserBookmarks } from './use-bookmarks';
import { EBookmarkType } from '../types/bookmark.types';
import { memoService } from '../services';
import { LIMIT } from '../consts/memo.const';
import { queryClient } from '@/pages/_app';

export const usePostStatistic = (id?: string) => {
  const queryClient = useQueryClient();

  return useQuery([QUERY_KEYS.STATISTIC, id], () => queryClient.getQueryData<IStatistic>([QUERY_KEYS.STATISTIC, id]), { enabled: true });
};

export const useUpdatePostStatistic = () => {
  const queryClient = useQueryClient();
  return useMutation([QUERY_KEYS.STATISTIC, 'update'], (id: string) => postService.getPostStatistic(id), {
    onSuccess: (data) => {
      if (!data) return;
      setPostStatistics(data, queryClient);
    }
  });
};

export const useUpdatePostStatisticReply = () => {
  const queryClient = useQueryClient();
  return useMutation([QUERY_KEYS.STATISTIC, 'updateReply'], (id: string) => postService.getPostStatisticReply(id), {
    onSuccess: async (data) => {
      if (!data) return;
      setPostStatistics(data, queryClient);
    }
  });
};

export const useGetDebateDetails = () =>
  useMutation({
    mutationKey: [QUERY_KEYS.GET_DEBATE_DETAILS],
    mutationFn: (questionId: string) => postService.getDebateDetails(questionId)
  });

export const useTreadByPostId = (postId?: string) => {
  const queryClient = useQueryClient();
  const { data: tread } = useQuery([QUERY_KEYS.POST_TREAD_DATA, postId], () =>
    queryClient.getQueryData<IRepliesInTreadResponse>([QUERY_KEYS.POST_TREAD_DATA, postId])
  );
  return {
    ...tread,
    setTread: async (data: IRepliesInTreadResponse, newPostId?: string) => {
      await queryClient.setQueryData([QUERY_KEYS.POST_TREAD_DATA, postId ?? newPostId], data);
      // queryClient.invalidateQueries([QUERY_KEYS.POST_TREAD_DATA, postId ?? newPostId]);
    },
    setPrevReplies: (data: IPrevRepliesInTreadResponse) => {
      const treadData = queryClient.getQueryData<IRepliesInTreadResponse>([QUERY_KEYS.POST_TREAD_DATA, postId]);
      const oldReplies = treadData?.replies;
      const filterOldPosts = (oldReplies ?? []).filter(({ id }) => !data.replies.find(({ id: newId }) => id === newId));
      const newReplies = [...data.replies, ...(filterOldPosts ?? [])];
      const newTreadData = {
        ...treadData,
        ...data,
        replies: newReplies
      };

      queryClient.setQueryData([QUERY_KEYS.POST_TREAD_DATA, postId], newTreadData);
      // queryClient.invalidateQueries([QUERY_KEYS.POST_TREAD_DATA, postId]);
    },
    setNextReplies: (data: INextRepliesInTreadResponse) => {
      const treadData = queryClient.getQueryData<IRepliesInTreadResponse>([QUERY_KEYS.POST_TREAD_DATA, postId]);
      const oldReplies = treadData?.replies;
      const filterOldPosts = (oldReplies ?? []).filter(({ id }) => !data.replies.find(({ id: newId }) => id === newId));
      const newReplies = [...(filterOldPosts ?? []), ...data.replies];
      const newTreadData = {
        ...treadData,
        ...data,
        replies: newReplies
      };
      queryClient.setQueryData([QUERY_KEYS.POST_TREAD_DATA, postId], newTreadData);
      // queryClient.invalidateQueries([QUERY_KEYS.POST_TREAD_DATA, postId]);
    },
    setCreatedReply: (data: IPost, newPostId?: string) => {
      const treadData = queryClient.getQueryData<IRepliesInTreadResponse>([QUERY_KEYS.POST_TREAD_DATA, postId ?? newPostId]);
      const oldReplies = treadData?.replies;
      const filterOldPosts = (oldReplies ?? []).filter(({ id }) => data.id !== id);
      const updateInputData = {
        ...data,
        isNew: treadData?.hasNext
      };
      const newReplies = [...(filterOldPosts ?? []), updateInputData];
      const newTreadData = {
        ...treadData,
        replies: newReplies
      };
      queryClient.setQueryData([QUERY_KEYS.POST_TREAD_DATA, postId ?? newPostId], newTreadData);
      // queryClient.invalidateQueries([QUERY_KEYS.POST_TREAD_DATA, postId ?? newPostId]);
    },
    setUpdateReply: (data: ISubPost) => {
      const treadData = queryClient.getQueryData<IRepliesInTreadResponse>([QUERY_KEYS.POST_TREAD_DATA, postId]);
      const oldReplies = treadData?.replies;
      const newReplise = (oldReplies ?? []).map((it) => {
        if (it.id === data.id) {
          return data;
        }
        return it;
      });
      const newTread = {
        ...treadData,
        replies: newReplise
      };
      queryClient.setQueryData([QUERY_KEYS.POST_TREAD_DATA, postId], newTread);
      // queryClient.invalidateQueries([QUERY_KEYS.POST_TREAD_DATA, postId]);
    },
    deleteReply: (data: ISubPost) => {
      const treadData = queryClient.getQueryData<IRepliesInTreadResponse>([QUERY_KEYS.POST_TREAD_DATA, postId]);
      const oldReplies = treadData?.replies;
      const updatedReplise = oldReplies?.map((post) => {
        if (post.postId === data.id) {
          return {
            ...post,
            post: data
          };
        }
        if (post.id === data.id) {
          return {
            ...post,
            ...data
          };
        }

        return post;
      });
      const newTreadData = {
        ...treadData,
        replies: updatedReplise
      };

      queryClient.setQueryData([QUERY_KEYS.POST_TREAD_DATA, postId], newTreadData);
      // queryClient.invalidateQueries([QUERY_KEYS.POST_TREAD_DATA, postId]);
    }
  };
};

export const usePost = () => {
  const queryClient = useQueryClient();
  const { setTread } = useTreadByPostId();
  return useMutation({
    mutationFn: ({ id, searchedPostId }: { id: string; searchedPostId?: string }) => postService.getPost(id, searchedPostId),
    onSuccess: (data) => {
      if (!data) return;
      setTread(data.tread, data.id);
      data.tread.replies.forEach((it) => {
        setPostStatistics(it, queryClient);
      });
      setPostStatistics(data, queryClient);

      if (data.post) {
        setPostStatistics(data.post, queryClient);
      }
    }
  });
};

export const useInsightPodcastPostModal = () => {
  const queryClient = useQueryClient();
  const { setTread } = useTreadByPostId();
  return useMutation({
    mutationFn: ({ id, searchedPostId }: { id: string; searchedPostId?: string }) =>
      postService.getInsightPodcastPostModal(id, searchedPostId),
    onSuccess: (data) => {
      if (!data) return;
      setTread(data.tread, data.id);
      data.tread.replies.forEach((it) => {
        setPostStatistics(it, queryClient);
      });
      setPostStatistics(data, queryClient);

      if (data.post) {
        setPostStatistics(data.post, queryClient);
      }
    }
  });
};

export const useFetchTread = (id: string) => {
  const queryClient = useQueryClient();
  const { setTread } = useTreadByPostId(id);

  return useMutation({
    mutationFn: () => postService.getReplies({ originalPostId: id, take: POST_LIMIT }),
    onSuccess: (data) => {
      if (!data) return;
      const treadData = {
        ...data,
        hasPrev: false,
        countPrevFollowingUserImages: 0,
        prevFollowingUserImages: []
      };
      setTread(treadData);
      treadData.replies.forEach((it) => {
        setPostStatistics(it, queryClient);
      });

      data.replies.forEach((item) => {
        setPostStatistics(item, queryClient);
      });
    }
  });
};

export const useFetchNextRepliesInTreadTread = (id: string) => {
  const queryClient = useQueryClient();
  const { setNextReplies } = useTreadByPostId(id);

  return useMutation({
    mutationFn: (searchedPostId: string) => postService.getNextReplies({ originalPostId: id, searchedPostId, take: POST_LIMIT }),
    onSuccess: (data) => {
      if (!data) return;
      setNextReplies(data);

      data.replies.forEach((item) => {
        setPostStatistics(item, queryClient);
      });
    }
  });
};

export const useFetchPrevRepliesInTreadTread = (id: string) => {
  const queryClient = useQueryClient();
  const { setPrevReplies } = useTreadByPostId(id);

  return useMutation({
    mutationFn: (searchedPostId: string) => postService.getPrevReplies({ originalPostId: id, searchedPostId, take: POST_LIMIT }),
    onSuccess: (data) => {
      if (!data) return;
      setPrevReplies(data);

      data.replies.forEach((item) => {
        setPostStatistics(item, queryClient);
      });
    }
  });
};

export const useAllProfilePosts = (profileId?: string) => {
  const queryClient = useQueryClient();
  const { data: posts } = useQuery([QUERY_KEYS.POST_PROFILE_DATA, profileId], () =>
    queryClient.getQueryData<IPost[]>([QUERY_KEYS.POST_PROFILE_DATA, profileId])
  );
  return {
    allPosts: posts,
    setAllPosts: (data: IPost[], newProfileId?: string) => {
      queryClient.setQueryData([QUERY_KEYS.POST_PROFILE_DATA, profileId ?? newProfileId], data);
      queryClient.invalidateQueries([QUERY_KEYS.POST_PROFILE_DATA, profileId ?? newProfileId]);
    },
    setNewPosts: (data: IPost[], newProfileId: string, isBefore?: boolean) => {
      const oldPosts = queryClient.getQueryData<IPost[]>([QUERY_KEYS.POST_PROFILE_DATA, profileId ?? newProfileId]);
      const filterOldPosts = (oldPosts ?? []).filter(({ id }) => !data.find(({ id: newId }) => id === newId));
      const newPosts = [...(isBefore ? data : []), ...(filterOldPosts ?? []), ...(!isBefore ? data : [])];
      queryClient.setQueryData([QUERY_KEYS.POST_PROFILE_DATA, profileId ?? newProfileId], newPosts);
      queryClient.invalidateQueries([QUERY_KEYS.POST_PROFILE_DATA, profileId ?? newProfileId]);
    },
    setUpdatePosts: (data: IPost, newProfileId: string) => {
      const oldPosts = queryClient.getQueryData<IPost[]>([QUERY_KEYS.POST_PROFILE_DATA, newProfileId]);
      const newPosts = (oldPosts ?? []).map((it) => {
        if (it.id === data.id) {
          return data;
        }
        return it;
      });
      queryClient.setQueryData([QUERY_KEYS.POST_PROFILE_DATA, newProfileId], newPosts);
      queryClient.invalidateQueries([QUERY_KEYS.POST_PROFILE_DATA, newProfileId]);
    },
    setNewQueryAnswer: (data: IPost, newProfileId: string) => {
      const oldPosts = queryClient.getQueryData<IPost[]>([QUERY_KEYS.POST_PROFILE_DATA, newProfileId]);
      const newPosts = updatePostQuestion(oldPosts ?? [], data);
      queryClient.setQueryData([QUERY_KEYS.POST_PROFILE_DATA, newProfileId], newPosts);
      queryClient.invalidateQueries([QUERY_KEYS.POST_PROFILE_DATA, newProfileId]);
    },
    deletePost: (data: IPost, newProfileId: string) => {
      const postsData = queryClient.getQueryData<IPost[]>([QUERY_KEYS.POST_PROFILE_DATA, profileId ?? newProfileId]);
      const updatedPosts = postsData?.map((post) => {
        if (post.postId === data.id) {
          return {
            ...post,
            post: data
          };
        }
        if (post.id === data.id) {
          return {
            ...post,
            ...data
          };
        }

        return post;
      });

      queryClient.setQueryData([QUERY_KEYS.POST_PROFILE_DATA, profileId ?? newProfileId], updatedPosts);
      queryClient.invalidateQueries([QUERY_KEYS.POST_PROFILE_DATA, profileId ?? newProfileId]);
    },
    deleteRepost: (data: IPost, newProfileId: string) => {
      const postsData = queryClient.getQueryData<IPost[]>([QUERY_KEYS.POST_PROFILE_DATA, profileId ?? newProfileId]);

      const updatedPosts = postsData?.filter((postData) => postData.id !== data.id);

      queryClient.setQueryData([QUERY_KEYS.POST_PROFILE_DATA, profileId ?? newProfileId], updatedPosts);
      queryClient.invalidateQueries([QUERY_KEYS.POST_PROFILE_DATA, profileId ?? newProfileId]);
    }
  };
};

export const hookPostData = ({ key, userProfileId }: { userProfileId?: string; key: string }) => {
  const queryClient = useQueryClient();
  return {
    setAllPosts: (data: IPost[]) => {
      queryClient.setQueryData([key, userProfileId], data);
      queryClient.invalidateQueries([key, userProfileId]);
    },
    setNewPosts: (data: IPost[], newUserProfileId: string, isBefore?: boolean) => {
      const oldPosts = queryClient.getQueryData<IPost[]>([key, newUserProfileId]);
      const filterOldPosts = (oldPosts ?? []).filter(({ id }) => !data.find(({ id: newId }) => id === newId));
      const newPosts = [...(isBefore ? data : []), ...(filterOldPosts ?? []), ...(!isBefore ? data : [])];
      queryClient.setQueryData([key, newUserProfileId], newPosts);
      queryClient.invalidateQueries([key, newUserProfileId]);
    },
    setUpdatePosts: (data: IPost, newUserProfileId: string) => {
      const oldPosts = queryClient.getQueryData<IPost[]>([key, newUserProfileId]);
      const newPosts = (oldPosts ?? []).map((it) => {
        if (it.id === data.id) {
          return data;
        }
        return it;
      });
      queryClient.setQueryData([key, newUserProfileId], newPosts);
      queryClient.invalidateQueries([key, newUserProfileId]);
    },
    setNewQueryAnswer: (data: IPost, newUserProfileId: string) => {
      const oldPosts = queryClient.getQueryData<IPost[]>([key, newUserProfileId]);
      const newPosts = updatePostQuestion(oldPosts ?? [], data);
      queryClient.setQueryData([key, newUserProfileId], newPosts);
      queryClient.invalidateQueries([key, newUserProfileId]);
    },
    deletePost: (data: IPost, newProfileId: string) => {
      const postsData = queryClient.getQueryData<IPost[]>([key, userProfileId ?? newProfileId]);
      const updatedPosts = postsData?.map((post) => {
        if (post.postId === data.id) {
          return {
            ...post,
            post: data
          };
        }
        if (post.id === data.id) {
          return {
            ...post,
            ...data
          };
        }

        return post;
      });

      queryClient.setQueryData([key, userProfileId ?? newProfileId], updatedPosts);
      queryClient.invalidateQueries([key, userProfileId ?? newProfileId]);
    },
    deleteRepost: (data: IPost, newProfileId: string) => {
      const postsData = queryClient.getQueryData<IPost[]>([key, userProfileId ?? newProfileId]);
      const updatedPosts = postsData?.filter((postData) => postData.id !== data.id);

      queryClient.setQueryData([key, userProfileId ?? newProfileId], updatedPosts);
      queryClient.invalidateQueries([key, userProfileId ?? newProfileId]);
    }
  };
};

export const useAllHomePosts = (isAll: boolean, userProfileId?: string) => {
  const queryClient = useQueryClient();
  const getValidKey = (isAllOrIsFollowing: boolean) =>
    isAllOrIsFollowing ? QUERY_KEYS.POST_HOME_DATA_ALL : QUERY_KEYS.POST_HOME_DATA_FOLLOWING;
  const { data: posts, isLoading } = useQuery(
    [getValidKey(isAll), userProfileId],
    () => queryClient.getQueryData<IPost[]>([getValidKey(isAll), userProfileId]),
    {
      staleTime: 1000
    }
  );
  return {
    allPosts: posts,
    isLoading,
    ...hookPostData({ key: getValidKey(isAll), userProfileId })
  };
};

export const useAllMemosPosts = (userProfileId?: string) => {
  const queryClient = useQueryClient();
  const getValidKey = () => QUERY_KEYS.GET_MEMO_LIST;
  const { data: posts, isLoading } = useQuery(
    [getValidKey(), userProfileId],
    () => queryClient.getQueryData<IPost[]>([getValidKey(), userProfileId]),
    {
      staleTime: 1000
    }
  );
  return {
    allPosts: posts,
    isLoading,
    ...hookPostData({ key: getValidKey(), userProfileId })
  };
};

export const usePodcastPosts = (userProfileId?: string) => {
  const queryClient = useQueryClient();
  const getValidKey = () => QUERY_KEYS.POST_PODCAST_INSIGHTS_DATA_FOLLOWING;
  const { data: posts, isLoading } = useQuery(
    [getValidKey(), userProfileId],
    () => queryClient.getQueryData<IPost[]>([getValidKey(), userProfileId]),
    {
      staleTime: 1000
    }
  );
  return {
    allPosts: posts,
    isLoading,
    ...hookPostData({ key: getValidKey(), userProfileId })
  };
};

export const useFundLettersPosts = (userProfileId?: string) => {
  const queryClient = useQueryClient();
  const getValidKey = () => QUERY_KEYS.POST_FUND_LETTERS_DATA_FOLLOWING;
  const { data: posts, isLoading } = useQuery(
    [getValidKey(), userProfileId],
    () => queryClient.getQueryData<IPost[]>([getValidKey(), userProfileId]),
    {
      staleTime: 1000
    }
  );
  return {
    allPosts: posts,
    isLoading,
    ...hookPostData({ key: getValidKey(), userProfileId })
  };
};

export const useChannelDataPosts = (userProfileId?: string, id?: string) => {
  const getValidKey = () => QUERY_KEYS.CHANNEL_DATA;
  const { data: posts, isLoading } = useQuery(
    [getValidKey(), userProfileId],
    () => queryClient.getQueryData<IPost[]>([QUERY_KEYS.CHANNEL_DATA, userProfileId]),
    {
      cacheTime: 0,
      staleTime: 0
    }
  );

  return {
    allPosts: posts,
    isLoading,
    setAllPosts: (data: IPost[]) => {
      queryClient.setQueryData([getValidKey(), userProfileId], data);
      queryClient.invalidateQueries([getValidKey(), userProfileId]);
    },
    setNewPosts: (data: IPost[], newUserProfileId: string, isBefore?: boolean) => {
      const oldPosts = queryClient.getQueryData<IPost[]>([getValidKey(), newUserProfileId]);
      const filterOldPosts = (oldPosts ?? []).filter(({ id }) => !data.find(({ id: newId }) => id === newId));
      const newPosts = [...(isBefore ? data : []), ...(filterOldPosts ?? []), ...(!isBefore ? data : [])];
      queryClient.setQueryData([getValidKey(), newUserProfileId], newPosts);
      queryClient.invalidateQueries([getValidKey(), newUserProfileId]);
    },
    setUpdatePosts: (data: IPost, newUserProfileId: string) => {
      const oldPosts = queryClient.getQueryData<IPost[]>([getValidKey(), newUserProfileId]);
      const newPosts = (oldPosts ?? []).map((it) => {
        if (it.id === data.id) {
          return data;
        }
        return it;
      });
      queryClient.setQueryData([getValidKey(), newUserProfileId], newPosts);
      queryClient.invalidateQueries([getValidKey(), newUserProfileId]);
    },
    setNewQueryAnswer: (data: IPost, newUserProfileId: string) => {
      const oldPosts = queryClient.getQueryData<IPost[]>([getValidKey(), newUserProfileId]);
      const newPosts = updatePostQuestion(oldPosts ?? [], data);
      queryClient.setQueryData([getValidKey(), newUserProfileId], newPosts);
      queryClient.invalidateQueries([getValidKey(), newUserProfileId]);
    },
    deletePost: (data: IPost, newProfileId: string) => {
      const postsData = queryClient.getQueryData<IPost[]>([getValidKey(), userProfileId ?? newProfileId]);

      const updatedPosts = postsData?.filter((post) => post.id !== data.id);

      queryClient.setQueryData([getValidKey(), userProfileId ?? newProfileId], updatedPosts);
      queryClient.invalidateQueries([getValidKey(), userProfileId ?? newProfileId]);
    },
    deleteRepost: (data: IPost, newProfileId: string) => {
      const postsData = queryClient.getQueryData<IPost[]>([getValidKey(), userProfileId ?? newProfileId]);
      const updatedPosts = postsData?.filter((postData) => postData.id !== data.id);

      queryClient.setQueryData([getValidKey(), userProfileId ?? newProfileId], updatedPosts);
      queryClient.invalidateQueries([getValidKey(), userProfileId ?? newProfileId]);
    }
  };
};

export const useAllQuotePostsByPostId = (postId?: string) => {
  const queryClient = useQueryClient();
  const { data: posts } = useQuery([QUERY_KEYS.QUOTE_POSTS_BY_POST_ID, postId], () =>
    queryClient.getQueryData<IPost[]>([QUERY_KEYS.QUOTE_POSTS_BY_POST_ID, postId])
  );
  return {
    allPosts: posts,
    setAllPosts: (data: IPost[], newPostId?: string) => {
      queryClient.setQueryData([QUERY_KEYS.QUOTE_POSTS_BY_POST_ID, postId ?? newPostId], data);
    },
    setNewPosts: (data: IPost[], isBefore?: boolean, newPostId?: string) => {
      const oldPosts = queryClient.getQueryData<IPost[]>([QUERY_KEYS.QUOTE_POSTS_BY_POST_ID, postId ?? newPostId]);
      const filterOldPosts = (oldPosts ?? []).filter(({ id }) => !data.find(({ id: newId }) => id === newId));
      const newPosts = [...(isBefore ? data : []), ...(filterOldPosts ?? []), ...(!isBefore ? data : [])];
      queryClient.setQueryData([QUERY_KEYS.QUOTE_POSTS_BY_POST_ID, postId ?? newPostId], newPosts);
    }
  };
};

export const useGetQuotePostsByPostId = (postId: string) => {
  const queryClient = useQueryClient();
  const { setAllPosts, setNewPosts } = useAllQuotePostsByPostId(postId);
  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
    data: dataResult
  } = useInfiniteQuery(
    [QUERY_KEYS.QUOTE_POSTS_BY_POST_ID, postId, POST_LIMIT],
    ({ pageParam = 0 }) => postService.getQuotePostsByPostId(postId, pageParam, POST_LIMIT),
    {
      getNextPageParam(lastPage, allPages) {
        if (allPages.length * POST_LIMIT >= (lastPage?.count ?? 0)) return;
        return allPages.length;
      },
      onSuccess: (data) => {
        if (!data) return;
        const pageNumber = data.pages.length;
        const lastPage = data.pages[pageNumber - 1];
        if (!lastPage) return;
        const { posts: postsData } = lastPage;
        if (pageNumber === 1) {
          setAllPosts(postsData);
        } else {
          setNewPosts(postsData);
        }

        postsData.forEach((post) => {
          setPostStatistics(post, queryClient);
          if (post.post) {
            setPostStatistics(post.post, queryClient);
          }
        });
      }
    }
  );

  return {
    hasNextPageList: hasNextPage,
    isFetchingNextPageList: isFetchingNextPage,
    isListLoading: isLoading,
    fetchNextPageList: fetchNextPage,
    refetchList: refetch,
    count: dataResult?.pages[dataResult.pageParams.length ? 0 : dataResult.pageParams.length - 1]?.count ?? 0
  };
};

export const useCreatePost = (id?: string) => {
  const queryClient = useQueryClient();
  const { setNewPosts } = useAllProfilePosts(id);
  const { setNewPosts: setNewPostsOnHomeFollowing } = useAllHomePosts(false, id);
  const { setNewPosts: setNewPostsOnHome } = useAllHomePosts(true, id);
  const { setTread } = useTreadByPostId();

  return useMutation({
    mutationFn: (data: IPostCreatePayload) => postService.createPost(data),
    onSuccess: (data) => {
      if (!data) return;

      setTread(data.tread, data.id);
      data.tread.replies.forEach((it) => {
        setPostStatistics(it, queryClient);
      });
      setPostStatistics(data, queryClient);
      setNewPosts([data], data.profileId, true);
      setNewPostsOnHome([data], data.profileId, true);
      setNewPostsOnHomeFollowing([data], data.profileId, true);
    }
  });
};

export const useCreateChannelPost = (id?: string, channelId?: string) => {
  const queryClient = useQueryClient();
  const { setNewPosts } = useAllProfilePosts(id);
  const { setNewPosts: setNewPostsOnHomeFollowing } = useChannelDataPosts(id, channelId);
  const { setTread } = useTreadByPostId();

  return useMutation({
    mutationFn: (data: IPostCreatePayload) => postService.createPost(data),
    onSuccess: (data) => {
      if (!data) return;

      setTread(data.tread, data.id);
      data.tread.replies.forEach((it) => {
        setPostStatistics(it, queryClient);
      });
      setPostStatistics(data, queryClient);
      setNewPosts([data], data.profileId, true);
      setNewPostsOnHomeFollowing([data], data.profileId, true);
    }
  });
};

export const useCreateRepost = (id?: string) => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useUpdatePostStatistic();
  const { setNewPosts, deleteRepost } = useAllProfilePosts(id);
  const { setNewPosts: setNewPostsOnHomeFollowing, deleteRepost: deleteRepostOnHomeFollowing } = useAllHomePosts(false, id);
  const { setNewPosts: setNewPostsOnHome, deleteRepost: deleteRepostOnHome } = useAllHomePosts(true, id);
  // const { setNewPosts: setNewQuotePostsInModal } = useAllQuotePostsByPostId();
  const user = useUser();
  return useMutation({
    mutationKey: [QUERY_KEYS.POST],
    mutationFn: (data: IRepostPayload) => postService.createRepost(data),
    onSuccess: async (data) => {
      if (!data) return;
      if (data?.repost?.postId || data?.post?.id) {
        mutateAsync(data.repost?.postId || data?.post?.id);
      }

      await setPostStatistics(data.repost || data?.post, queryClient);

      // const statistics = queryClient.getQueryData<IStatistic[]>([QUERY_KEYS.STATISTIC]) ?? [];

      if (data.repost?.deletedAt) {
        deleteRepost(data.repost, data.repost.profileId);
        deleteRepostOnHome(data.repost, data.repost.profileId);
        deleteRepostOnHomeFollowing(data.repost, data.repost.profileId);
      } else if (data.repost) {
        setNewPosts([data.repost], data.repost?.profileId, true);
        setNewPostsOnHome([data.repost], data.repost?.profileId, true);
        setNewPostsOnHomeFollowing([data.repost], data.repost?.profileId, true);
      }
      // setNewQuotePostsInModal([data], true);

      // const updatedPost = statistics.find((p) => p.id === data.postId);
      // if (!updatedPost) return;

      updateAdorationPost(data.post, queryClient, user?.profile?.id);
    }
  });
};

export const useAllRepostsOnPost = (postId: string, enabled: boolean) => {
  const queryClient = useQueryClient();
  const { data: posts } = useQuery(
    [QUERY_KEYS.POST_REPOSTS, postId],
    () => queryClient.getQueryData<IPostStatResponseItem['data']>([QUERY_KEYS.POST_REPOSTS, postId]),
    { enabled }
  );
  return {
    allData: posts,
    setAllData: (data: IPostStatResponseItem['data'], newPostId?: string) => {
      queryClient.setQueryData([QUERY_KEYS.POST_REPOSTS, postId ?? newPostId], data);
      queryClient.invalidateQueries([QUERY_KEYS.POST_REPOSTS, postId ?? newPostId]);
    },
    setNewData: (data: IPostStatResponseItem['data'], newPostId: string, isBefore?: boolean) => {
      const old = queryClient.getQueryData<IPostStatResponseItem['data']>([QUERY_KEYS.POST_REPOSTS, postId ?? newPostId]);
      const filtered = (old ?? []).filter(({ id }) => !data.find(({ id: newId }) => id === newId));
      const newData = [...(isBefore ? data : []), ...(filtered ?? []), ...(!isBefore ? data : [])];
      queryClient.setQueryData([QUERY_KEYS.POST_REPOSTS, postId ?? newPostId], newData);
      queryClient.invalidateQueries([QUERY_KEYS.POST_REPOSTS, postId ?? newPostId]);
    },
    setUpdateData: (data: IPostStatResponseItem['data'][0], newId: string) => {
      const oldData = queryClient.getQueryData<IPostStatResponseItem['data']>([QUERY_KEYS.POST_REPOSTS, newId]);
      const newData = (oldData ?? []).map((it) => {
        if (it.id === data.id) {
          return data;
        }
        return it;
      });
      queryClient.setQueryData([QUERY_KEYS.POST_REPOSTS, newId], newData);
      queryClient.invalidateQueries([QUERY_KEYS.POST_REPOSTS, newId]);
    }
  };
};

export const useRepostsByPostId = (id: string, take: number) => {
  const { setAllData, setNewData } = useAllRepostsOnPost(id, false);
  const {
    data: pages,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch
  } = useInfiniteQuery([QUERY_KEYS.POST_REPOSTS, id, take], ({ pageParam = 0 }) => postService.getPostReposts(id, take, pageParam + 1), {
    enabled: false,
    getNextPageParam(lastPage, allPages) {
      if (allPages.length * Number(take) >= (Number(lastPage?.count) ?? 0)) return;
      return allPages.length;
    },
    onSuccess: (data) => {
      if (!data) return;
      const pageNumber = data.pages.length;
      const lastPage = data.pages[pageNumber - 1];
      if (!lastPage) return;
      const { data: repostsData } = lastPage;
      if (pageNumber === 1) {
        setAllData(repostsData, id);
        return;
      }
      setNewData(repostsData, id);
    }
  });

  return {
    hasNextPageList: hasNextPage,
    isFetchingNextPageList: isFetchingNextPage,
    isListLoading: isLoading,
    fetchNextPageList: fetchNextPage,
    refetchList: refetch,
    count: Number(pages?.pages?.at(0)?.count) || 0,
    metaData: pages?.pages[pages?.pages && pages.pages.length > 0 ? pages.pages.length - 1 : 0]?.metaData
  };
};
export const usePostQuotePosts = (postId: string, enabled: boolean) =>
  useQuery([QUERY_KEYS.POST_QUOTE_POSTS, postId], () => postService.getPostQuotePosts(postId), { enabled });

export const useAllAdoratedPosts = (id: string) => {
  const queryClient = useQueryClient();
  const { data } = useQuery([QUERY_KEYS.ADORATIONS, id], () => queryClient.getQueryData<IPost[]>([QUERY_KEYS.ADORATIONS, id]));

  const setPosts = (posts: IPost[]) => {
    posts.forEach((item) => {
      setPostStatistics(item, queryClient);

      if (item.post) {
        setPostStatistics(item.post, queryClient);
      }
    });

    queryClient.setQueryData([QUERY_KEYS.ADORATIONS, id], posts);
  };

  const updateQuestionInAdorations = (newPost: IPost) => {
    queryClient.setQueryData([QUERY_KEYS.ADORATIONS, id], updatePostQuestion(data ?? [], newPost));
  };

  const deletePostInAdorations = (postId: string) => {
    queryClient.setQueryData([QUERY_KEYS.ADORATIONS, id], data?.filter((e) => e.id !== postId));
  };

  return { data, setPosts, updateQuestionInAdorations, deletePostInAdorations };
};

export const useDeletePost = ({ postId, profileId, close }: { postId?: string; profileId?: string; close?: () => void }) => {
  const queryClient = useQueryClient();
  const { pathname } = useRouter();
  const { deleteReply } = useTreadByPostId(postId);
  const { deletePost } = useAllProfilePosts(profileId);
  const { deletePost: deletePostOnHomeFollowing } = useAllHomePosts(false, profileId);
  const { deletePostInAdorations } = useAllAdoratedPosts(profileId!);
  const { deleteBookmark } = useAllUserBookmarks(profileId);
  const { mutateAsync: updatePostStatistic } = useUpdatePostStatistic();
  return useMutation({
    mutationFn: (id: string) => postService.deletePost(id),
    onSuccess: (data) => {
      if (!data) return;
      deletePost(data, data.profileId);
      deletePostOnHomeFollowing(data, data.profileId);
      deletePostInAdorations(data.id);
      deleteBookmark({ ...data, type: EBookmarkType.POST });
      if (profileId) queryClient.invalidateQueries([QUERY_KEYS.PROFILE, QUERY_KEYS.IDEAS, profileId]);
      if (profileId) {
        queryClient.invalidateQueries([QUERY_KEYS.QUESTION_QUERIES, 'sentimentProfile', profileId]);
      }
      if (postId) {
        updatePostStatistic(postId);
        deleteReply(data);
      }
      if (data.originalPostId) {
        updatePostStatistic(data.originalPostId);
        deleteReply(data);
      }
      if (pathname === ROUTER_KEYS.POLLS) {
        queryClient.invalidateQueries([QUERY_KEYS.POLL_SENTIMENT_SWINGS]);
      }
      if (close) close();
    }
  });
};

export const useDeletePostChannels = ({ postId, profileId, close }: { postId?: string; profileId?: string; close?: () => void }) => {
  const queryClient = useQueryClient();
  const { pathname } = useRouter();
  const { deleteReply } = useTreadByPostId(postId);
  const { deletePost } = useAllProfilePosts(profileId);
  const { deletePost: deletePostOnHomeFollowing } = useChannelDataPosts(profileId);
  const { deletePostInAdorations } = useAllAdoratedPosts(profileId!);
  const { deleteBookmark } = useAllUserBookmarks(profileId);
  const { mutateAsync: updatePostStatistic } = useUpdatePostStatistic();
  return useMutation({
    mutationFn: (id: string) => postService.deletePost(id),
    onSuccess: (data) => {
      if (!data) return;
      deletePost(data, data.profileId);
      deletePostOnHomeFollowing(data, data.profileId);
      deletePostInAdorations(data.id);
      deleteBookmark({ ...data, type: EBookmarkType.POST });
      if (profileId) queryClient.invalidateQueries([QUERY_KEYS.PROFILE, QUERY_KEYS.IDEAS, profileId]);
      if (profileId) {
        queryClient.invalidateQueries([QUERY_KEYS.QUESTION_QUERIES, 'sentimentProfile', profileId]);
      }
      if (postId) {
        updatePostStatistic(postId);
        deleteReply(data);
      }
      if (data.originalPostId) {
        updatePostStatistic(data.originalPostId);
        deleteReply(data);
      }
      if (pathname === ROUTER_KEYS.POLLS) {
        queryClient.invalidateQueries([QUERY_KEYS.POLL_SENTIMENT_SWINGS]);
      }
      if (close) close();
    }
  });
};

export function useHomeFilters(currentFilter: IFilters) {
  return useQuery([QUERY_KEYS.NEWSFEEDS_FILTERS, currentFilter], () => postService.getHomeFilters(currentFilter), {});
}

export const useFetchHomePosts = (isAll: boolean, currentFilter?: IFilters, userProfileId?: string) => {
  const queryClient = useQueryClient();
  const { setAllPosts, setNewPosts } = useAllHomePosts(isAll, userProfileId);
  const { setTread } = useTreadByPostId();

  const setPosts = (postsData: IPost[], pageNumber: number) => {
    if (pageNumber === 1) {
      setAllPosts(postsData);
      return;
    }
    if (userProfileId) {
      setNewPosts(postsData, userProfileId);
    }
  };
  const { fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, refetch, isRefetching } = useInfiniteQuery(
    [isAll ? QUERY_KEYS.POST_HOME_DATA_ALL : QUERY_KEYS.POST_HOME_DATA_FOLLOWING, currentFilter, POST_LIMIT, userProfileId],
    ({ pageParam = 0, signal }) =>
      isAll
        ? postService.getAllPosts({ setTread, queryClient, setPosts }, currentFilter, pageParam + 1, POST_LIMIT)
        : postService.getFollowingPosts({ setTread, queryClient, setPosts }, currentFilter, pageParam + 1, POST_LIMIT, signal),
    {
      getNextPageParam(lastPage, allPages) {
        if (allPages.length * POST_LIMIT >= (lastPage?.count ?? 0)) return;
        return allPages.length;
      }
    }
  );

  return {
    hasNextPageList: hasNextPage,
    isFetchingNextPageList: isFetchingNextPage,
    isListLoading: isLoading,
    isRefetchingList: isRefetching,
    fetchNextPageList: fetchNextPage,
    refetchList: async () => {
      await queryClient.setQueryData(
        [isAll ? QUERY_KEYS.POST_HOME_DATA_ALL : QUERY_KEYS.POST_HOME_DATA_FOLLOWING, POST_LIMIT, userProfileId],
        () => ({
          pageParams: []
        })
      );
      await refetch();
    }
  };
};

export const useFetchFundLettersPosts = (isAll: boolean, userProfileId?: string) => {
  const queryClient = useQueryClient();
  const { setAllPosts, setNewPosts } = useFundLettersPosts(userProfileId);
  const { setTread } = useTreadByPostId();

  const setPosts = (postsData: IPost[], pageNumber: number) => {
    if (pageNumber === 1) {
      setAllPosts(postsData);
      return;
    }
    if (userProfileId) {
      setNewPosts(postsData, userProfileId);
    }
  };
  const { fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, refetch, isRefetching } = useInfiniteQuery(
    [QUERY_KEYS.POST_FUND_LETTERS_DATA_FOLLOWING, POST_LIMIT, userProfileId],
    ({ pageParam = 0, signal }) => postService.getFundLettersPosts({ setTread, queryClient, setPosts }, pageParam + 1, POST_LIMIT, signal),
    {
      getNextPageParam(lastPage, allPages) {
        if (allPages.length * POST_LIMIT >= (lastPage?.count ?? 0)) return;
        return allPages.length;
      }
    }
  );

  return {
    hasNextPageList: hasNextPage,
    isFetchingNextPageList: isFetchingNextPage,
    isListLoading: isLoading,
    isRefetchingList: isRefetching,
    fetchNextPageList: fetchNextPage,
    refetchList: async () => {
      await queryClient.setQueryData([QUERY_KEYS.POST_FUND_LETTERS_DATA_FOLLOWING, POST_LIMIT, userProfileId], () => ({
        pageParams: []
      }));
      await refetch();
    }
  };
};

export const useFetchIdeasPosts = (currentFilter?: any, userProfileId?: string) => {
  const queryClient = useQueryClient();
  const { setAllPosts, setNewPosts } = useAllMemosPosts(userProfileId);
  const { setTread } = useTreadByPostId();

  const setPosts = (postsData: IPost[], pageNumber: number) => {
    if (pageNumber === 1) {
      setAllPosts(postsData);
      return;
    }
    if (userProfileId) {
      setNewPosts(postsData, userProfileId);
    }
  };
  const { fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, refetch, isRefetching } = useInfiniteQuery(
    [QUERY_KEYS.GET_MEMO_LIST, LIMIT, currentFilter, userProfileId],
    ({ pageParam = 0, signal }) =>
      memoService.getMemoList({
        postParams: { setTread, queryClient, setPosts },
        page: pageParam + 1,
        limit: LIMIT,
        category: currentFilter.category,
        direction: currentFilter.direction,
        sectorId: currentFilter.sectorId,
        regionId: currentFilter.regionId,
        marketCap: currentFilter.marketCap,
        adtvCap: currentFilter.adtvCap,
        signal
      }),
    {
      getNextPageParam(lastPage, allPages) {
        if (allPages.length * LIMIT >= (lastPage?.count ?? 0)) return;
        return allPages.length;
      }
    }
  );

  return {
    hasNextPageList: hasNextPage,
    isFetchingNextPageList: isFetchingNextPage,
    isListLoading: isLoading,
    isRefetchingList: isRefetching,
    fetchNextPageList: fetchNextPage,
    refetchList: async () => {
      await queryClient.setQueryData([QUERY_KEYS.GET_MEMO_LIST, LIMIT, userProfileId], () => ({
        pageParams: []
      }));
      await refetch();
    }
  };
};

export const useFetchProfilePosts = (id: string) => {
  const queryClient = useQueryClient();
  const { setAllPosts, setNewPosts } = useAllProfilePosts(id);
  const { setTread } = useTreadByPostId();
  const setPosts = (postsData: IPost[], pageNumber: number) => {
    if (pageNumber === 1) {
      setAllPosts(postsData);
      return;
    }
    setNewPosts(postsData, id);
  };
  const {
    data: posts,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isRefetching,
    isLoading,
    refetch
  } = useInfiniteQuery(
    [QUERY_KEYS.POST_PROFILE_DATA, POST_LIMIT, id],
    ({ pageParam = 0 }) => postService.getPosts(id, pageParam + 1, POST_LIMIT, { setTread, queryClient, setPosts }),
    {
      getNextPageParam(lastPage, allPages) {
        if (allPages.length * POST_LIMIT >= (lastPage?.count ?? 0)) return;
        return allPages.length;
      }
    }
  );

  return {
    hasNextPageList: hasNextPage,
    isFetchingNextPageList: isFetchingNextPage,
    isListLoading: isLoading || isRefetching,
    fetchNextPageList: fetchNextPage,
    refetchList: async () => {
      await queryClient.setQueryData([QUERY_KEYS.POST_PROFILE_DATA, POST_LIMIT, id], () => ({
        pageParams: []
      }));
      await refetch();
    },
    isPostsHidden: !!posts?.pages?.at(0)?.isFeedHidden
  };
};

export const useFetchPodcastInsightsPosts = (isAll: boolean, userProfileId?: string) => {
  const queryClient = useQueryClient();
  const { setAllPosts, setNewPosts } = usePodcastPosts(userProfileId);
  const { setTread } = useTreadByPostId();

  const setPosts = (postsData: IPost[], pageNumber: number) => {
    if (pageNumber === 1) {
      setAllPosts(postsData);
      return;
    }
    if (userProfileId) {
      setNewPosts(postsData, userProfileId);
    }
  };
  const { fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, refetch, isRefetching } = useInfiniteQuery(
    [QUERY_KEYS.POST_PODCAST_INSIGHTS_DATA_FOLLOWING, POST_LIMIT, userProfileId],
    ({ pageParam = 0, signal }) =>
      postService.getPodcastInsightsPosts({ setTread, queryClient, setPosts }, pageParam + 1, POST_LIMIT, signal),
    {
      getNextPageParam(lastPage, allPages) {
        if (allPages.length * POST_LIMIT >= (lastPage?.count ?? 0)) return;
        return allPages.length;
      }
    }
  );

  return {
    hasNextPageList: hasNextPage,
    isFetchingNextPageList: isFetchingNextPage,
    isListLoading: isLoading,
    isRefetchingList: isRefetching,
    fetchNextPageList: fetchNextPage,
    refetchList: async () => {
      await queryClient.setQueryData([QUERY_KEYS.POST_PODCAST_INSIGHTS_DATA_FOLLOWING, POST_LIMIT, userProfileId], () => ({
        pageParams: []
      }));
      await refetch();
    }
  };
};

export const useUpdatePost = (userProfileId: string) => {
  const { setUpdatePosts: UpdateOnHomeFollowing } = useAllHomePosts(false);
  const { setUpdatePosts: UpdateOnHome } = useAllHomePosts(true);
  const { setUpdatePosts: UpdateOnProfile } = useAllProfilePosts();
  const { asPath, query } = useRouter();
  const { id: profileId } = query;
  return useMutation({
    mutationKey: [QUERY_KEYS.POST],
    mutationFn: (id: string) => postService.getPost(id),
    onSuccess: (data) => {
      if (!data) return;
      UpdateOnHome(data, userProfileId);
      UpdateOnHomeFollowing(data, userProfileId);
      if (asPath.startsWith('/profile')) {
        UpdateOnProfile(data, profileId?.toString() ?? userProfileId);
      }
    }
  });
};

export const useAdoratedPosts = (profileId: string) => {
  const queryClient = useQueryClient();
  const [allPosts, setAllPosts] = useState<IPost[]>([]);
  const { data: posts, setPosts } = useAllAdoratedPosts(profileId);
  const propsObj = useInfiniteQuery({
    queryKey: [QUERY_KEYS.ADORATIONS, 'inf-query', profileId],
    queryFn: ({ pageParam = 0 }) => postService.getAdoratedPosts(pageParam + 1, POST_LIMIT),
    getNextPageParam: (lastPage, allPages) => {
      if (allPages.length * POST_LIMIT >= (lastPage?.count ?? 0)) return;
      return allPages.length;
    },
    onSuccess: (data) => {
      if (!data) return;

      const pageNumber = data.pages.length;
      const lastPage = data.pages[pageNumber - 1];
      if (!lastPage) return;
      const { posts: postsData } = lastPage;
      if (pageNumber === 1) {
        setAllPosts(postsData);
        setPosts(postsData);
      } else {
        setAllPosts((prev) => [...prev, ...postsData]);
        setPosts([...(posts ?? []), ...postsData]);
      }
    }
  });

  const onAdorationDeleted = (postId: string) => setAllPosts((prev) => prev.filter((post) => post.id !== postId));

  const resetAdorations = () => queryClient.removeQueries([QUERY_KEYS.ADORATIONS]);
  return { ...propsObj, posts: allPosts, onAdorationDeleted, resetAdorations };
};

export const useTrendingMusings = () => {
  const queryClient = useQueryClient();
  const { setTread } = useTreadByPostId();

  return useQuery({
    queryKey: [QUERY_KEYS.TRENDING_MUSINGS],
    queryFn: () => postService.getTrendingMusings({ setTread, queryClient })
  });
};

export const useUpdateTreadingQuestion = () => {
  const queryClient = useQueryClient();
  return {
    updateQuestions: (data: IPost) => {
      const oldPosts = queryClient.getQueryData<IPost[]>([QUERY_KEYS.TRENDING_MUSINGS]) ?? [];
      const newPosts = updatePostQuestion(oldPosts, data);
      queryClient.setQueryData([QUERY_KEYS.TRENDING_MUSINGS], newPosts);
      queryClient.invalidateQueries([QUERY_KEYS.TRENDING_MUSINGS]);
    }
  };
};

export const useCreateReplie = (onSuccess: (isFirst: boolean) => void) => {
  const { mutateAsync: updatePostStatistic } = useUpdatePostStatistic();
  const queryClient = useQueryClient();
  const { setCreatedReply } = useTreadByPostId();
  const { setNewPosts: setNewPostsAll } = useAllHomePosts(true);
  const { setNewPosts: setNewPostsFollowing } = useAllHomePosts(false);

  return useMutation({
    mutationKey: [QUERY_KEYS.POST_REPLY, 'reply'],
    mutationFn: (data: IRepliePayload) => postService.createReplie(data),
    onSuccess: (data) => {
      if (!data) return;
      setCreatedReply(data.reply, data.reply.originalPostId);
      if (data.post) {
        setPostStatistics(data.post, queryClient);
        onSuccess(data.post.replyCount === 1);
        if (data.post.replyCount === 1) {
          setNewPostsAll([data.post], data.reply.profileId, true);
          setNewPostsFollowing([data.post], data.reply.profileId, true);
        }
      }
      if (data.reply.originalPostId && !data.post) {
        updatePostStatistic(data.reply.originalPostId);
      }
      if (data.reply.postId) {
        updatePostStatistic(data.reply.postId);
      }
      queryClient.refetchQueries([QUERY_KEYS.MEMOS_POST_BY_ID]);
    }
  });
};
