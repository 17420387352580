import React, { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';
import ArrowBottom from '@styles/icons/conversation-blue-bottom-arrow.webp';
import { useRouter } from 'next/router';
import { scrollIntoView, polyfill } from 'seamless-scroll-polyfill';
import * as Styled from './conversation-tread.styled';
import { PostComponent } from '@/common/components/post';
import { useDeletePost, useFetchNextRepliesInTreadTread, useFetchPrevRepliesInTreadTread, useTreadByPostId } from '@/common/hooks';
import { IPostWithPostOrOriginalPost, ISubPost, PostType } from '@/common/types/post.type';
import { ShowMorePrev } from './show-more-prev.component';
import { Loader } from '@/common/components/loader';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';

interface IProps {
  openReply: (postItem?: IPostWithPostOrOriginalPost, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isClickOnPost: boolean;
  isTreanding?: boolean;
  isMemoPost?: boolean;
  postId: string;
  profileId?: string;
  isIdeaModal?: boolean;
  userProfileId?: string | undefined;
  isInModal?: boolean;
  isPublish?: boolean;
  replies?: IPostWithPostOrOriginalPost[];
  quotePostHandler: (postId: string) => void;
  closeModal?: () => void;
  memosSection?: any;
  dividerWidth?: number;
  isDefaultStats?: boolean;
  isInsightNewPodcast?: boolean;
  disableQuoteAndRepost?: boolean;
  parentPost?: ISubPost;
}

export const ConversationTreadComponent = ({
  openReply,
  isClickOnPost,
  isTreanding,
  profileId,
  postId,
  userProfileId,
  isInModal,
  replies,
  isMemoPost,
  isIdeaModal,
  isPublish,
  memosSection,
  dividerWidth,
  isDefaultStats,
  disableQuoteAndRepost,
  isInsightNewPodcast,
  parentPost,
  quotePostHandler,
  closeModal
}: IProps) => {
  const router = useRouter();
  const isHome = router.pathname.includes(ROUTER_KEYS.HOME);
  const isProfile = router.pathname.includes(ROUTER_KEYS.PROFILE);
  const isMemoPostHomeOrProfile = memosSection && (isProfile || isHome);
  const isIdeaPageConversation = router.pathname.includes(ROUTER_KEYS.MEMO_PUBLISH);

  const { mutateAsync: deletePost } = useDeletePost({ postId, profileId });
  const { hasPrev, hasNext, countNextReplies, prevFollowingUserImages, countPrevFollowingUserImages } = useTreadByPostId(postId);
  const { mutateAsync: loadPrevReplies, isLoading: isPrevLoading, isSuccess } = useFetchPrevRepliesInTreadTread(postId);
  const { mutateAsync: loadNextReplies, isLoading: isNextLoading, isSuccess: isSuccessNext } = useFetchNextRepliesInTreadTread(postId);

  const onClickToLoadPrevReplies = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    const id = replies?.filter((it) => !it.isSearched && !it.isNew)?.[0]?.id;
    if (!id) return;
    loadPrevReplies(id);
  };

  const onClickToLoadNextReplies = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    const id = replies?.filter((it) => !it.isSearched && !it.isNew)?.at(-1)?.id;
    if (!id) return;
    loadNextReplies(id);
  };

  const stopPropagation = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e?.preventDefault();
    e?.stopPropagation();
  };

  const handleClickScroll = async (anchor: string): Promise<void> => {
    const element = document.getElementById(anchor);
    if (element) {
      scrollIntoView(element, { behavior: 'smooth' });
    }
  };

  useEffect(() => {
    polyfill();
  }, []);

  const lastReplyWithUndefinedFullname = replies && [...replies]?.reverse().find((reply) => !reply.profileId);

  return (
    <AnimatePresence>
      {isClickOnPost && replies?.length && (
        <motion.div
          transition={{ ease: 'backInOut', delay: isIdeaModal ? 0 : 0.2, duration: 0.5 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={undefined}
        >
          <Styled.ConversationWrapper isIdeaPageConversation={isIdeaPageConversation} isInModal={isInModal}>
            {hasPrev && !isPublish && !isIdeaModal && (
              <Styled.ReplyDivider
                hasPrev={hasPrev}
                isInModal={isInModal}
                isMemoPostHomeOrProfile={isMemoPostHomeOrProfile}
                dividerWidth={dividerWidth}
                isPublish={isPublish}
                isFirst
                isMemoPost={!!memosSection || isMemoPost}
                isIdeaModal={isIdeaModal}
              />
            )}

            {hasPrev && isPublish && !isIdeaModal && (
              <Styled.ReplyDivider
                isInModal={isInModal}
                isIdeaPageConversation={isIdeaPageConversation}
                isMemoPostHomeOrProfile={isMemoPostHomeOrProfile}
                dividerWidth={dividerWidth}
                isPublish={isPublish}
                isFirst
                isMemoPost={!!memosSection || isMemoPost}
                isIdeaModal={isIdeaModal}
              />
            )}
            {!hasPrev && isPublish && !isIdeaModal && (
              <Styled.ReplyDivider
                isIdeaPageConversation={isIdeaPageConversation}
                isInModal={isInModal}
                dividerWidth={dividerWidth}
                isPublish={isPublish}
                isFirst
                isMemoPost={!!memosSection || isMemoPost}
                isIdeaModal={isIdeaModal}
              />
            )}
            <div style={{ marginLeft: isPublish && !isIdeaModal ? '40px' : undefined }}>
              {hasPrev && !isPrevLoading && (
                <ShowMorePrev
                  isInModal={isInModal}
                  isIdeaPageConversation={isIdeaPageConversation}
                  noPadding={isPublish && !isHome}
                  isIdeaModal={isIdeaModal}
                  userImages={prevFollowingUserImages ?? []}
                  countUserImages={countPrevFollowingUserImages ?? 0}
                  onClick={onClickToLoadPrevReplies}
                />
              )}
              {hasPrev && isPrevLoading && (
                <Styled.LoaderWrapper>
                  <Loader height="22" width="22" />
                </Styled.LoaderWrapper>
              )}
              {replies.map((it, index) => {
                if (it.isNew) return <div key={`empty${isTreanding && '-treanding'}-post-${it.id}`} />;
                const isFirstReply = index === 0;
                const isFourth = index === 3;
                const isFifth = index === 4;
                const isLastSummaryReply = lastReplyWithUndefinedFullname?.id === it?.id;
                const isMoreThen4 = replies.length > 4;

                return (
                  <div key={`wrapper-post${isTreanding && '-treanding'}-divider-${it.id}`} style={{ position: 'relative' }}>
                    <Styled.ReplyDivider
                      isIdeaPageConversationSmall={isIdeaPageConversation}
                      dividerAfterMore={(!isSuccess || hasPrev) && isFirstReply && hasPrev}
                      isInsightNewPodcast={isInsightNewPodcast && isFifth}
                      // isPublish={isPublish}
                      isInModal={isInModal}
                      dividerWidth={dividerWidth}
                      isFirstMemoReply={(!!memosSection || isMemoPost) && isFirstReply && isPublish && !hasPrev}
                      isIdeaModal={isIdeaModal}
                      isMemoPost={!!memosSection || isMemoPost}
                      isMemoPostHomeOrProfile={isMemoPostHomeOrProfile}
                      key={`post${isTreanding && '-treanding'}-divider-${it.id}`}
                      isFirst={!hasPrev && index === 0}
                    />
                    {isLastSummaryReply && <Styled.Anchor id={`${lastReplyWithUndefinedFullname?.id}`} />}
                    <PostComponent
                      disableQuoteAndRepost={disableQuoteAndRepost}
                      parentPost={parentPost}
                      userProfileId={userProfileId}
                      isDefaultStats={isDefaultStats}
                      postStatus={{
                        isInOwnTread: false,
                        isOwnReply: true,
                        isLast: it.replyCount === 0,
                        isInModal,
                        isTreadOpen: isClickOnPost,
                        isConversation: true
                      }}
                      isIdeaModal={isIdeaModal}
                      closeModal={closeModal}
                      post={it}
                      onClickByPost={stopPropagation}
                      repliedPost={it.post}
                      key={`conversation${isTreanding && '-treanding'}-post-${it.id}`}
                      deletePost={deletePost}
                      openReply={(e) => openReply(it, e)}
                      quotePostHandler={quotePostHandler}
                      postType={PostType.Reply}
                    />
                    {isFourth && isMoreThen4 && isInsightNewPodcast && (
                      <>
                        <Styled.ReplyDivider margin="14px 33px 6px 27px" />
                        <Styled.SkipSummaryWrapper gap={19} onClick={() => handleClickScroll(`${lastReplyWithUndefinedFullname?.id}`)}>
                          <Image src={ArrowBottom} alt="Show More" width={12} height={12} style={{ margin: '0 0 0 9px' }} />
                          <div>
                            <p>Skip to end of summary</p>
                          </div>
                        </Styled.SkipSummaryWrapper>
                        <Styled.ReplyDivider margin="6px 33px 14px 27px" />
                      </>
                    )}
                  </div>
                );
              })}
              {hasNext && <Styled.ReplyDivider hasNext={hasNext} isInModal={isInModal} />}
              {hasNext && !isNextLoading && (
                <Styled.ShowMoreWrapper isInModal={isInModal} gap={19} hasNext={hasNext} onClick={onClickToLoadNextReplies}>
                  <Image src={ArrowBottom} alt="Show More" width={12} height={12} style={{ margin: '0px 0px 0px 9px' }} />
                  <div>
                    <Styled.ShowMoreParagraph>
                      Show {countNextReplies} more {(countNextReplies ?? 0) > 1 ? 'replies' : 'reply'}
                    </Styled.ShowMoreParagraph>
                  </div>
                </Styled.ShowMoreWrapper>
              )}
              {replies.map((it, index) => {
                if (!it.isNew) return <div key={`empty-new${isTreanding && '-treanding'}-post-${it.id}`} />;
                const isFirstReply = index === 0;
                return (
                  <div key={`wrapper-new-post${isTreanding && '-treanding'}-divider-${it.id}`}>
                    <Styled.ReplyDivider
                      dividerAfterMore={(!isSuccessNext || hasNext) && isFirstReply && hasNext}
                      isInModal={isInModal}
                      dividerWidth={dividerWidth}
                      isMemoPost={!!memosSection || isMemoPost}
                      // isPublish={isPublish}
                      isIdeaModal={isIdeaModal}
                      key={`new-post${isTreanding && '-treanding'}-divider-${it.id}`}
                    />
                    <PostComponent
                      disableQuoteAndRepost={disableQuoteAndRepost}
                      parentPost={parentPost}
                      isDefaultStats={isDefaultStats}
                      onClickByPost={stopPropagation}
                      userProfileId={userProfileId}
                      postStatus={{
                        isInOwnTread: false,
                        isOwnReply: true,
                        isLast: it.replyCount === 0,
                        isInModal,
                        isTreadOpen: isClickOnPost,
                        isConversation: true
                      }}
                      post={it}
                      isIdeaModal={isIdeaModal}
                      repliedPost={it.post}
                      key={`new-conversation${isTreanding && '-treanding'}-post-${it.id}`}
                      deletePost={deletePost}
                      openReply={(e) => openReply(it, e)}
                      quotePostHandler={quotePostHandler}
                      postType={PostType.Reply}
                    />
                  </div>
                );
              })}
              {hasNext && isNextLoading && (
                <Styled.LoaderWrapper hasNext={hasNext}>
                  <Loader height="22" width="22" />
                </Styled.LoaderWrapper>
              )}
            </div>
          </Styled.ConversationWrapper>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
