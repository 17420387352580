import React, { ReactElement, useEffect, useRef, useState } from 'react';
import LockIcon from '@styles/icons/lock-icon-test.webp';
import LockHoveredIcon from '@styles/icons/lock-icon-hovered.webp';
import Image from 'next/image';
import { FlexComponent } from '@/common/components/flex';
import * as Styled from './insight-basic-info.styled';
import defaultImgInsight from '@/styles/icons/insight-user-photo.png';
import { DateTimeOutput } from '../../date';
import { ISubPost } from '@/common/types/post.type';

interface IProp {
  isTrending?: boolean;
  fullName: string;
  deletedAt?: Date;
  isAnon?: boolean;
  isImageHidden?: boolean;
  isHidden?: boolean;
  isQuote?: boolean;
  isLatestCoverage?: boolean;
  date?: Date;
  isFaded?: boolean;
  isOriginalPostFaded?: boolean;
  isInCoverageLatest?: boolean;
  className?: string;
  isNotification?: boolean;
  post?: ISubPost;
  typePost?: string;
  onClickBy: () => void;
  hoverPosition: 'top' | 'right';
}

function capitalizeFirstLetter(str: string | undefined): string {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const InsightBasicInfoComponent = ({
  post,
  isHidden,
  isLatestCoverage,
  isImageHidden,
  isOriginalPostFaded,
  isFaded,
  deletedAt,
  isNotification,
  isTrending,
  isQuote,
  fullName,
  typePost,
  isAnon,
  isInCoverageLatest,
  hoverPosition,
  date,
  onClickBy,
  className
}: IProp): ReactElement => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [usernameWidth, setUsernameWidth] = useState<number>(0);
  const insightImg = post?.insight?.source?.imgUrl || defaultImgInsight?.src;
  const sourceType = capitalizeFirstLetter(post?.insight?.source?.sourceType);
  const postTitle = post?.insight?.source?.fullName || 'Fund Letters';
  const isAnonPost = !post?.profile;
  const isWithSource = post?.insight?.source;
  const postUserName = fullName || 'anonymously';
  const isRssInsight = Boolean(post?.insight?.isRssInsight);

  useEffect(() => {
    if (elementRef.current) {
      setUsernameWidth(elementRef.current.getBoundingClientRect().width);
    }
  }, []);

  return (
    <div>
      <Styled.Wrapper
        onClick={isAnonPost ? undefined : onClickBy}
        isHidden={isHidden}
        isAnon={isAnonPost}
        isLatestCoverage={isLatestCoverage}
        className={className}
      >
        {!isQuote && (
          <Styled.ImageWrapper
            typePost={typePost}
            onClick={isAnonPost || isWithSource ? undefined : onClickBy}
            isReply={false}
            imageUrl={insightImg}
            profileId={undefined}
            borderRadius={isLatestCoverage ? '20px' : '30px'}
            isImageHidden={isImageHidden}
            isReplyHidden={isHidden}
            isFaded={isOriginalPostFaded}
            isGrey={isFaded}
            isNotification={isNotification}
            isLatestCoverage={isLatestCoverage}
            isTreanding={isTrending}
          />
        )}
        <Styled.InfoWrapper isAnon={isAnonPost} typePost={typePost} isQuote={isQuote} isInCoverageLatest={isInCoverageLatest}>
          <Styled.FullNameWrapper isTrending={!!isTrending} isAnon={!!isAnon} isQuote={isQuote} isFaded={isFaded || isOriginalPostFaded}>
            {isQuote && !deletedAt && 'quoting'}
          </Styled.FullNameWrapper>
          {!isQuote && (
            <FlexComponent maxHeight="13px" gap="8px" alignItems="baseline">
              {isHidden ? (
                <Styled.Title usernameWidth={usernameWidth}>{fullName}</Styled.Title>
              ) : (
                <>
                  <Styled.Title usernameWidth={usernameWidth}>{postTitle}</Styled.Title>
                  {!isAnonPost && !isWithSource && (
                    <Styled.DetaileInfo
                      ref={elementRef}
                      isIncludeHover={!isRssInsight}
                      isTrending={!!isTrending}
                      isFaded={isFaded || isOriginalPostFaded}
                    >
                      shared by {fullName}
                    </Styled.DetaileInfo>
                  )}
                  {sourceType && (
                    <Styled.DetaileInfo
                      ref={elementRef}
                      isTrending={!!isTrending}
                      isFaded={isFaded || isOriginalPostFaded}
                      fullNameWidth={fullName ? fullName.length * 8 : 0}
                    >
                      {sourceType}
                    </Styled.DetaileInfo>
                  )}
                </>
              )}
            </FlexComponent>
          )}

          {post && post.isPrivate && (
            <Styled.LockHoverContainer>
              <Styled.LockIconContainer>
                <Styled.LockIconStyled src={LockIcon} alt="lock-icon" width={16} height={12} />
              </Styled.LockIconContainer>
              <Styled.HoverContent hoverPosition={hoverPosition}>
                <Image src={LockHoveredIcon} width={16} height={16} alt="hovered-lock-icon" />
                <Styled.StyledIconHoverText>Only visible to you</Styled.StyledIconHoverText>
              </Styled.HoverContent>
            </Styled.LockHoverContainer>
          )}
          {isQuote && !!date && <DateTimeOutput isQuote={isQuote} date={date} />}
        </Styled.InfoWrapper>
      </Styled.Wrapper>
      {isQuote && isWithSource && (
        <FlexComponent gap="5px" alignItems="baseline">
          <Styled.SourceName>{postTitle}</Styled.SourceName>
          <Styled.SourceType>{sourceType}</Styled.SourceType>
        </FlexComponent>
      )}
      {isQuote && !isWithSource && (
        <FlexComponent gap="6px" alignItems="baseline">
          <Styled.SourceName>Fund Letters</Styled.SourceName>
          <Styled.SourceType>shared by {postUserName}</Styled.SourceType>
        </FlexComponent>
      )}
    </div>
  );
};
