/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  DateOutput,
  DateOutputWrapper,
  DateTimeWrapperWithIcon,
  MemoDateIcon,
  MemoPopover,
  MemoPopoverText
} from '../post-stats/post-stats.styled';
import { month } from '@/common/components/onboard/components/employer/employer.const';
import MemoPostDateIcon from '@/styles/icons/memo-post-date.png';
import { IMemoSection } from '@/common/types/post.type';
import { useDebounce } from '@/common/hooks';

interface IProps {
  date?: Date;
  isQuote?: boolean;
  isRepost?: boolean;
  memoSection: any;
  isBackDate?: boolean;
  isInModal?: boolean;
  summaryTooltipPosition?: boolean;
}

export const MemoDateTimeOutput = ({ date, isBackDate, isInModal, isQuote, isRepost, memoSection, summaryTooltipPosition }: IProps) => {
  const writedAt = isBackDate ? new Date(memoSection[0].memoSection.memo.writedAt!) : new Date(memoSection.memo?.writedAt!);
  const sectionCreatedAt = isBackDate ? new Date(memoSection[0].memoSection.memo.createdAt!) : new Date(memoSection.memo!.createdAt);
  const currentDate = new Date().getTime();
  const diffInMinutes = date && Math.floor((currentDate - date.getTime()) / (1000 * 60));
  const diffInHours = diffInMinutes && Math.floor(diffInMinutes / 60);
  const diffInDays = diffInHours && Math.floor(diffInHours / 24);
  let result = '';

  if (diffInDays && diffInDays <= 14) {
    result = `${diffInDays}d`;
  }
  if (diffInHours && diffInHours < 24) {
    result = `${diffInHours}h`;
  }
  if (diffInMinutes && diffInMinutes < 60) {
    result = `${diffInMinutes}m`;
  }
  if (diffInMinutes && diffInMinutes < 0) {
    result = '0m';
  }

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [popoverPosition, setPopoverPosition] = React.useState<{ top: number; left: number } | null>({ top: 0, left: 0 });

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.currentTarget;
    setAnchorEl(target);

    const rect = target.getBoundingClientRect();
    setPopoverPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX
    });
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverPosition(null);
  };

  const open = useDebounce(Boolean(anchorEl), 500);

  return (
    <DateTimeWrapperWithIcon isBackDate={isBackDate} isInModal={isInModal} summaryTooltipPosition={summaryTooltipPosition}>
      <DateOutputWrapper>
        <DateOutput fontSize="10px" isRepost={isRepost} isQuote={isQuote}>
          {new Date(writedAt.setDate(writedAt.getDate())).getDate()} {month[writedAt.getMonth()]} {writedAt.getFullYear()}
        </DateOutput>
      </DateOutputWrapper>
      <MemoDateIcon
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        src={MemoPostDateIcon}
        alt="clock-icon"
      />
      <MemoPopover
        style={{
          top: popoverPosition?.top! + 10,
          left: popoverPosition?.left! - 125
        }}
      >
        <MemoPopoverText>Originally written on this date</MemoPopoverText>
        <MemoPopoverText>
          Published on Villan on {sectionCreatedAt.getDate()} {month[sectionCreatedAt.getMonth() ?? 0]} {sectionCreatedAt.getFullYear()}
        </MemoPopoverText>
      </MemoPopover>
    </DateTimeWrapperWithIcon>
  );
};
