import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { CaseType, Direction, IMemoSection } from '@/common/types/memo.type';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import * as Styled from './display-memo-section.styled';
import { FlexComponent } from '@/common/components/flex';
import { getSectionText, getSectionTitle } from './model/helpers';
import { IdeaModalComponent } from '@/common/components/memo/components/idea-modal';
import arrow from '@/styles/icons/arrow-top-right.svg';
import { highlightText } from '@/common/utils/highlight-text';
import { MemoSectionMatch } from '@/common/consts/memo.const';

export interface IDisplayMemoSectionComponent {
  memoSection: IMemoSection;
  isSearchClickedIdea?: boolean;
  isQuote?: boolean;
  searchText?: string;
  matchCount?: number;
}

export const DisplayMemoSectionComponent = ({
  memoSection,
  matchCount,
  searchText,
  isSearchClickedIdea,
  isQuote
}: IDisplayMemoSectionComponent) => {
  const router = useRouter();
  const isNotificationPage = router.pathname.includes(ROUTER_KEYS.NOTIFICATIONS);
  const isProfilePage = router.pathname.includes(ROUTER_KEYS.PROFILE);
  const isSearchPage = router.pathname.includes(ROUTER_KEYS.SEARCH_PAGE);
  const [openIdeaModal, setOpenIdeaModal] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const clickedId = router?.query?.value;
  const isNotBaseCase = memoSection.type !== CaseType.BASE_CASE;
  const direction = memoSection?.direction || '';
  const formattedDirection = direction.charAt(0).toUpperCase() + direction.slice(1).toLowerCase();

  const updateQueryParams = (memoId: string) => {
    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          idea: memoId
        }
      },
      undefined,
      { shallow: true }
    );
  };

  const clearQuery = () => {
    router.push(
      {
        pathname: router.pathname,
        query: {}
      },
      undefined,
      { shallow: true }
    );
  };

  const handleCloseModal = useCallback(() => {
    setOpenIdeaModal(false);
    setTimeout(() => setIsModalVisible(false), 1000);
    clearQuery();
  }, [clearQuery]);

  const handleClickModal = () => {
    if (isProfilePage) {
      setOpenIdeaModal(true);
      setIsModalVisible(true);
      updateQueryParams(memoSection?.memoId!);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (isQuote) return;
    e.stopPropagation();
    router.push(`${ROUTER_KEYS.MEMO_PUBLISH}/${memoSection.memoId}`);
  };

  useEffect(() => {
    if (router.query.idea) {
      clearQuery();
    }
  }, []);

  if (isSearchClickedIdea) {
    const newText = highlightText({
      text: `${memoSection?.title.split(' ')[0]} ${memoSection?.companyName}`,
      highlight: clickedId as string,
      exactMatch: false
    });

    return (
      <Styled.MemoWrapper isQuote={isQuote}>
        <FlexComponent gap="5x">
          <Styled.MemoTitle style={{ width: '46px' }}>idea</Styled.MemoTitle>
          <Styled.LinkOnMemoSearch isShort={memoSection.direction === Direction.SHORT} isQuote={isQuote} onClick={handleClick}>
            {newText}
          </Styled.LinkOnMemoSearch>
        </FlexComponent>
      </Styled.MemoWrapper>
    );
  }

  if (isNotificationPage) {
    return (
      <Styled.MemoWrapper isQuote={isQuote}>
        <FlexComponent alignItems="center">
          <Styled.MemoTitle isNotificationPage={isNotificationPage}>idea</Styled.MemoTitle>
          <Styled.LinkOnMemoNotification isShort={memoSection.direction === Direction.SHORT} isQuote={isQuote} onClick={handleClick}>
            {memoSection?.title.split(' ')[0]} {memoSection?.companyName}
          </Styled.LinkOnMemoNotification>
        </FlexComponent>
        {isNotBaseCase && (
          <FlexComponent gap="10px">
            <div>
              <Styled.MemoTitle isNotificationPage={isNotificationPage}>{getSectionTitle(memoSection)}</Styled.MemoTitle>
              <Styled.MemoTextNotification isQuote={isQuote}>{getSectionText(memoSection)}</Styled.MemoTextNotification>
            </div>
            {!!memoSection.risk && (
              <div>
                <Styled.MemoTitle style={{ width: '76px' }}>risk / reward</Styled.MemoTitle>
                <Styled.MemoTextNotification isQuote={isQuote}>{memoSection.risk}x</Styled.MemoTextNotification>
              </div>
            )}
            {/* <Styled.Link onClick={handleClick}>
            <Styled.Arrow src={arrow.src} alt="arrow-top" />
            read
          </Styled.Link> */}
          </FlexComponent>
        )}
      </Styled.MemoWrapper>
    );
  }

  if (isSearchPage) {
    const newText = highlightText({
      text: `${memoSection?.title.split(' ')[0]} ${memoSection?.companyName}`,
      highlight: clickedId as string,
      exactMatch: false
    });
    return (
      <Styled.MemoWrapper gap={2} isQuote={isQuote} onClick={handleClick}>
        <Styled.ClickBlock onClick={handleClick} />
        <FlexComponent gap="25px" alignItems="center">
          <Styled.SearchMemoTitle>idea</Styled.SearchMemoTitle>
          <Styled.LinkOnMemoSearchDef isShort={memoSection.direction === Direction.SHORT} isQuote={isQuote} onClick={handleClick}>
            {newText}
          </Styled.LinkOnMemoSearchDef>
        </FlexComponent>
        {searchText ? (
          <Styled.MemoPostTextMention>
            {matchCount || 1} {matchCount && matchCount > 1 ? 'mentions' : 'mention'} of <span>{searchText}</span>
          </Styled.MemoPostTextMention>
        ) : (
          <Styled.MemoPostText>{memoSection?.truncatedText}</Styled.MemoPostText>
        )}
      </Styled.MemoWrapper>
    );
  }

  const sectionName =
    isNotBaseCase && memoSection?.sectionName ? ` ${MemoSectionMatch[memoSection.sectionName] ?? memoSection.sectionName}` : '';

  const title = (
    <Styled.LinkOnMemoHome
      isShort={memoSection.direction === Direction.SHORT}
      isQuote={isQuote}
      onClick={isNotBaseCase ? handleClick : undefined}
    >
      {formattedDirection} {memoSection?.memo?.security}
      {sectionName}: <span>{memoSection?.truncatedText}</span>
      <Styled.LinkHome onClick={isNotBaseCase ? handleClick : undefined}>more</Styled.LinkHome>
    </Styled.LinkOnMemoHome>
  );

  if (isNotBaseCase) {
    return (
      <Styled.MemoWrapper isQuote={isQuote}>
        <FlexComponent alignItems="center">
          <FlexComponent display="flex" gap="4px" alignItems="center" onClick={handleClick}>
            {title}
          </FlexComponent>
        </FlexComponent>
        <FlexComponent gap="10px">
          {!!memoSection.risk && (
            <div>
              <Styled.MemoTitle style={{ width: '76px' }}>risk / reward</Styled.MemoTitle>
              <Styled.MemoText isQuote={isQuote}>{memoSection.risk}x</Styled.MemoText>
            </div>
          )}
        </FlexComponent>
      </Styled.MemoWrapper>
    );
  }

  return (
    <>
      <Styled.MemoWrapper gap={2} isQuote={isQuote} onClick={isQuote ? undefined : handleClick}>
        <Styled.ClickBlock onClick={handleClick} />
        <FlexComponent gap="4px" alignItems="center">
          {title}
        </FlexComponent>
      </Styled.MemoWrapper>
      {(openIdeaModal || isModalVisible) && isProfilePage && !isQuote && (
        <IdeaModalComponent withoutPagination item={memoSection?.memo!} open={openIdeaModal} closeHandler={handleCloseModal} />
      )}
    </>
  );
};
