import { useDeletePost, useFetchTread, usePostStatistic, useTreadByPostId } from '@hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { UseMutationResult } from 'react-query';
import { IPost, IPostWithPostOrOriginalPost, ISubPost } from '@/common/types/post.type';
import * as Styled from './post-row.styled';
import { QuotePostComponent } from './components/quote-post';
import { BasicPostComponent } from './components/basic-post';
import { ConversationTreadComponent } from './components/conversation-tread';
import { ReplyDivider } from './components/conversation-tread/conversation-tread.styled';
import { ReplyComponent } from '@/common/components/post/components/reply';
import { RepostComponent } from './components/repost';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { Loader } from '@/common/components/loader';
import { EInsightType } from '@/common/types/insight.types';
import { PodcastSectionsThread } from './components/podcast-sections-thread';

interface IRowProps {
  post: IPost;
  isAnon?: boolean;
  isHome?: boolean;
  isProfile?: boolean;
  isAnotherUserProfile?: boolean;
  repliedPostProp?: ISubPost;
  quotePostHandler: (postId: string) => void;
  openReplyModal?: (postId: string, searchId: string) => void;
  updateQuestionInModal?: (post: ISubPost) => void;
  closeModal?: () => void;
  customOpenReply?: () => void;
  customCloseHandler?: () => void;
  userProfileId?: string;
  profileId?: string;
  isPublish?: boolean;
  isLast?: boolean;
  isInQuoteModal?: boolean;
  isTreadOpen?: boolean;
  isInModal?: boolean;
  isTreadHidden?: boolean;
  isReplyOpen?: boolean;
  onPostClick?: (isReplyOpen?: boolean) => void;
  openTreadOnMemo?: boolean;
  isTreanding?: boolean;
  isInBookmarkSection?: boolean;
  isMemoPost?: boolean;
  isIdeaModal?: boolean;
  isHidePostStats?: boolean;
  memosSection?: any;
  dividerWidth?: number;
  isDefaultStats?: boolean;
  isIdeaPageView?: boolean;
  disableQuoteAndRepost?: boolean;
  isPodcastModal?: boolean;
  onIdeaPageClick?: () => void;
  useDeletePostHook?: ({
    postId,
    profileId,
    close
  }: {
    postId?: string;
    profileId?: string;
    close?: () => void;
  }) => UseMutationResult<void | IPost, unknown, string, unknown>;
}

export const PostRowComponent = ({
  post,
  dividerWidth,
  isAnon,
  isHome,
  isProfile,
  isAnotherUserProfile,
  repliedPostProp,
  memosSection,
  isMemoPost,
  isHidePostStats,
  customOpenReply,
  customCloseHandler,
  quotePostHandler,
  openReplyModal,
  updateQuestionInModal,
  closeModal,
  profileId,
  userProfileId,
  isPublish,
  isPodcastModal,
  isLast,
  isInQuoteModal,
  isTreadOpen,
  isInModal,
  isTreadHidden,
  isReplyOpen,
  onPostClick,
  openTreadOnMemo,
  isTreanding,
  isInBookmarkSection,
  isIdeaModal,
  isDefaultStats,
  isIdeaPageView,
  disableQuoteAndRepost,
  useDeletePostHook = useDeletePost,
  onIdeaPageClick
}: IRowProps) => {
  const router = useRouter();
  const isInsightNewPodcast = post?.insight?.isNewPodcastType || post?.insight?.type === EInsightType.AI_GENERATED_PODCAST;
  const [isClickOnPost, setIsClickOnPost] = useState(() => {
    if (isInsightNewPodcast) {
      return false;
    }
    return !!post.tread?.replies?.length;
  });
  const [repliedPost, setRepliedPost] = useState<null | ISubPost>(repliedPostProp ?? null);
  const [openReply, setOpenReply] = useState(!!isReplyOpen);
  const [isTreadFetched, setIsTreadFetched] = useState(false);

  useEffect(() => {
    setOpenReply(!!isReplyOpen);
  }, [isReplyOpen]);

  const { hasNext, replies } = useTreadByPostId(post.id);
  const { data: statistic } = usePostStatistic(post.id);
  useEffect(() => {
    if (typeof openTreadOnMemo === 'undefined') return;
    setIsClickOnPost(openTreadOnMemo);
  }, [openTreadOnMemo]);

  const { mutateAsync: deletePost, isSuccess } = useDeletePostHook({
    postId: post.postId,
    profileId,
    close: closeModal
  });
  const { mutateAsync: featchTread, isLoading: isTreadLoading } = useFetchTread(post.id);

  useEffect(() => {
    if (replies?.length && !isClickOnPost && post.memo) {
      setIsClickOnPost(true);
    }
  }, [replies]);

  useEffect(() => {
    if (isClickOnPost && !isTreadLoading && hasNext && !replies?.length && !isTreadFetched) {
      featchTread();
      setIsTreadFetched(true);
    }
  }, [isClickOnPost]);

  useEffect(() => {
    if (isSuccess && closeModal) {
      closeModal();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isTreadOpen) {
      setIsClickOnPost(true);
    }
  }, [isTreadOpen]);

  const onClickByPost = (event?: React.MouseEvent<HTMLDivElement, MouseEvent>, isReplyOpenProp?: boolean) => {
    if (onPostClick) {
      onPostClick(isReplyOpenProp);
      return;
    }
    setIsClickOnPost((prev) => !prev);
    event?.preventDefault();
    // event?.stopPropagation();
  };

  const openReplyClick = (postItem?: IPostWithPostOrOriginalPost, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event?.stopPropagation();
    if (isTreadHidden) {
      onClickByPost(undefined, true);
      if (customOpenReply) {
        customOpenReply();
      }
      return;
    }
    // if (isPublish && statistic?.replyCount === 0 && userProfileId === post.profileId) {
    //   return;
    // }
    if (postItem === (repliedPost ?? undefined) && openReply) {
      setRepliedPost(null);
      setOpenReply(false);
      return;
    }

    setRepliedPost(postItem ?? null);
    setOpenReply(true);
  };

  const onClickByQuotePostOrRepostHandler = () => {
    if (!openReplyModal || !post.post?.id) return;
    if (post.post?.originalPostId) {
      openReplyModal(post.post.originalPostId, post.post.id);
      return;
    }
    openReplyModal(post.post.id, post.post.id);
  };

  const openPostInModal = () => {
    if (!openReplyModal || !post?.id) return;
    if (post?.originalPostId) {
      openReplyModal(post.originalPostId, post.id);
      return;
    }
    openReplyModal(post.id, post.id);
  };

  const isNotDisplayPostRow = useMemo(
    () =>
      (!(statistic?.replyCount ?? post.replyCount ?? 0) && !!post.deletedAt) ||
      (post.isRepost && !!post.post?.deletedAt) ||
      !((post.post && post.isRepost) || (post.post && post.isRepost === false) || (!post.post && post.isRepost === null)),
    [statistic?.replyCount, post.replyCount, post.deletedAt, post.post, post.isRepost]
  );

  const isProfilePage = router.pathname.includes(ROUTER_KEYS.PROFILE);
  const isIdeaPage = router.pathname.includes(ROUTER_KEYS.MEMO_PUBLISH);
  const isIdeaHomePage = router.pathname.includes(ROUTER_KEYS.MEMO) || Boolean(router.query.idea);
  // if (isProfilePage && post.memoSection?.memo?.isAnon) {
  //   return null;
  // }

  const closeHandler = () => {
    if (customCloseHandler) {
      customCloseHandler();
    }
    setOpenReply(false);
  };

  if (isTreadLoading && isIdeaModal) {
    return <Loader />;
  }

  return (
    <Styled.TwoTreadsWrapper isIdeaModal={isIdeaModal} isNotDisplay={isNotDisplayPostRow}>
      <Styled.PostWrapper isPublish={isPublish} isInModal={isInModal} isIdeaModal={isIdeaModal}>
        {post.post && post.isRepost && (
          <RepostComponent
            isAnon={isAnon}
            post={post}
            parrentPost={post.post}
            statistic={statistic}
            userProfileId={userProfileId}
            quotePostHandler={quotePostHandler}
            isInModal={isInModal}
            openReply={(e) => openReplyClick(undefined, e)}
            onClickByChildPost={onClickByQuotePostOrRepostHandler}
            closeModal={closeModal}
            isTreadOpen={isClickOnPost}
          />
        )}
        {post.post && post.isRepost === false && (
          <QuotePostComponent
            isAnon={isAnon}
            post={post}
            parrentPost={post?.post}
            onClickByQuotePost={onClickByQuotePostOrRepostHandler}
            statistic={statistic}
            userProfileId={userProfileId}
            deletePost={deletePost}
            onClickByPost={onClickByPost}
            quotePostHandler={quotePostHandler}
            openReply={(e) => openReplyClick(undefined, e)}
            isInModal={isInModal}
            closeModal={closeModal}
            isTreadOpen={isClickOnPost}
            isInQuoteModal={isInQuoteModal}
            memosSection={memosSection}
          />
        )}
        {!post.post && post.isRepost === null && !isPodcastModal && (
          <BasicPostComponent
            disableQuoteAndRepost={disableQuoteAndRepost}
            onIdeaPageClick={onIdeaPageClick}
            isIdeaPageView={isIdeaPageView}
            updateQuestionInModal={updateQuestionInModal}
            isHome={isHome}
            openPostInModal={openPostInModal}
            isAnotherUserProfile={isAnotherUserProfile}
            isAnon={isAnon}
            post={post}
            isDefaultStats={isDefaultStats}
            isHidePostStats={isHidePostStats}
            userProfileId={userProfileId}
            onClickByPost={onClickByPost}
            deletePost={post.memo ? undefined : deletePost}
            openReply={(e) => openReplyClick(undefined, e)}
            isInModal={isInModal}
            quotePostHandler={quotePostHandler}
            closeModal={closeModal}
            statistic={statistic}
            isTreadOpen={isClickOnPost}
            isPublish={isPublish}
            isIdeaModal={isIdeaModal}
            memosSection={memosSection}
          />
        )}
      </Styled.PostWrapper>
      {!isTreadHidden && isPodcastModal && (
        <PodcastSectionsThread
          isPodcastModal={isPodcastModal}
          disableQuoteAndRepost={disableQuoteAndRepost}
          parentPost={post}
          replies={replies}
          isDefaultStats={isDefaultStats}
          dividerWidth={dividerWidth}
          isMemoPost={isMemoPost}
          profileId={profileId}
          isTreanding={isTreanding}
          quotePostHandler={quotePostHandler}
          openReply={openReplyClick}
          userProfileId={userProfileId}
          isClickOnPost={isClickOnPost || !!openTreadOnMemo}
          isInModal={isInModal}
          postId={post.id}
          memosSection={memosSection || post.memoSection}
          closeModal={closeModal}
        />
      )}
      {!isTreadHidden && !isPodcastModal && (
        <ConversationTreadComponent
          disableQuoteAndRepost={disableQuoteAndRepost}
          parentPost={post}
          replies={replies}
          isInsightNewPodcast={isInsightNewPodcast}
          isDefaultStats={isDefaultStats}
          dividerWidth={dividerWidth}
          isMemoPost={isMemoPost}
          isPublish={isPublish}
          isIdeaModal={isIdeaModal}
          profileId={profileId}
          isTreanding={isTreanding}
          quotePostHandler={quotePostHandler}
          openReply={openReplyClick}
          userProfileId={userProfileId}
          isClickOnPost={isClickOnPost || !!openTreadOnMemo}
          isInModal={isInModal}
          postId={post.id}
          memosSection={memosSection || post.memoSection}
          closeModal={closeModal}
        />
      )}
      {!isTreadHidden && !isPodcastModal && (
        <AnimatePresence>
          {openReply &&
            statistic &&
            (statistic.replyCount <= 0 ? (
              <motion.div
                transition={{ ease: 'backInOut', delay: 0.2, duration: 0.5 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                style={{ maxWidth: isPublish || isIdeaModal ? '540px' : '100%' }}
              >
                {isIdeaPage && (
                  <ReplyDivider
                    isIdeaPageConversation
                    dividerWidth={dividerWidth}
                    isPublish={isPublish}
                    isAnon={isAnon}
                    isInModal={isInModal}
                    isIdeaModal={isIdeaModal}
                    isMemoPost={isMemoPost || !!memosSection}
                  />
                )}
                {(!isIdeaHomePage || isIdeaPageView) && (
                  <ReplyDivider
                    dividerWidth={dividerWidth}
                    isPublish={isPublish}
                    isAnon={isAnon}
                    isInModal={isInModal}
                    isIdeaModal={isIdeaModal}
                    isMemoPost={isMemoPost || !!memosSection}
                  />
                )}
                {isIdeaHomePage && replies && replies?.length > 0 && (
                  <ReplyDivider
                    dividerWidth={540}
                    isPublish={isPublish}
                    isAnon={isAnon}
                    isInModal={isInModal}
                    isIdeaModal={isIdeaModal}
                    isMemoPost={isMemoPost || !!memosSection}
                  />
                )}
                <ReplyComponent
                  isIdeaModal={isIdeaModal}
                  userProfileId={userProfileId}
                  repliedPost={repliedPost ?? undefined}
                  postId={repliedPost?.id}
                  originalPostId={post.id}
                  originalPost={post}
                  onClickByPost={onClickByPost}
                  closeHandler={closeHandler}
                />
              </motion.div>
            ) : (
              <div style={{ maxWidth: isPublish || isIdeaModal ? '540px' : '100%' }}>
                <ReplyDivider
                  dividerWidth={dividerWidth}
                  isIdeaPage={isIdeaPage}
                  isIdeaHomePage={isIdeaHomePage}
                  isPublish={isPublish}
                  isInModal={isInModal}
                  isAnon={isAnon}
                  isIdeaModal={isIdeaModal}
                  isMemoPost={isMemoPost || !!memosSection}
                />
                <ReplyComponent
                  isIdeaPage={isIdeaPage}
                  isIdeaModal={isIdeaModal}
                  userProfileId={userProfileId}
                  repliedPost={repliedPost ?? undefined}
                  postId={repliedPost?.id}
                  originalPostId={post.id}
                  originalPost={post}
                  onClickByPost={onClickByPost}
                  closeHandler={() => setOpenReply(false)}
                />
              </div>
            ))}
        </AnimatePresence>
      )}
      {!isPublish && !isPodcastModal && (
        <Styled.PostDivider
          isProfile={isProfile}
          isDefaultWidth={isTreanding || isInQuoteModal}
          isInBookmarkSection={isInBookmarkSection}
          isAnon={isAnon}
          isInModal={isInModal}
        />
      )}
    </Styled.TwoTreadsWrapper>
  );
};
