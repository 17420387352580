/* eslint-disable no-tabs */
/* eslint-disable prettier/prettier */
import Image from 'next/image';
import styled, { AnyStyledComponent } from 'styled-components';
import { HTMLProps } from 'react';
import { COLORS, FONTS } from '@/theme';
import { FAMILIES } from '@/theme/fonts.const';
import { UserImageComponent } from '@/common/components/user-image';
import { ModalComponent } from '@/common/components/modal';
import { Loader } from '@/common/components/loader';
import { ConnectionActionsComponent } from '../connection-actions';
import {
  BoxAroundProfileSection,
  BoxAroundSection,
  HeaderTitleUnderlineWrapper
} from '@/common/components/page-wrapper/components/box-around-section';

export const BasicStatusWrapper = styled(BoxAroundSection)`
  font-family: ${FAMILIES.robotoFlex};
  color: ${COLORS.black};
  padding: 18px 20px;
  width: 426px;
  margin-bottom: 10px;
  background: tranparent;
`;

export const BasicStatusProfileWrapper = styled(BoxAroundProfileSection)`
  font-family: ${FAMILIES.robotoFlex};
  color: ${COLORS.black};
  padding: 0;
  width: 350px;
  background: tranparent;
`;

export const BasicStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 30px;
  margin-bottom: 30px;
`;

export const PhotoContainer = styled.div``;

export const UserInfoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

export const UserNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  position: relative;
`;

export const HeaderUnderlineOnBasicProfile = styled(HeaderTitleUnderlineWrapper)`
  ${({ isAnon }) => isAnon && 'border-bottom: solid 1px #5F5F5F;'};
  padding-bottom: 4px;
  margin-bottom:3px;
`;

export const SettingsButton = styled.button`
  position: absolute;
  top: 7px;
  right: 0;
  background-color: transparent;
  border: none;

  display: flex;
  gap: 3px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > p {
    color: #393939;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px; 
  }
`;

export const UserNameWithImageContainer = styled.div`
  display: flex;
  gap: 10px;
  width: fit-content;
  align-items: center;
  justify-content: center;
  min-height: 30px;
`;

export const SendMessageBtn = styled.button`
  display: flex;
  align-items: center;
  text-align: right;
  color: #aa5c00;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.medium};
  line-height: 1;

  border: none;
  border-radius: 7px;
  background: rgba(170, 92, 0, 0.1);
  padding: 6px 15px;
  margin-top: 10px;

  cursor: pointer;
`;

export const FollowersAndBioContainer = styled.div`
height: 89px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
  max-width: 410px;
`;

export const BioContainer = styled.div<{ isAnon?: boolean }>`
color: #000;
font-family: "Roboto Flex";
font-size: 13px;
font-style: normal;
font-weight: 300;
line-height: 20px;
  display: flex;
  width: 100%;
  button {
    border: 0;
    background: transparent;
    ::focus-visible {
      outline: 0;
    }

    cursor: pointer;
    color: #0016d8;
    margin-bottom: 5px;
    font-family: 'Roboto Flex';
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 13px;
  }
`;

export const EditBioLink = styled.span`
color: #515151;
font-family: "Roboto Flex";
font-size: 12px;
font-style: normal;
font-weight: 200;
line-height: 16px; 
  cursor: pointer;
  letter-spacing: 0.1px;
`;
export const CurrentUserSubInfo = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  font-size: ${FONTS.SIZES.s};
  font-weight: ${FONTS.WEIGHTS.extra_light};
  color: ${COLORS.profileUsernameSubInfo};
  line-height: ${FONTS.SIZES.l};
  display: flex;
  flex-wrap: wrap;
  // align-items: center;
  img {
    margin-left: 5px;
  }
`;

export const CurrentUserSubInfoTest = styled.p`
color: #9C9C9C;
font-family: "Roboto Flex";
font-size: 12px;
font-style: normal;
font-weight: 200;
line-height: 16px; 
letter-spacing: 0.1px;
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding-left: 7px;
`;

export const UserName = styled.div<{ isAnon: boolean }>`
  font-size: 15px;
  line-height: ${FONTS.SIZES.m};
  font-weight: ${FONTS.WEIGHTS.medium};
  letter-spacing: 0.3px;
  max-width: 275px;
`;

export const FollowersContainer = styled.div`
  display: flex;
  gap: 15px;
`;

export const FollowersItemContainer = styled.div<{ isClickable?: boolean; isAnon?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 10px;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 14px; 
  color: #474747;
  ${({ isAnon }) => isAnon && 'color: #dadada;'}
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
`;

export const MutualAssociationsContainer = styled.div`
  display: flex;
  gap: 5px;
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.m};
  color: #8d8d8d;
`;

export const LockedProfileIcon = styled(Image)`
  width: 12px;
  height: 12px;
`;

export const MutualAssociationsIcon = styled(Image)`
  width: 19.6px;
  height: 14px;
`;

// export const BioContainer = styled.div`
//   font-size: 13px;
//   line-height: 18px;
//   font-weight: ${FONTS.WEIGHTS.extra_light};
//   color: #222;
//   text-indent: 7.5px;
// `;

export const AssociationsContainer = styled.div`
  margin-top: -4px;
  display: flex;
  gap: 5px;
  justify-content: start;
  align-items: start;
`;

export const UserImage = styled(UserImageComponent)`
  min-width: 80px;
  width: 80px;
  min-height: 80px;
  height: 80px;
  cursor: auto;
`;

export const ThreeDots = styled(Image)`
  cursor: pointer;
  transform: rotate(90deg);
`;

export const FollowsModalWrapper = styled(ModalComponent as AnyStyledComponent)`
  padding: 0 50px 18px;
  min-width: 602px;
  max-width: 602px;
  min-height: calc(100vh - 30px);
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.95);
  position: fixed;
  color: ${COLORS.black};
  font-size: ${FONTS.SIZES.s};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 50px;
  border-radius: 10px;
  background: #e3e3e3;
  display: inline-flex;
  padding: 8px 10px;
  align-items: flex-start;
  gap: 8px;
  border: none;
  cursor: pointer;
  color: #515151;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  z-index: 200;
`;

export const CloseImage = styled(Image)`
  width: 12px;
  height: 12px;
`;

export const FollowsModalLoading = styled(Loader as AnyStyledComponent)`
  width: 100%;
  min-height: 274px;
  align-items: center;
`;

export const FollowsModalCounter = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  padding-top: 30px;
  padding-bottom: 8px;
  margin-left: -50px;
  top: 0;
  left: 0;
  width: calc(100% + 100px);
  padding-left: 50px;
  border-radius: 10px 10px 0px 0px;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  z-index: 99;

  & > p:first-child {
    font-size: ${FONTS.SIZES.l};
    margin-right: 2px;
    font-weight: ${FONTS.WEIGHTS.medium};
    width: fit-content;
    padding: 5px 0 7px 0;
  }
`;

export const FollowModalUserContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
  margin-top: 12px;
  img {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    object-fit: cover;
  }
  flex-wrap: wrap;
  ::after {
    content: '';
    height: 1px;
    background: ${COLORS.gapLineUserModal};
    width: 100%;
    display: block;
    margin: 12px 0px 0px 0px;
  }

  div {
    display: flex;
    gap: 5px;
  }
`;

export const ImageWrapper = styled(UserImageComponent)`
  width: 30px;
  height: 30px;
`;

export const FollowsModalUsersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-top: 3px;
  min-width: 500px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  ${FollowModalUserContainer}:last-child {
    ::after {
      margin-top: 10px;
      content: '';
      height: 1px;
      background: ${COLORS.gapLineUserModal};
      width: 100%;
      display: block;
    }
  }
`;

export const FollowModalRow = styled.div`
  display: flex;
`;

export const ConnectionActions = styled(ConnectionActionsComponent)`
  margin-top: 10px;
`;

export const FollowModalUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px !important;
  max-width: 205px;
`;

export const FollowModalUsername = styled.p`
  display: flex;
  color: #2E2E2E;
font-family:  ${FAMILIES.robotoFlex};
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 13px;
`;

export const FollowModalRole = styled.p`
color: #A0A0A0;
font-family:  ${FAMILIES.robotoFlex};
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 13px; 
`;

export const FollowModalUserButtons = styled.div`
  display: flex;
  gap: 4px;
`;

interface IFollowModalUserButtonProps extends HTMLProps<HTMLButtonElement> {
  $active: boolean;
  $disabled?: boolean;
}

export const FollowModalUserButton = styled.button<IFollowModalUserButtonProps>`
  font-family: ${FAMILIES.robotoFlex};
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 13px;
  border: 0;
  cursor: ${({ $active }) => ($active ? 'pointer' : 'default')};
  background: ${({ $active }) =>
    $active ? 'linear-gradient(264deg, rgba(0, 148, 121, 0.10) 0%, rgba(0, 24, 241, 0.10) 100%)' : 'rgba(0, 107, 131, 0.60)'};
  color: ${({ $active }) => ($active ? '#0018F1' : '#FFF')};
  ${({ $disabled }) => $disabled && 'color: #FFF; background: rgba(0, 107, 131, 0.30);'}
  &:focus-visible {
    outline: 0;
  }
`;

export const PaginationContainer = styled.button`
  font-family: ${FAMILIES.robotoFlex};
  margin-top: 15px;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 8px;
  max-width: 167px;
  border: 0px;
  background-color: transparent;
  &:focus-visible {
    outline: 0;
  }
  p {
    color: ${COLORS.buttonPaginationUserModal};
    font-size: ${FONTS.SIZES.m};
  }
`;

export const HiddenImage = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.hiddenImageBackgroundColor};
  backdrop-filter: blur(2.5px);
  img {
    width: 14px !important;
    height: 14px !important;
    border-radius: 0 !important;
    padding-right: 0 !important;
    margin-right: 0 !important;
  }
`;

export const OtherWebsitesFollowers = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 7px;
`;
export const OtherFollowersItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
export const FollowersCountContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`;

export const OtherFollowersIcon = styled(Image)`
  width: 9px;
  height: 9px;
`;

const BaseOtherFollowersText = styled.div`
  color: #777;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 13px;
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.normal};
  line-height: 13px;
`;

export const OtherFollowersText = styled(BaseOtherFollowersText)`
color: #5A5A5A;
font-weight: 300;
`;

export const OtherFollowersCount = styled(BaseOtherFollowersText)`
color: #5A5A5A;
font-weight: 300;
`;

export const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const UserBioInfo = styled.div``;
