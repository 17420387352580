import React, { useEffect, useState } from 'react';
import Plus from '@styles/icons/submit-idea-plus.webp';
import { useRouter } from 'next/router';
import SummarizeIcon from '@styles/icons/summaraz-memo-icon.webp';
import AddIcon from '@styles/icons/add-insight-icon.webp';
import DecorIcon from '@styles/icons/add-post-moda-decor-icon.webp';
import PenInactive from '@styles/icons/pen_inactive.png';
import DownloadActive from '@styles/icons/download_active.png';
import { Stack } from '@mui/material';
import Image from 'next/image';
import toast from 'react-hot-toast';
import CreateMemoManual from '@/styles/icons/write-it.webp';
import MemoUpload from '@/styles/icons/memo-upload.webp';
import GrayDot from '@/styles/icons/gray_dot.png';
import CloseIcon from '@/styles/icons/close-modal-icon.webp';
import { SelectionType } from '@/common/types/memo.type';
import * as Styled from './memo-first-section.styled';
import { FlexComponent } from '@/common/components/flex';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { UploadDocumentComponent } from '../submit-new-idea-form/components/memo-optional-details/memo-document.component';
import { StepperComponent } from '@/common/components/create-modal/components/poll/components/stepper';
import { PostFormActions } from '@/common/components/create-modal/components/insight/components/post-form-actions';
import { COLORS } from '@/theme';

interface IMemoFirstSection {
  isOpenOptions: boolean;
  setIsOpenOptions: (isOpen: boolean) => void;
  handleFileChange: (file: File | undefined) => void;
  handleFileNameChange: (fileName: string) => void;
  selectedType?: undefined | SelectionType;
  setSelectedType: (arg: undefined | SelectionType) => void;
}

const steps = [
  {
    value: ''
  }
];

const stepsForDoc = [
  {
    value: '',
    lineHeight: '30px',
    disabledLineHeight: '30px'
  },
  {
    value: ''
  }
];

export const MemoFirstSection = ({
  isOpenOptions,
  setIsOpenOptions,
  handleFileNameChange,
  handleFileChange,
  selectedType,
  setSelectedType
}: IMemoFirstSection) => {
  const router = useRouter();
  const [isFileAtaching, setIsFileAtaching] = useState(false);
  const [fileName, setFileName] = useState<string>('');
  const [isFileSending, setIsFileSending] = useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);

  const [activeButton, setActiveButton] = useState<string>('');

  const handleButtonClick = (sectionType: SelectionType, buttonType: string) => {
    setSelectedType(sectionType);
    setActiveButton(buttonType);
  };

  const onClickByNewMemo = () => {
    localStorage.removeItem('memoId');
    router.push(ROUTER_KEYS.MEMO_ADD);
  };

  const onClose = () => {
    setIsOpenOptions(false);
    setSelectedType(undefined);
    setFile(undefined);
    setFileName('');
    setIsFileAtaching(false);
    setActiveButton('');
  };

  const saveFileToIndexedDB = (fileData: File): Promise<void> =>
    new Promise((resolve, reject) => {
      const request = indexedDB.open('FileStorage', 1);

      request.onupgradeneeded = () => {
        const db = request.result;
        if (!db.objectStoreNames.contains('files')) {
          db.createObjectStore('files', { keyPath: 'fileName' });
        }
      };

      request.onsuccess = async () => {
        try {
          const arrayBuffer = await fileData.arrayBuffer();
          const db = request.result;
          const transaction = db.transaction('files', 'readwrite');
          const store = transaction.objectStore('files');
          store.put({ fileName: fileData.name, file: arrayBuffer, fileType: fileData.type });
          transaction.oncomplete = () => resolve();
          transaction.onerror = (event) => reject(event);
        } catch (err) {
          toast.error(err?.toString() ?? '');
        }
      };

      request.onerror = (event) => reject(event);
    });

  const onClickBySummarizeButton = async () => {
    if (selectedType === SelectionType.DOCUMENT_SECTION && fileName && file) {
      // setIsFileSending(true);
      try {
        await saveFileToIndexedDB(file);
        setIsFileSending(false);
      } catch (err) {
        toast.error(`Error: ${err?.toString()}`);
        setIsFileSending(false);
      }
      localStorage.setItem('fileName', fileName);
      localStorage.setItem('isFileAtaching', JSON.stringify(isFileAtaching));
      router.push(ROUTER_KEYS.MEMO_ADD_UPLOADED);
    }
  };

  useEffect(() => {
    if (isFileAtaching) {
      handleFileChange(file);
      handleFileNameChange(fileName);
    }
  }, [file, fileName, isFileAtaching, handleFileChange, handleFileNameChange]);

  return (
    <Styled.ModalWrapper
      componentsProps={{
        backdrop: {
          style: {
            backgroundColor: 'rgba(244, 244, 244, 0.75)',
            backdropFilter: 'blur(5px)'
          }
        }
      }}
      open={isOpenOptions}
      closeHandler={onClose}
      childTopPostion={15}
      isCreateModal
      isSubmitIdea
    >
      <Styled.Hidden src={Plus} alt="Hidden icon" />
      <Styled.Hidden src={MemoUpload} alt="Hidden icon" />
      <Styled.Hidden src={CreateMemoManual} alt="Hidden icon" />
      <Styled.Hidden src={SummarizeIcon} alt="Hidden icon" />

      <Styled.HeaderModalContainer>
        <Styled.HeaderModalTabs>
          {/* <Image src={DecorIcon} width={20} height={20} alt="decor-icon" /> */}
          <Styled.SelectModalContainer>
            {/* <Styled.SelectItem key="idea" isSelected>
              idea
            </Styled.SelectItem> */}
            <Stack gap="10px">
              <Styled.ModalTitle>Submit idea: upload document or write now</Styled.ModalTitle>
            </Stack>
          </Styled.SelectModalContainer>
        </Styled.HeaderModalTabs>
        <Styled.CloseButton onClick={onClose}>
          <Styled.CloseButtonImage src={CloseIcon} alt="close-icon" />
        </Styled.CloseButton>
      </Styled.HeaderModalContainer>
      <Styled.TitleContainer>
        {/* <Styled.IconContainer>
          <Image src={AddIcon} width={12} height={12} alt="add-post" />
        </Styled.IconContainer> */}
        <Stack gap="10px">
          {/* <Styled.ModalTitle>Submit idea: upload document or write now</Styled.ModalTitle> */}
          <Styled.ModalHeaderText>
            <Styled.ContainerText>Before submitting, you have the option to:</Styled.ContainerText>
            <Styled.ContainerTextList>
              <Styled.Dot>•</Styled.Dot> Share your idea anonymously
            </Styled.ContainerTextList>
            <Styled.ContainerTextList>
              <Styled.Dot>•</Styled.Dot> Share your full write-up: by default, uploaded documents are not shared
            </Styled.ContainerTextList>
          </Styled.ModalHeaderText>
        </Stack>
      </Styled.TitleContainer>
      <FlexComponent gap="15px" marginTop="20px" paddingTop="6px">
        <StepperComponent
          grayDotStep={selectedType === SelectionType.DOCUMENT_SECTION ? 2 : 1}
          allDotsActive
          isDotted
          step={1}
          steps={selectedType === SelectionType.DOCUMENT_SECTION ? stepsForDoc : steps}
        />
        <FlexComponent gap="21px" flexDirection="column" marginTop="-7px">
          <Styled.ContainerButtons>
            <FlexComponent gap="7px" width="100%">
              <Styled.Button
                isActive={activeButton === 'upload'}
                onClick={() => handleButtonClick(SelectionType.DOCUMENT_SECTION, 'upload')}
                style={{
                  backgroundColor: activeButton === 'upload' ? '#e1e1e1' : activeButton ? '#EFEFEF' : '#EDEEFF',
                  color: activeButton === 'upload' ? '#686868' : activeButton ? '#686868' : '#0018f1'
                }}
                width={235}
                type="button"
                inActive={selectedType === SelectionType.DOCUMENT_SECTION}
                // onClick={() => setSelectedType(SelectionType.DOCUMENT_SECTION)}
                isOpenOptions={isOpenOptions}
              >
                <Styled.ImagesContainer>
                  <Styled.Images src={activeButton === 'upload' ? DownloadActive : MemoUpload} alt="Create Memo Manual Icon" />
                </Styled.ImagesContainer>
                upload document
              </Styled.Button>
              <Styled.Button
                width={235}
                type="button"
                inActive={selectedType === SelectionType.DOCUMENT_SECTION}
                onClick={onClickByNewMemo}
                isOpenOptions={isOpenOptions}
                style={{
                  backgroundColor: activeButton === 'write' ? '#e1e1e1' : activeButton ? '#EFEFEF' : '#EDEEFF',
                  color: activeButton === 'upload' ? '#BBB' : activeButton ? '#686868' : '#0018f1'
                }}
              >
                <Styled.ImagesContainer>
                  <Styled.Images
                    src={activeButton === 'upload' ? PenInactive : CreateMemoManual}
                    alt="Create Memo Manual Icon"
                    // style={{
                    //   marginLeft: '-0px'
                    // }}
                  />
                </Styled.ImagesContainer>
                write it now
              </Styled.Button>
            </FlexComponent>
          </Styled.ContainerButtons>
          {selectedType === SelectionType.DOCUMENT_SECTION && (
            <Styled.ContainerForAllSection>
              <Styled.UploadActions>
                {/* <Styled.UploadsSummarizedText>
                  Uploads are summarized with AI to provide a succinct read, which you can edit
                </Styled.UploadsSummarizedText> */}
                <Stack gap="8px">
                  <UploadDocumentComponent
                    isCurrent
                    setDocumentName={setFileName}
                    documentName={fileName}
                    setDocumentUrl={(name: string) => {}}
                    setFile={setFile}
                    isFileForProcessing
                  />
                  <Styled.FileAtachingWrapper>
                    <button type="button" onClick={() => setIsFileAtaching((prev) => !prev)}>
                      {isFileAtaching && <div />}
                    </button>
                    <Styled.AttachDocumentText>attach document for others to view</Styled.AttachDocumentText>
                  </Styled.FileAtachingWrapper>
                </Stack>
              </Styled.UploadActions>
              <PostFormActions
                isMemo
                title="submit idea for summarization"
                isLoading={isFileSending}
                isActive={selectedType === SelectionType.DOCUMENT_SECTION && !!fileName}
                activeCallback={onClickBySummarizeButton}
              />
            </Styled.ContainerForAllSection>
          )}
        </FlexComponent>
      </FlexComponent>
    </Styled.ModalWrapper>
  );
};
