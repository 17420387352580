import styled from 'styled-components';
import Image from 'next/image';
import { ModalComponent } from '@/common/components/modal';
import { FONTS } from '@/theme';

export const ModalWrapper = styled(ModalComponent)`
  height: 100%;
  min-width: 600px;
  max-width: 600px;
  top: 15px;
  min-height: calc(100vh - 30px);
  padding: 25px 30px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  display: flex;
  flex-direction: column;
  color: #000;
  overflow: scroll;
  border-radius: 10px !important;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: -5px 5px 10px 0px rgba(202, 202, 202, 0.15);
  z-index: 15 !important;
`;

export const ModalTitle = styled.p`
  color: #676767;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  margin-bottom: 5px;
`;

export const ModalText = styled.p`
  color: #000;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  margin-bottom: 6px;
`;

export const CloseButton = styled.button`
  background-color: #fff;
  border: none;
  cursor: pointer;
`;

export const CloseButtonImage = styled(Image)`
  width: 20px;
  height: 20px;
`;

export const PodcastBaseText = styled.p`
  color: #878787;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
`;

export const PodcastLabelText = styled(PodcastBaseText)`
  letter-spacing: 0.1px;
`;

export const PodcastDotText = styled(PodcastBaseText)`
  color: #ccc;
`;
