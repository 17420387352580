import React, { useEffect, useState } from 'react';
import AddMaterialsIcon from '@styles/icons/add-insight-icon.webp';
import Image from 'next/image';
import { useCreateFeedByUser } from '@/common/hooks';
import * as Styled from './insight.styled';
import { InputButtonComponent } from '@/common/components/anon/components/ui/input-button/input-button.component';
import { InputField } from '@/common/components/anon/components/ui/input-button/input-button.styled';
import { Stepper } from '@/common/components/onboard-new/components/stepper';
import { PostFormActions } from './components/post-form-actions';
import { POST } from '@/common/consts';

interface IProps {
  closeHandler: () => void;
}

export const InsightComponent: React.FC<IProps> = ({ closeHandler }) => {
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState(1);
  const [selected, setSelected] = useState<boolean>(false);
  const [inputLink, setInputLink] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');
  const [isFile, setIsFileFile] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');

  const { mutateAsync: createFeed, isLoading, isSuccess } = useCreateFeedByUser();

  const handleLinkChange = (value: string, filename?: string) => {
    setInputLink(value);
    setFileName(filename || '');
  };

  const validateForm = () => {
    const isValid = Boolean(inputLink) && selected !== null;
    setIsFormValid(isValid);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  useEffect(() => {
    validateForm();
    if (inputLink) {
      setActiveStep(2);
    }
  }, [inputLink, selected]);

  const onClose = () => {
    setIsFormValid(false);
    setSelected(false);
    setInputLink('');
    setIsFileFile(false);
    setFileName('');
    setTitle('');

    closeHandler();
  };

  const steps = [
    {
      value: '',
      lineHeight: '75px'
    },
    {
      value: ''
    }
  ];

  const handleSubmit = async () => {
    if (isLoading || !isFormValid) return;

    const formData = {
      link: inputLink,
      fileName,
      isFile,
      title,
      isAnon: selected
    };

    try {
      await createFeed(formData);
      onClose();
    } catch (error) {
      console.error('Error creating feed:', error);
    }
  };

  return (
    <>
      <Styled.ModalHeaderContent style={{ marginTop: '20px' }}>
        <Styled.IconContainer>
          <Image src={AddMaterialsIcon} width={12} height={12} alt="add-materials-icon" />
        </Styled.IconContainer>
        <Styled.ModalHeaderTextContent>
          <Styled.ModalHeaderTitle>Add insight</Styled.ModalHeaderTitle>
          <Styled.ModalHeaderText>
            This can be a fund letter, investor interview, industry presentation, <br /> Substack post, podcast episode, YouTube link, etc
          </Styled.ModalHeaderText>
        </Styled.ModalHeaderTextContent>
      </Styled.ModalHeaderContent>
      <Styled.AddFundMaterialsFormContainer>
        <Stepper variant="oval" steps={steps} step={activeStep} isDotted />
        <Styled.AddFundMaterialsForm>
          <Styled.AddFundMaterialsDataSection>
            <Styled.AddFundMaterialsDataTitle active>Source</Styled.AddFundMaterialsDataTitle>
            <Styled.AddFundMaterialsValidateInput>
              <InputButtonComponent onLinkChange={handleLinkChange} setIsFileFile={setIsFileFile} />
            </Styled.AddFundMaterialsValidateInput>
          </Styled.AddFundMaterialsDataSection>
          <Styled.AddFundMaterialsDataSection>
            <Styled.AddFundMaterialsDataTitle active={activeStep === 2}>Post anonymously</Styled.AddFundMaterialsDataTitle>
            <Styled.ToogleButtonsContainer>
              <Styled.ToggleButton active={!selected} onClick={() => setSelected(false)}>
                no
              </Styled.ToggleButton>
              <Styled.ToggleButton active={selected} onClick={() => setSelected(true)}>
                yes
              </Styled.ToggleButton>
            </Styled.ToogleButtonsContainer>
          </Styled.AddFundMaterialsDataSection>
          <Styled.AddFundMaterialsDataSection>
            <Styled.AddFundMaterialsDataTitle isActiveOptionTitle={isFormValid}>
              Add title<span>optional</span>
            </Styled.AddFundMaterialsDataTitle>
            <Styled.AddFundMaterialsDataSubtitle>
              e.g. Green Square Capial 2024 Q2, Red Arch Ecommerce Industry Presentation
            </Styled.AddFundMaterialsDataSubtitle>
            <Styled.AddFundMaterialsValidateInput>
              <InputField placeholder="Type here .." value={title} maxLength={POST.MAX_LENGTH} onChange={handleInputChange} />
            </Styled.AddFundMaterialsValidateInput>
          </Styled.AddFundMaterialsDataSection>
          <PostFormActions isActive={isFormValid} isLoading={isLoading} isDisabled={isSuccess} activeCallback={handleSubmit} />
        </Styled.AddFundMaterialsForm>
      </Styled.AddFundMaterialsFormContainer>
    </>
  );
};
