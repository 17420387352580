import styled, { AnyStyledComponent, css } from 'styled-components';
import Image from 'next/image';
import { COLORS, FONTS } from '@/theme';
import { FAMILIES, SIZES, WEIGHTS } from '@/theme/fonts.const';
import { UserImageComponent } from '@/common/components/user-image';

export const ReplyButton = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  border: none;
  width: fit-content;
  cursor: pointer;
  padding: 10px 15px 10px 10px;
  max-width: 430px;
  border-left: 2px solid ${COLORS.borderColorForReplyTile};
`;

export const ReplyDivider = styled.div<{
  margin?: string;
  isInsightNewPodcast?: boolean;
  isFirstMemoReply?: boolean;
  isFirst?: boolean;
  isAnon?: boolean;
  isMemoPost?: boolean;
  isIdeaModal?: boolean;
  isPublish?: boolean;
  isIdeaPage?: boolean;
  isIdeaPageConversation?: boolean;
  isIdeaPageConversationSmall?: boolean;
  isInModal?: boolean;
  isMemoPostHomeOrProfile?: boolean;
  dividerWidth?: number;
  isIdeaHomePage?: boolean;
  hasPrev?: boolean;
  dividerAfterMore?: boolean;
  hasNext?: boolean;
}>`
  position: relative;
  margin: 14px 33px 14px 27px;

  height: 2px;
  width: calc(100% - 60px);

  /* background: linear-gradient(270deg, ${COLORS.NEW_BORDER_COLOR} 0%, ${COLORS.NEW_BORDER_COLOR} 94%, #fff 94.05%); */

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 5.95%;
    width: 94.05%;
    height: 0px;
    background-color: ${COLORS.NEW_BORDER_COLOR};
    border: ${COLORS.NEW_BORDER};

    ${({ isIdeaPageConversation, isIdeaPage, isIdeaPageConversationSmall }) =>
      (isIdeaPageConversation || isIdeaPage || isIdeaPageConversationSmall) &&
      css`
        left: 0;
        width: 99.7%;
      `}
  }

  ${({ isPublish }) => isPublish && 'width: 650px;'};

  ${({ isIdeaModal, isPublish }) => isIdeaModal && isPublish && 'width: 500px;'};
  ${({ isIdeaModal }) => isIdeaModal && 'width: 540px;margin-left: 0'};

  ${({ isFirstMemoReply }) => isFirstMemoReply && 'display: none;'};
  overflow: hidden;

  ${({ isIdeaPage }) => isIdeaPage && 'width: 440px; margin-left: 100px'};
  ${({ dividerWidth }) => dividerWidth && `width: ${dividerWidth}px`};

  ${({ isInModal }) =>
    isInModal &&
    css`
      margin-left: -27px;
      margin-right: 0;
      width: 530px;
    `};

  ${({ hasNext, isInModal }) =>
    hasNext &&
    css`
      margin: 14px 33px 8px ${isInModal ? '-27px' : '27px'};
    `}

  ${({ hasPrev, isInModal }) =>
    hasPrev &&
    css`
      margin: 14px 33px 8px ${isInModal ? '-27px' : '27px'};
    `}

  ${({ dividerAfterMore, isInModal, isIdeaModal }) =>
    dividerAfterMore &&
    css`
      margin: 8px 33px 14px ${isInModal ? '-27px' : '27px'};

      ${isIdeaModal &&
      css`
        margin-left: 0;
      `}
    `}

    ${({ isInsightNewPodcast }) =>
    isInsightNewPodcast &&
    css`
      display: none;
    `}

    ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}


    ${({ isIdeaPageConversation }) =>
    isIdeaPageConversation &&
    css`
      width: 694px;
      margin-left: auto;
      margin-right: 0;
    `}
    ${({ isIdeaPageConversationSmall }) =>
    isIdeaPageConversationSmall &&
    css`
      width: 502px;
    `}
`;

export const Anchor = styled.div`
  position: absolute;
  top: 0px;
  display: block;
  width: 100px;
`;

export const ReplyButtonText = styled.div`
  font-family: ${FAMILIES.ibmPlexSansHebrew};
  font-weight: ${WEIGHTS.medium};
  font-size: ${SIZES.s};
  line-height: ${SIZES.s};
  color: ${COLORS.black};
  letter-spacing: 0.6px;
`;

export const ReplyButtonIcon = styled(Image as AnyStyledComponent)`
  transform: rotate(-90deg);
  width: 12px;
  height: 12px;
`;

export const ReplyButtonIconSecond = styled(Image as AnyStyledComponent)`
  width: 12px;
  height: 12px;
`;

export const ReplyButtonIconLeft = styled(Image as AnyStyledComponent)`
  width: 12px;
  height: 12px;
  transform: rotate(90deg);
`;

export const ConversationWrapper = styled.div<{ marginTop?: number; isInModal?: boolean; isIdeaPageConversation?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`}
  ${({ isInModal }) => isInModal && 'margin-left: 40px;'} 
  ${({ isIdeaPageConversation }) => isIdeaPageConversation && 'max-width: 500px; margin-left: -2px;'}
`;

export const ShowMoreWrapper = styled.button<{
  noPadding?: boolean;
  isIdeaPageConversation?: boolean;
  hasNext?: boolean;
  gap?: number;
  isInModal?: boolean;
  isIdeaModal?: boolean;
}>`
  background-color: transparent;
  border: none;
  padding-left: ${({ isInModal }) => (isInModal ? '0px' : '67px')};
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  height: 22px;
  & > div {
    display: flex;
    align-items: center;
    /* gap: 10px; */
    & > p {
      color: #0016d9;
      font-family: ${FONTS.FAMILIES.robotoFlex};
      font-size: ${FONTS.SIZES.s};
      font-style: normal;
      font-weight: ${FONTS.WEIGHTS.normal};
      line-height: ${FONTS.SIZES.xxxl};
      letter-spacing: 0.1px;
      white-space: nowrap;
    }
  }

  ${({ hasNext }) =>
    hasNext &&
    css`
      margin-bottom: -8px;
    `}

  ${({ isIdeaPageConversation }) =>
    isIdeaPageConversation &&
    css`
      margin-left: 27px;
    `}

  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `}

    
    ${({ noPadding }) =>
    noPadding &&
    css`
      padding-left: 0;
    `}

    ${({ isIdeaModal }) =>
    isIdeaModal &&
    css`
      padding-left: 0;
    `}
`;

export const SkipSummaryWrapper = styled.button<{ noPadding?: boolean; hasNext?: boolean; gap?: number }>`
  background-color: transparent;
  border: none;
  padding-left: ${({ noPadding }) => (noPadding ? '0' : '67px')};
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  height: 22px;
  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
    & > p {
      color: #0016d9;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.1px;
      white-space: nowrap;
    }
  }

  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `}
`;

export const ProfileIconsList = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;

  & > div {
    margin-left: -4px;
  }
`;

export const ProfileItem = styled(UserImageComponent)`
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
`;

export const LoaderWrapper = styled.div<{ hasNext?: boolean }>`
  width: 100%;
  min-height: 22px;
  max-height: 22px;

  ${({ hasNext }) =>
    hasNext &&
    css`
      margin-bottom: -8px;
    `}
`;

export const ShowMoreParagraph = styled.p`
  margin: 0px 5px 0px 10px;
`;
