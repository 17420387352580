import React from 'react';
import AddIcon from '@styles/icons/add-insight-icon.webp';
import { Stack } from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { NewMusingInputsComponent } from '@/common/components/new-musing-modal/new-mussing-inputs.component';
import { INewPostReply } from '@/common/components/new-musing-modal/new-musing-modal.types';
import { useAuthProfile, useCreateChannelPost, useCreatePost } from '@/common/hooks';
import * as Styled from './channel-post.styled';
import { IconContainer } from '../poll/poll.styled';
import { ModalHeaderText, ModalTitle, TitleContainer } from '../coverage/coverage.styled';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';

interface INewMusingModalComponentProps {
  open: boolean;
  closeHandler: () => void;
  setIsScrollDisabled: (it: boolean) => void;
  isChannelModal?: boolean;
}

export const ChannelPostComponent = ({ open, closeHandler, setIsScrollDisabled, isChannelModal }: INewMusingModalComponentProps) => {
  const router = useRouter();
  const isChannels = router.pathname.includes(ROUTER_KEYS.CHANNELS);
  const { id } = router.query;
  const { data: profileId } = useAuthProfile();

  const { mutateAsync: createPost, isLoading, isSuccess, reset } = useCreateChannelPost(profileId ?? undefined, id as string);

  const createClick = (text: string, image: ArrayBuffer | string | null, replies: INewPostReply[], isPrivate: boolean) => {
    if (text || image) {
      createPost({ text, image, replies, isPrivate, channelId: isChannels ? (id as string) : null });
    }
  };
  return (
    <div>
      <TitleContainer>
        {/* <IconContainer>
          <Image src={AddIcon} width={12} height={12} alt="add-post" />
        </IconContainer> */}
        <Stack gap="10px">
          {/* <ModalTitle>Add post</ModalTitle> */}
          <Styled.ModalHeaderText>Create a thread of multiple posts by adding a reply</Styled.ModalHeaderText>
        </Stack>
      </TitleContainer>
      <Styled.NewMusingInputsComponentContainer>
        <NewMusingInputsComponent
          isCreateModal
          closeHandler={closeHandler}
          createClick={createClick}
          isLoading={isLoading}
          setIsScrollDisabled={(it) => setIsScrollDisabled(it)}
          isSuccess={isSuccess}
          reset={reset}
          open={open}
          isChannelModal
        />
      </Styled.NewMusingInputsComponentContainer>
    </div>
  );
};
