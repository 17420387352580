import React, { ChangeEvent, useRef, useState } from 'react';
import DeleteDocumentIcon from '@styles/icons/memo-delete-document-icon.webp';
import UploadDocumentIcon from '@styles/icons/memo-upload-document-icon.webp';
import Image from 'next/image';
import { ModalErrorOnDragAndDropComponent } from '@/common/components/modal-error-on-drag-and-drop';
// eslint-disable-next-line import/no-cycle
import * as Styled from './memo-optional-details.styled';
import { OnDropErrorEnum } from '@/common/types/profile.type';
import { getBase64 } from '@/common/components/onboard/helpers';
import { dataURLtoFile } from '@/common/utils/data-url-to-file';
import MemoUpload from '@/styles/icons/memo-upload.webp';


interface IProps {
  setDocumentName: (name: string) => void;
  setDocumentUrl: (name: string) => void;
  setFile?: (file: File) => void;
  isCurrent: boolean;
  documentName?: string;
  isFileForProcessing?: boolean;
}

export const UploadDocumentComponent: React.FC<IProps> = ({
  setDocumentName,
  setDocumentUrl,
  setFile,
  isCurrent,
  documentName,
  isFileForProcessing
}) => {
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const [onDropError, setOnDropError] = useState<null | OnDropErrorEnum>(null);

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const maxSize = 20 * 1024 * 1024;
    let typeError: null | OnDropErrorEnum = null;
    if (e.target.files && e.target.files[0].type !== 'application/pdf') {
      typeError = OnDropErrorEnum.INCORECT_TYPE_MEMO_PDF;
    }
    if (e.target.files && e.target.files[0].size > maxSize) {
      typeError = OnDropErrorEnum.MAX_SIZE_MEMO_PDF;
    }
    if (e.target.files && e.target.files[0].size <= maxSize) {
      let result: string | undefined;
      let resultName: string | undefined;
      if (e.target.files[0].type === 'application/pdf') {
        if (setFile) {
          setFile(e.target.files[0]);
        }
        resultName = e.target.files[0].name;
        result = (await getBase64(e.target.files[0]))?.toString();
      }
      e.target.value = '';
      setDocumentName(resultName ?? '');
      setDocumentUrl(result ?? '');
    }
    e.target.value = '';
    setOnDropError(typeError);
  };

  const handlerDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const { files } = event.dataTransfer;
    const URL = event.dataTransfer.getData('URL');

    const filteredFiles: File[] = [];
    let typeError: null | OnDropErrorEnum = null;

    for (let i = 0; i < files.length; i += 1) {
      filteredFiles.push(files[i]);
    }
    if (URL && URL.length > 0 && filteredFiles.length === 0) {
      const file = dataURLtoFile(URL, 'file');
      if (file) {
        filteredFiles.push(file);
      } else {
        typeError = OnDropErrorEnum.INCORECT_TYPE;
      }
    }

    for (let i = 0; i < filteredFiles.length; i += 1) {
      if (filteredFiles[i]) {
        const file = filteredFiles[i];
        if (!file) return;
        if (file.size > 20 * 1024 * 1024) {
          typeError = OnDropErrorEnum.MAX_SIZE_MEMO_PDF;
        }
        if (file.type !== 'application/pdf') {
          typeError = OnDropErrorEnum.INCORECT_TYPE_MEMO_PDF;
        }
        if (file && file.type === 'application/pdf' && file.size <= 20 * 1024 * 1024) {
          if (setFile) {
            setFile(file);
          }
          const imageBase64 = getBase64(file);
          setDocumentName(file.name ?? '');
          imageBase64.then((result) => {
            setDocumentUrl(result?.toString() ?? '');
          });
        }
      }
    }
    setOnDropError(typeError);
  };

  return (
    <div>
      <ModalErrorOnDragAndDropComponent type={onDropError} open={!!onDropError} closeHandler={() => setOnDropError(null)} />
      <Styled.UploadDocumentWrapper>
        <Styled.UploadDocumentContainer
          isCurrent={isCurrent}
          onClick={() => uploadInputRef.current && uploadInputRef.current?.click()}
          onDrop={handlerDrop}
        >
          <Image
            src={MemoUpload}
            alt="Upload document icon"
            width={16}
            height={16}
            style={{ margin: isFileForProcessing ? '0' : '4px 5px 4px 0' }}
          />
          <input ref={uploadInputRef} type="file" style={{ display: 'none' }} multiple={false} accept=".pdf" onChange={handleChange} />
          {!documentName && (
            <Styled.UploadDocumentButton isFileForProcessing={isFileForProcessing}>
              click here to select document or drag-and-drop here
            </Styled.UploadDocumentButton>
          )}
          {documentName && <Styled.SelectedDocumentText>{documentName}</Styled.SelectedDocumentText>}
        </Styled.UploadDocumentContainer>
        {documentName && (
          <Styled.DeleteUploadedDocumentButton
            onClick={() => {
              setDocumentName('');
              setDocumentUrl('');
            }}
          >
            delete upload
            <Image
              src={DeleteDocumentIcon}
              alt="Delete document icon"
              width={18}
              height={18}
              style={{ marginLeft: '2px', marginRight: '-4px', marginBottom: '2px', padding: '1px' }}
            />
          </Styled.DeleteUploadedDocumentButton>
        )}
      </Styled.UploadDocumentWrapper>
    </div>
  );
};
