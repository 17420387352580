import styled from 'styled-components';
import Image from 'next/image';
import { ModalComponent } from '@/common/components/modal';
import { COLORS, FONTS } from '@/theme';
import { Loader } from '@/common/components/loader';
import { UserImageComponent } from '@/common/components/user-image';
import { FAMILIES } from '@/theme/fonts.const';

export const LikesClapsPraisesModalWrapper = styled(ModalComponent)`
  min-width: 602px;
  max-width: 602px;
  min-height: calc(100vh - 30px);
  padding: 35px 50px 18px 50px;
  color: ${COLORS.black};
  font-size: ${FONTS.SIZES.s};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  display: flex;
  gap: 30px;
  flex-direction: column;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: -5px 5px 10px 0px rgba(202, 202, 202, 0.15);
`;

export const ActionLoader = styled(Loader)`
  width: 100%;
  min-height: 274px;
  align-items: center;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 30px;
  right: 50px;
  border-radius: 10px;
  background: #e3e3e3;
  display: inline-flex;
  padding: 8px 10px;
  align-items: flex-start;
  gap: 8px;
  border: none;
  cursor: pointer;
  color: #515151;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
`;

export const CloseImage = styled(Image)`
  width: 12px;
  height: 12px;
`;

export const ActionSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  :first-child {
    margin-top: 0;
  }
`;

export const ActionCount = styled.div`
  p {
    color: #000;
    font-family: Roboto Flex;
    font-size: ${FONTS.SIZES.l};
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
`;

export const ActionUsersSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionUser = styled.div<{ index: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ index }) =>
    index &&
    `::before {
    content: '';
    height: 1px;
    background: ${COLORS.gapLineUserModal};
    width: 100%;
    display: block;
    margin: 12px 0px 12px 0px;
  }`}

  :last-child::after {
    margin-top: 12px;
    content: '';
    height: 1px;
    background: ${COLORS.gapLineUserModal};
    width: 100%;
    display: block;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  gap: 4px;
  justify-content: space-between;
  p {
    font-size: 13px;
    color: #2e2e2e;
  }
  p:last-child {
    font-size: ${FONTS.SIZES.s};

    color: #a0a0a0;
  }
  div {
    display: flex;
    align-items: center;
    div:last-child {
      display: flex;
      flex-direction: column;
      align-items: start;
    }
  }
`;

export const ImageWrapper = styled(UserImageComponent)`
  margin-right: 5px;
  width: 30px;
  height: 30px;
`;

export const UserConnectionButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

interface IUserConnectionButtonProps {
  $active: boolean;
  $disabled?: boolean;
}

export const UserConnectionButton = styled.button<IUserConnectionButtonProps>`
  padding: 5px 10px;
  border-radius: 5px;
  border: 0;
  font-size: 13px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  cursor: ${({ $active }) => ($active ? 'pointer' : 'default')};
  background: ${({ $active }) =>
    $active ? 'linear-gradient(264deg, rgba(0, 148, 121, 0.10) 0%, rgba(0, 24, 241, 0.10) 100%)' : 'rgba(0, 107, 131, 0.60)'};
  color: ${({ $active }) => ($active ? '#0018F1' : '#FFF')};
  ${({ $disabled }) => $disabled && 'color: #FFF; background: rgba(0, 107, 131, 0.30);'}
  &:focus-visible {
    outline: 0;
  }
`;

export const PaginationContainer = styled.button`
  cursor: pointer;
  margin-top: -18px;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  max-width: 167px;
  border: 0px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  background-color: transparent;
  &:focus-visible {
    outline: 0;
  }
  p {
    font-size: ${FONTS.SIZES.m};
    color: ${COLORS.buttonPaginationUserModal};
    margin-left: 5px;
    line-height: ${FONTS.SIZES.m};
  }
`;

export const HiddenImage = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  background-color: ${COLORS.hiddenImageBackgroundColor};
  backdrop-filter: blur(2.5px);
  img {
    width: 14px !important;
    height: 14px !important;
    border-radius: 0 !important;
    padding-right: 0 !important;
    margin-right: 0 !important;
  }
`;

export const Username = styled.p`
  color: #2e2e2e;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
`;

export const Role = styled.p`
  color: #a0a0a0;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
`;
