import React, { ReactElement } from 'react';
import * as Styled from './post-podcast-modal.styled';

interface IProps {
  text?: string;
  additionalInfo?: Record<string, string>;
}

export const PostPodcastModalComponent = ({ text, additionalInfo }: IProps): ReactElement => (
  <Styled.PodcastSectionWrapper>
    <Styled.PodcastModalTitle>{additionalInfo?.title}</Styled.PodcastModalTitle>
    <Styled.PodcastModalText>{text}</Styled.PodcastModalText>
  </Styled.PodcastSectionWrapper>
);
