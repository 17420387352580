import React, { ReactElement, useEffect, useState } from 'react';
import { format } from 'date-fns';
import * as Styled from './podcast-modal.styled';
import { FlexComponent } from '@/common/components/flex';
import CloseIcon from '@/styles/icons/close-modal-icon.webp';
import { useInsightPodcastPostModal } from '@/common/hooks';
import { IPost } from '@/common/types/post.type';
import { PostRowComponent } from '@/common/components/profile/components/post-row';
import { Loader } from '@/common/components/loader';

interface IProps {
  isOpen: boolean;
  onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  originalPostId: string;
}

export const PodcastModalComponent = ({ isOpen, onClose, originalPostId }: IProps): ReactElement => {
  const { mutateAsync: fetchPost, isLoading } = useInsightPodcastPostModal();
  const [podcastData, setPodcastData] = useState<IPost | null>(null);
  const title = podcastData?.insight?.title;
  const sourceFullName = podcastData?.insight?.source?.fullName;
  const podcastDate = podcastData?.createdAt ? format(podcastData?.createdAt, 'd MMMM yyyy') : '';

  useEffect(() => {
    if (isOpen) {
      const fetchData = async () => {
        const data = (await fetchPost({ id: originalPostId })) as IPost | null;
        setPodcastData(data);
      };

      fetchData();
    }
  }, [isOpen, originalPostId]);

  return (
    <Styled.ModalWrapper
      componentsProps={{
        backdrop: {
          style: {
            backgroundColor: 'rgba(225, 225, 225, 0.8)'
          }
        }
      }}
      open={isOpen}
      closeHandler={onClose as () => void}
      childTopPostion={15}
      isCreateModal
    >
      {isLoading ? (
        <FlexComponent height="100%" justifyContent="center" alignItems="center">
          <Loader />
        </FlexComponent>
      ) : (
        <>
          <FlexComponent justifyContent="space-between" alignItems="flex-start">
            <div>
              <Styled.ModalTitle>{sourceFullName}</Styled.ModalTitle>
              <Styled.ModalText>{title}</Styled.ModalText>
              <FlexComponent gap="5px">
                <Styled.PodcastBaseText>{podcastDate}</Styled.PodcastBaseText>
                <Styled.PodcastDotText>•</Styled.PodcastDotText>
                <Styled.PodcastLabelText>Podcast summary</Styled.PodcastLabelText>
              </FlexComponent>
            </div>
            <Styled.CloseButton onClick={onClose}>
              <Styled.CloseButtonImage src={CloseIcon} alt="close-icon" />
            </Styled.CloseButton>
          </FlexComponent>
          {podcastData && (
            <PostRowComponent
              disableQuoteAndRepost
              isPodcastModal
              isTreadOpen
              quotePostHandler={() => {}}
              userProfileId="userProfileId"
              post={podcastData}
            />
          )}
        </>
      )}
    </Styled.ModalWrapper>
  );
};
