import styled, { css, keyframes } from 'styled-components';
import { FAMILIES } from '@/theme/fonts.const';
import { COLORS } from '@/theme';

const flashlightAnimation = keyframes`
  0% {
    background-color: rgba(239, 241, 255, 0.9);
    color: rgba(0,54,245, 0.2);
  }
  25% {
    color: rgba(0,54,245, 0.4);
  }
  50% {
    background-color: rgba(219, 223, 243, 0.7); 
    color: rgba(0,54,245, 0.7);
  }
  75% {
    color: rgba(0,54,245, 0.9);
  }
  100% {
    background-color: rgba(239, 241, 255, 0.9); 
    color: #0036f5;
  }
`;

export const Header = styled.div`
  flex: 1;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: -3px;
`;
export const Description = styled.p`
  color: ${COLORS.black};
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;

  & > span {
    font-weight: 600;
  }
`;

export const Title = styled(Description)`
  font-weight: 500;
`;

export const NotificationMainTitle = styled.p`
  color: #525252;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
`;

export const NotificationSecuritiesBox = styled.p`
  margin-top: 6px;
`;

export const NotificationFundTitle = styled.p`
  color: #525252;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
`;

export const NotificationTitle = styled.p`
  color: ${COLORS.black};
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
`;

export const Username = styled.p`
  color: #9b9b9b;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.08;
`;

export const Link = styled.a<{ isNewsletter?: boolean; isSecurityListEmpty?: boolean }>`
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding: 2px;
  padding-left: 0;
  margin-left: ${({ isSecurityListEmpty }) => (isSecurityListEmpty ? 0 : '1px')};
  color: #606060;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 13px;
  white-space: nowrap;
`;

export const ButtonLink = styled.a<{ isNewsletter?: boolean; isSecurityListEmpty?: boolean }>`
  cursor: pointer;
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 6px;
  border: 1.5px solid #ebebeb;
  background: #fafafa;
  width: 26px;
  height: 26px;
`;

export const LinkPodcast = styled.a<{ isNewsletter?: boolean; isSecurityListEmpty?: boolean }>`
  color: #0018f1;
  margin-left: auto;
  font-family: 'Roboto Flex';
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  white-space: nowrap;
`;

export const Arrow = styled.img`
  width: 11px;
  height: 11px;
`;

export const ReadMoreSection = styled.span`
  color: #0018f1;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400 !important;
`;
export const SecuritiesListVSLinks = styled.div`
  display: flex;
`;

export const EmptySecurity = styled.p`
  padding: 3px 5px;
  border-radius: 3px;
  border: 1px rgba(227, 231, 255, 0);
  background: #f9f9f9;
  color: #858585;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  height: 18px;
  width: fit-content;
`;

export const OthersText = styled.p<{ ml?: number }>`
  color: #4a4a4a;
  margin-left: ${({ ml }) => (ml ? `${ml}px` : 0)};
  font-family: ${FAMILIES.robotoFlex};
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: 13px;
  letter-spacing: -0.22px;
  padding-top: 4px;
  padding-bottom: 1px;
  white-space: nowrap;
`;

export const NewPodcastButton = styled.button<{ isThreadOpen?: boolean; isAnimating?: boolean }>`
  display: flex;
  color: #0018f1;
  min-width: 210px;
  max-width: ${({ isThreadOpen }) => (isThreadOpen ? '210px' : '343px')};
  height: 28px;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding: 2px 6px;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  gap: 4px;
  border-radius: 6px;
  border: 1.5px solid rgba(154, 167, 255, 0.6);
  background: rgba(239, 241, 255, 0.8);

  ${({ isAnimating }) =>
    isAnimating &&
    css`
      animation: ${flashlightAnimation} 1s infinite;
    `}
`;

export const PodcastButton = styled.button<{ isThreadOpen?: boolean; isAnimating?: boolean }>`
  display: flex;
  color: #0018f1;
  min-width: 210px;
  max-width: ${({ isThreadOpen }) => (isThreadOpen ? '210px' : '341px')};
  height: 24px;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding: 2px 6px;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid #9aa7ff;
  background: rgba(239, 241, 255, 0.8);

  ${({ isAnimating }) =>
    isAnimating &&
    css`
      animation: ${flashlightAnimation} 1s infinite;
    `}
`;

export const NewPodcastTypeTitle = styled.p`
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

export const NewPodcastTypeText = styled.p`
  color: #6b6b6b;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;
