import styled, { css } from 'styled-components';
import Image from 'next/image';
import { COLORS } from '@/theme';
import { FAMILIES } from '@/theme/fonts.const';

export const DescriptionWrapper = styled.div<{ mt?: number, isChannel?: boolean }>`
  position: relative;
  gap: 10px;
  width: 100%;
  /* padding: 9px 33px 9px 27px; */
  padding: ${({ isChannel }) => (isChannel ? '11px 33px 9px 27px' : '8px 33px 9px 27px')};
  margin-bottom: 14px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 99.9%;
    height: 0px;
    background-color: ${COLORS.NEW_BORDER_COLOR};
    border: ${COLORS.NEW_BORDER};
  }

  ${({ mt }) =>
    mt &&
    css`
      margin-top: ${mt}px;
    `}
`;

export const Description = styled.div`
  color: #9e9e9e;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 0.34px;
`;

export const AttentionIconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background: #fbfbfb;
  backdrop-filter: blur(2.5px);
`;

export const AttentionIcon = styled(Image)`
  width: 18px;
  height: 18px;
`;
