import styled, { AnyStyledComponent } from 'styled-components';
import Image from 'next/image';
import { UserImageComponent } from '@/common/components/user-image';
import { COLORS, FONTS } from '@/theme';

export const Wrapper = styled.div<{ isHidden?: boolean; isLatestCoverage?: boolean; typePost?: string }>`
  position: relative;
  display: flex;
  align-items: end;
  cursor: pointer;
  gap: 5px;
  width: 100%;
/* margin-bottom: 4px !important; */
  /* ${({ isHidden }) => isHidden && 'margin-bottom: 1px;'} */
  ${({ isLatestCoverage }) => isLatestCoverage && 'padding:0 15px; align-items: center;'}
`;

export const ImageWrapper = styled(UserImageComponent)<{
  isReply?: boolean;
  isLatestCoverage?: boolean;
  isTreanding?: boolean;
  isNotification?: boolean;
  typePost?: string;
}>`
  ${({ isTreanding, isNotification, isReply }) =>
    isTreanding && !isReply
      ? `
    position: relative;
    left: 0px;
    top: 0px;
    `
      : `
    position: absolute;
    left: -40px;
    top: -1px;
    `}
  ${({ typePost }) =>
    (typePost === 'notification' || typePost === 'coverage') &&
    `
    position: relative;
    left: 0px;
    top: 0px;
  `}
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  min-height: 30px;
  max-height: 30px;

  ${({ isReply }) => isReply && 'opacity: 0.7;'}
  ${({ isLatestCoverage }) =>
    isLatestCoverage &&
    `width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  min-height: 20px;
  max-height: 20px;`}
  top: 1px;
`;

export const AnonymousTitle = styled.div`
  color: #2e2e2e;
  font-family: 'Roboto Flex';
  font-size: 13px;
  font-weight: 600;
  line-height: 13px;
`;

export const AnonMemoIcon = styled(Image)`
  width: 30px;
  border-radius: 50%;
  height: 30px;
  position: absolute;
  left: -40px;
  top: -1px;
`;

export const LockedMemberIcon = styled(AnonMemoIcon)``;

export const InfoWrapper = styled.div<{ isQuote?: boolean; isInCoverageLatest?: boolean; typePost?: string }>`
  display: flex;
  gap: 9px;
  width: calc(100% - 35px);
  align-items: end;
  ${({ typePost }) =>
    typePost === 'notification' &&
    `
   padding-bottom: 3px;
`}
  ${({ typePost }) =>
    typePost === 'trending' &&
    `
    padding-top: 14px;
  padding-bottom: 3px;`};
  ${({ isInCoverageLatest }) => isInCoverageLatest && 'padding-bottom: 0;'}
  height: fit-content;
  ${({ isQuote }) => isQuote && 'gap: 10px;padding-bottom: 0;'}
`;

export const FullNameWrapper = styled.div<{ isQuote?: boolean; isFaded?: boolean; isAnon: boolean; isTrending: boolean }>`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.semiBold};
  font-size: 13px;
  line-height: 14px;
  color: ${COLORS.fullnamePostInfoColor};
  ${({ isQuote }) =>
    isQuote &&
    `color: ${COLORS.fullNameInRepostTileColor};
    font-size: 12px; 
    font-weight: ${FONTS.WEIGHTS.semiBold}; 
    line-height: 12px; `}
  white-space: nowrap;
  ${({ isFaded }) => isFaded && 'color: rgba(46, 46, 46, 0.50);'}
  ${({ isTrending }) => isTrending && 'color: #3D3D3D;'}
  ${({ isAnon }) => isAnon && 'color: #CECECE;'}
    overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 1;
  max-width: 100%;
`;

export const DetaileInfoWrapper = styled.div`
  display: flex;
  gap: 3px;
`;

export const DetaileInfo = styled.div<{ isFaded?: boolean; fullNameWidth: number; isTrending: boolean }>`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.semiBold};
  flex-shrink: 0;
  max-width: ${({ fullNameWidth }) => `calc(100% - ${fullNameWidth}px)`};
  font-size: 12px;

  line-height: 13px;
  padding-top: 1px;
  @-moz-document url-prefix() {
    padding-top: 0;
    line-height: 13px;
  }
  color: ${COLORS.datailePostInfoColor};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${({ isFaded }) => isFaded && 'color: rgba(160, 160, 160, 0.50);'}
  ${({ isTrending }) => isTrending && 'color: #A0A0A0;'}
`;

export const StyledIconHoverText = styled.span`
  color: #000;
  font-family: 'Roboto Flex';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const LockIconContainer = styled.div`
  display: flex;
  align-items: end;
`;

export const LockIconStyled = styled(Image as AnyStyledComponent)`
  cursor: pointer;
  margin-bottom: 1px;
  position: relative;
  width: 16px;
  height: 12px;
`;

export const HoverContent = styled.div<{ hoverPosition: 'top' | 'right' }>`
  visibility: hidden;
  opacity: 0;
  width: 155px;
  height: 34px;
  padding: 7px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background: rgba(249, 249, 249, 0.8);
  backdrop-filter: blur(10px);
  position: absolute;
  border-radius: 4px;
  z-index: 100;
  transition: opacity 0.3s ease-in-out;

  ${({ hoverPosition }) =>
    hoverPosition === 'top'
      ? `
    top: -40px;
    left: -130px;
  `
      : `
    top: -10px;
    left: 20px; 
  `}
`;

export const LockHoverContainer = styled.div`
  display: flex;
  align-items: end;
  position: relative;
  display: inline-block;

  &:hover {
    ${HoverContent} {
      visibility: visible;
      opacity: 1;
      transition-delay: 500ms;
    }
  }
`;
