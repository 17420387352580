import styled, { AnyStyledComponent, css } from 'styled-components';
import Image from 'next/image';
import { COLORS, FONTS } from '@/theme';
import { FAMILIES, SIZES, WEIGHTS } from '@/theme/fonts.const';
import { UserImageComponent } from '@/common/components/user-image';

export const ReplyButton = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  border: none;
  width: fit-content;
  cursor: pointer;
  padding: 10px 15px 10px 10px;
  max-width: 430px;
  border-left: 2px solid ${COLORS.borderColorForReplyTile};
`;

export const ReplyDivider = styled.div<{}>`
  position: relative;
  margin-top: 15px;
  margin-bottom: 10px;

  height: 2px;
  width: calc(100%);
  max-width: 539px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 539px;
    height: 0px;
    background-color: #ededed;
    border: 0.5px solid #ededed;
  }
`;

export const Anchor = styled.div`
  position: absolute;
  top: 0px;
  display: block;
  width: 100px;
`;

export const ReplyButtonText = styled.div`
  font-family: ${FAMILIES.ibmPlexSansHebrew};
  font-weight: ${WEIGHTS.medium};
  font-size: ${SIZES.s};
  line-height: ${SIZES.s};
  color: ${COLORS.black};
  letter-spacing: 0.6px;
`;

export const ReplyButtonIcon = styled(Image as AnyStyledComponent)`
  transform: rotate(-90deg);
  width: 12px;
  height: 12px;
`;

export const ReplyButtonIconSecond = styled(Image as AnyStyledComponent)`
  width: 12px;
  height: 12px;
`;

export const ReplyButtonIconLeft = styled(Image as AnyStyledComponent)`
  width: 12px;
  height: 12px;
  transform: rotate(90deg);
`;

export const ConversationWrapper = styled.div`
  margin-top: 13px;
  display: flex;
  min-width: 540px;
  flex-direction: column;
`;

export const ShowMoreWrapper = styled.button<{
  noPadding?: boolean;
  isIdeaPageConversation?: boolean;
  hasNext?: boolean;
  gap?: number;
  isInModal?: boolean;
  isIdeaModal?: boolean;
}>`
  background-color: transparent;
  border: none;
  padding-left: ${({ isInModal }) => (isInModal ? '0px' : '67px')};
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  height: 22px;
  & > div {
    display: flex;
    align-items: center;
    /* gap: 10px; */
    & > p {
      color: #0016d9;
      font-family: ${FONTS.FAMILIES.robotoFlex};
      font-size: ${FONTS.SIZES.s};
      font-style: normal;
      font-weight: ${FONTS.WEIGHTS.normal};
      line-height: ${FONTS.SIZES.xxxl};
      letter-spacing: 0.1px;
      white-space: nowrap;
    }
  }

  ${({ hasNext }) =>
    hasNext &&
    css`
      margin-bottom: -8px;
    `}

  ${({ isIdeaPageConversation }) =>
    isIdeaPageConversation &&
    css`
      margin-left: 27px;
    `}

  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `}

    
    ${({ noPadding }) =>
    noPadding &&
    css`
      padding-left: 0;
    `}

    ${({ isIdeaModal }) =>
    isIdeaModal &&
    css`
      padding-left: 0;
    `}
`;
